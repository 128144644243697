import React, { cloneElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//MuiIcons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/FileDownload';

//Services

//MuiComponents
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Paper, Typography } from '@mui/material';
import { Box, width } from '@mui/system';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';

export default function BigReportCard(props) {
  const {
    title,
    subtitle,
    children,
    downloadClick,
    infoTip,
    dateOrCoverage,
    openOrClosed,
  } = props;

  const [reportName, setReportName] = useState('');
  const [allowDownload, setAllowDownload] = useState(false);
  const [downloadFunction, setDownloadFunction] = useState(false);

  const changeDateOrCoverage = (e) => {
    props.setDateOrCoverage(e.target.value);
  };

  const [
    selectedEnrollmentWindowObjParent,
    setSelectedEnrollmentWindowObjParent,
  ] = useState({});

  const downloadClickHandler = async () => {
    let result = await downloadFunction();
    if (props.createCsv) props.createCsv(result, reportName);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          height: 'calc(100vh - 436px)',
          //height: "100%",
          borderRadius: '0px',
          backgroundColor: 'background.default',
          position: 'relative',
          // overflowY:'auto',
        }}
      >
        <Box // Graph header row
          //className="flex-row"
          sx={{
            //marginLeft: "10px",
            display: 'flex',
            flexDirection: 'row',
            borderColor: 'background.border',
            position: 'relative',
            //padding: "14px 24px",
            height: '70px',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Box // By date/coverage dropdown
            sx={{
              paddingLeft: '30px',
              flex: '0 0 200px',
            }}
          >
            {props.openOrClosed == 'open' ? (
              <>
                <TextField
                  variant="standard"
                  select
                  value={dateOrCoverage}
                  // label="Group Plan"
                  onChange={changeDateOrCoverage}
                  //fullWidth
                  sx={{ width: '200px' }}
                >
                  <MenuItem value={'date'}>Enrollment By Date</MenuItem>
                  <MenuItem value={'coverage'}>Enrollment By Coverage</MenuItem>
                </TextField>
              </>
            ) : (
              <>
                <TextField
                  variant="standard"
                  select
                  value={props?.thisEnrollmentWindow || ''}
                  // label="Group Plan"
                  //fullWidth
                  sx={{ width: '170px' }}
                  onChange={props.handleWindowChange}
                >
                  {props?.enrollmentWindows?.length > 0 &&
                    props.enrollmentWindows.map((enrollmentWindow) => {
                      return (
                        <MenuItem value={enrollmentWindow?.id}>
                          {props.formatDate(
                            enrollmentWindow.openEnrollmentStart
                          )}{' '}
                          -{' '}
                          {props.formatDate(enrollmentWindow.openEnrollmentEnd)}
                        </MenuItem>
                      );
                    })}
                  {/* <MenuItem value={5}>2022-2023</MenuItem> */}
                </TextField>
              </>
            )}
          </Box>

          {/* Timezone Info Tip for coverage by date*/}
          {props?.dateOrCoverage === 'date' && (
            <Box>
              <Tooltip
                enterTouchDelay={50}
                title="Dates are displayed in central time zone"
                placement="bottom-end"
                arrow
              >
                <IconButton>
                  <InfoOutlinedIcon sx={{ height: '20px' }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Box // Graph title
            sx={{
              flex: '2 1 100%',
              margin: '0 auto',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontFamily: 'Poppins',
                fontWeight: 500,
                textAlign: 'center',
                color: 'text.primary',
                //padding: "10px",
                //margin: "20px auto 0px",
              }}
            >
              {selectedEnrollmentWindowObjParent?.openEnrollmentStart
                ? `Enrollments from ${props?.formatDate(
                    selectedEnrollmentWindowObjParent?.openEnrollmentStart
                  )} - ${props?.formatDate(
                    selectedEnrollmentWindowObjParent?.openEnrollmentEnd
                  )}`
                : 'Unknown Enrollment Window'}
            </Typography>
          </Box>

          <Box // Enrollment by date legend
            sx={{
              flex: '1 1 10%',
              display: 'flex',
              flexDirection: 'row',
              marginRight: '20px',
              marginLeft: 'auto',
            }}
          >
            <Box
              className="col"
              sx={{
                visibility:
                  props.openOrClosed == 'open' && props.dateOrCoverage == 'date'
                    ? ''
                    : 'hidden',
              }}
            >
              <div style={{ paddingLeft: '20px', position: 'relative' }}>
                <Typography
                  component={'span'}
                  sx={{
                    fontFamily: 'Archivo',
                    color: 'text.Gray2',
                    letterSpacing: '1px',
                    fontSize: '12px',
                  }}
                >
                  MEMBER
                </Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    height: '7px',
                    width: '14px',
                    backgroundColor: 'primary.main',
                    top: '44%',
                    left: '0',
                  }}
                />
              </div>
              <div style={{ paddingLeft: '20px', position: 'relative' }}>
                <Typography
                  component={'span'}
                  sx={{
                    fontFamily: 'Archivo',
                    color: 'text.Gray2',
                    letterSpacing: '1px',
                    fontSize: '12px',
                  }}
                >
                  AGENT
                </Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    height: '7px',
                    width: '14px',
                    backgroundColor: '#5421CA96',
                    top: '44%',
                    left: '0',
                  }}
                />
              </div>
            </Box>

            <Box
              //   className=""
              data-html2canvas-ignore
              style={{
                display: 'flex',
                alignContent: 'center',
                paddingLeft: '40px',
              }}
            >
              <IconButton
                onClick={downloadClickHandler}
                disabled={!allowDownload}
                sx={{ padding: '0px', margin: '0px 10px' }}
              >
                <DownloadIcon
                  sx={{ color: !allowDownload ? 'grey' : 'primary.main' }}
                />{' '}
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            width: '100%',
            marginBottom: '0px',
            color: 'background.border',
            opacity: 0.5,
            //bottom: 0,
            //position: "absolute",
          }}
        />

        <>
          {cloneElement(children, {
            selectedEnrollmentWindowObjParent,
            setSelectedEnrollmentWindowObjParent,
            downloadFunction: downloadFunction,
            setDownloadFunction: setDownloadFunction,
            setAllowDownload: setAllowDownload,
            setReportName: setReportName,
          })}
        </>
      </Card>
    </>
  );
}
