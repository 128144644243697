import React from 'react';
import { useEffect } from 'react';

// MuiComponents
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

/**
 * The FlexSnackbar is a new, unified version of the SaveChangesSnackbar and UndoActionSnackbar components.
 * The goal is to create a single component that can be used in multiple places in the app. This component
 * will have the same functionality as the SaveChangesSnackbar and UndoActionSnackbar components, but will be
 * more flexible and (hopefully) easier to use.
 *
 * @param {
 *  displayAlert: boolean,
 *  setDisplayAlert: function,
 *  text: string,
 *  autoHideDuration: number,
 *  severity: string,
 *  parent: string,
 *  actionFunction: function
 *  cleanupFunctions: function[]
 * } props
 */

function SlideTransitionLeft(props) {
  return <Slide {...props} direction="left"></Slide>;
}

function SlideTransitionUp(props) {
  return <Slide {...props} direction="up"></Slide>;
}

export default function FlexSnackbar(props) {
  let undoClicked = false;

  // Snackbar params
  const showAlert = props.displayAlert;
  const text = props.text ? props.text : '';
  const autoHideDuration = props.autoHideDuration
    ? Number(props.autoHideDuration)
    : 10000;
  const severity = props.severity ? props.severity : 'info';
  const parent = props.parent ? props.parent : 'page';

  // Snackbar function params from parent component
  const setDisplayAlert = props.setDisplayAlert ? props.setDisplayAlert : null;
  const actionFunction = props.actionFunction ? props.actionFunction : null;
  const cleanupFunctions = props.cleanupFunctions
    ? props.cleanupFunctions
    : null;

  // Snackbar Styling
  const undoSnackbarStyle = {
    undoButton: { color: 'primary.main', fontSize: '14px!important' },
    closeSnackbarButton: { width: '30px' },
    closeIcon: { color: 'text.primary', width: '20px' },
    baseUndoSnackbar: {
      '.MuiSnackbar-root': { borderRadius: '4px' },
      '.MuiSnackbarContent-root': {
        minWidth: '375px',
        color: 'text.primary',
        fontSize: '14px!important',
        borderRadius: '4px',
        background: 'rgba(0, 96, 255, 0.11)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      },
    },
  };

  // Snackbar functions
  // This function will signal the parent component to close the snackbar
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    // Communicate to parent component that the alert should be closed
    setDisplayAlert(false);
  }

  // This function will run cleanup functions passed from the parent component. You can pass in multiple cleanup functions and they
  // will run sequentially so make sure you pass them in the order you want them to run.
  const handleUndoCleanup = React.useCallback(() => {
    // Call the cleanup functions passed from the parent component
    for (let i = 0; i < cleanupFunctions.length; i++) {
      const cleanupFunction = cleanupFunctions[i];
      cleanupFunction();
    }
  }, [cleanupFunctions]);

  // Hooks
  // This effect hook will only run when there is an actionFunction passed to the component. This hook will wait for 5 seconds and then
  // run the actionFunction passed from the parent component. If undo is clicked then the cleanup functions will run and will cancel the
  // actionFunction.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (actionFunction && !undoClicked) {
        actionFunction();
      }
    }, autoHideDuration);

    if (undoClicked) {
      handleUndoCleanup();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [actionFunction, undoClicked, handleUndoCleanup, autoHideDuration]);

  return (
    // Return the basic snackbar component
    <Snackbar
      open={showAlert}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={actionFunction ? text : null}
      TransitionComponent={
        parent === 'drawer' ? SlideTransitionUp : SlideTransitionLeft
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: parent === 'drawer' ? 'center' : 'right',
      }}
      sx={{
        ...undoSnackbarStyle.baseUndoSnackbar,
        marginBottom: '50px',
        marginRight: parent === 'drawer' ? '0' : '50px',
        position: parent === 'drawer' && 'absolute',
      }}
      action={
        <>
          {/* Conditionally render the Undo button if there is an action passed to undo */}
          {actionFunction ? (
            <IconButton
              size="small"
              aria-label="undo"
              color="inherit"
              onClick={handleUndoCleanup}
              sx={undoSnackbarStyle.undoButton}
            >
              UNDO
            </IconButton>
          ) : null}
          {/* Close button */}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            sx={undoSnackbarStyle.closeSnackbarButton}
          >
            <CloseIcon fontSize="small" sx={undoSnackbarStyle.closeIcon} />
          </IconButton>
        </>
      }
    >
      {/* Conditionally render if there is no action to undo i.e this is just an alert */}
      {!actionFunction && (
        <MuiAlert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={severity}
          sx={{ width: '100%' }}
        >
          <Typography>{text}</Typography>
        </MuiAlert>
      )}
    </Snackbar>
  );
}
