import React, { useEffect, useState } from 'react';

//Mui components
import {
  Card,
  CardHeader,
  Slide,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material';

//Mui icons
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

//our components
import InfinityLoader from '../../../../UH-loading-animation/InfinityLoader';

//our styles
import reportingDashboardStyles from '../../reportingDashboardStyles';

//Services
import dashboardService from '../../../../../services/dashboard-service';

//recharts
import { Bar, BarChart, XAxis, ResponsiveContainer } from 'recharts';

export default function PostEnrollmentWorkStatus(props) {
  const [loading, setLoading] = useState(false);
  let theme = useTheme();

  const [results, setResults] = useState([]);
  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getPostEnrollWorkingStatus(
        props.groupPlanId
      );
      setResults(_results);
    } catch (error) {
      console.error('error getting PostEnrollmentWorkStatus', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.groupPlanId) {
      getReportResults();
    }
  }, [props.groupPlanId]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ padding: '20px' }}>
          <p className="label">{`${label}`}</p>
          <p>{`${payload[0]?.payload?.count}`} Enrollment(s)</p>
        </Paper>
      );
    }
    return null;
  };

  const renderBarShape = (props) => {
    const getPath = (x, y, width, height) => {
      return `M${x},${y}
         v${height}
         h${width - 4}
         a4,4 0 0 0 4,-4
         v${-height + 8}
         a4,4 0 0 0 -4,-4
         h${-width}
         z
         `;
    };
    return (
      <path
        {...props}
        d={getPath(props.x, props.y, props.width, props.height)}
        stroke={props.stroke}
        fill={props.fill}
        strokeLinejoin="round"
        strokeWidth={'3px'}
        strokeLinecap="round"
      />
    );
  };

  const [builtBars, setBuiltBars] = useState([]);
  const buildBars = (results) => {
    //array of objects to map over, has color information and width information

    let total = 0;
    let builtBars = [
      // {}
    ];
    let color;

    //build inital list
    for (let resultObj of results) {
      total += resultObj.enrollmentCount;
      switch (resultObj.status) {
        case 'Actively Working':
          color = '#00C814';
          break;
        case 'On Disability':
          color = '#9747FF';
          break;
        case 'On Medical Leave':
          color = '#5421C9';
          break;
        case 'Furloughed':
          color = '#195FFB';
          break;
        case 'Retired':
          color = '#FBBC04';
          break;
        case 'Deceased':
          color = '#E94235';
          break;
        case 'Suspended':
          color = '#FE7C04';
          break;
        case 'Laid Off':
          color = '#B90270';
          break;
        default:
          color = '#195FFB';
          break;
      }

      let builtBar = {
        status: resultObj.status,
        count: resultObj.enrollmentCount,
        color: color,
      };
      builtBars.push(builtBar);
    }
    //make sure we have at least 4% width, keep track of what we're adding to get there, and how many are already above 4
    let aboveMinPercentCount = 0;
    let difference = 0;
    for (let builtBar of builtBars) {
      let widthPercent = (builtBar.count / total) * 100;
      if (widthPercent < 4) {
        difference += Number((4 - widthPercent).toPrecision(5));
        widthPercent = 4;
      } else {
        // widthPercent = widthPercent.toPrecision(4)
        aboveMinPercentCount++;
        builtBar.aboveMin = true;
      }
      builtBar.widthPercent = widthPercent;
    }

    //Take away the difference equally from all above 4% width
    for (let builtBar of builtBars) {
      if (builtBar?.aboveMin) {
        let newPercent =
          builtBar.widthPercent - difference / aboveMinPercentCount;
        builtBar.widthPercent = `${newPercent.toPrecision(4)}%`;
      } else builtBar.widthPercent = `${builtBar.widthPercent.toPrecision(4)}%`;
    }

    setBuiltBars(builtBars);
  };
  useEffect(() => {
    if (results) buildBars(results);
  }, [results]);

  return (
    <>
      <Box sx={reportingDashboardStyles.smallReportContainer}>
        {loading && (
          <>
            <InfinityLoader
              style={reportingDashboardStyles.individualReportInfinityLoader}
            />
            <style>
              {`
                    div.lottie-div > svg {
                      height:55px!important;
                      position:absolute;
                      top:30%;
                    }
                `}
            </style>
          </>
        )}
        <div
          style={{
            height: '30px',
            width: '100%',
            padding: '13px 13px 0px 18px',
            display: 'flex',
          }}
        >
          {builtBars?.length > 0 &&
            builtBars.map((builtBar, i) => {
              return (
                <div
                  style={{
                    height: '9px',
                    display: 'inline-block',
                    width: builtBar.widthPercent,
                    backgroundColor: builtBar.color,
                    borderTopLeftRadius: i == 0 ? '100px' : '',
                    borderBottomLeftRadius: i == 0 ? '100px' : '',
                    borderTopRightRadius:
                      i == builtBars?.length - 1 ? '100px' : '',
                    borderBottomRightRadius:
                      i == builtBars?.length - 1 ? '100px' : '',
                  }}
                ></div>
              );
            })}
        </div>

        <div
          style={{
            height: '100%',
            width: '100%',
            padding: '0px 30px 18px',
          }}
        >
          {builtBars?.length > 0 &&
            builtBars.map((builtBar) => {
              return (
                <div
                  className="flex-row"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        backgroundColor: builtBar.color,
                        borderRadius: '100px',
                        marginRight: '13px',
                      }}
                    />
                    <Typography
                      component={'span'}
                      sx={{
                        fontFamily: 'Archivo',
                        fontSize: '12px',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {builtBar?.status}
                    </Typography>
                  </div>

                  <Typography
                    component={'span'}
                    sx={{
                      fontFamily: 'Archivo',
                      fontSize: '12px',
                      letterSpacing: '1px',
                      textTransform: 'uppercase',
                      color: 'text.secondary',
                    }}
                  >
                    {props?.formatNumWithCommas(builtBar?.count)}
                  </Typography>
                </div>
              );
            })}
        </div>
      </Box>
    </>
  );
}
