import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Services
import userService from '../../../../services/user-service';
import permissionService from '../../../../services/permission-service';

// Assets

//MuiIcons

//MuiComponents
import Button from '@mui/material/Button';
import { InputLabel, TextField } from '@mui/material';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import FlexSnackbar from '../../../flex-snackbar/FlexSnackbar';

export default function SettingsProfile(props) {
  const [disableSave, setDisableSave] = useState(true);
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    id: 0,
    title: '',
    timezoneId: 0,
    roleId: 0,
  });
  const [editPassword, setEditPassword] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    newPasswordError: false,
    confirmNewPasswordError: false,
    oldPasswordError: false,
  });

  // Error / Success Messages
  const [message, setMessage] = useState({});

  useEffect(() => {
    if (props.thisUser) {
      let _userInfo = {
        firstName: props.thisUser.firstName,
        lastName: props.thisUser.lastName,
        email: props.thisUser.email,
        id: props.thisUser.id,
        memberId: props.thisUser.memberId,
        title: props.thisUser.title,
        timezoneId: 2,
        roleId: 1,
      };

      setUserInfo(_userInfo);
      setImage(userInfo.id);
    }
  }, [props.thisUser, userInfo.id]);

  useEffect(() => {
    if (editPassword) {
      setDisableSave(false);
    }
  }, [editPassword]);

  let handleInfoChange = (e, property) => {
    let value = e.target.value;
    setUserInfo({
      ...userInfo,
      [property]: value,
    });
    setDisableSave(false);
  };

  let handlePasswordInfoChange = (e, property) => {
    let value = e.target.value;

    // Remove the error for Old Password field
    if (property === 'oldPassword') {
      if (passwordInfo.oldPasswordError) {
        passwordInfo.oldPasswordError = false;
      }
    }

    // We need to verify that the new password and confirm new password match
    // We also need to verify that the new password is different from the old password
    if (property === 'newPassword') {
      if (value === passwordInfo.oldPassword && value !== '') {
        passwordInfo.newPasswordError = true;
      } else {
        passwordInfo.newPasswordError = false;
      }
    }

    // We are checking letter by letter to make sure the new passwords match. This way when the user is typing the confirm password, it won't instantly error out.
    if (property === 'confirmNewPassword') {
      if (value !== '') {
        for (let i = 0; i < value.length; i++) {
          if (value[i] !== passwordInfo['newPassword'][i]) {
            passwordInfo.confirmNewPasswordError = true;
            passwordInfo.newPasswordError = true;
            break;
          } else {
            passwordInfo.confirmNewPasswordError = false;
            passwordInfo.newPasswordError = false;
          }
        }
      } else {
        passwordInfo.confirmNewPasswordError = false;
        passwordInfo.newPasswordError = false;
      }
    }

    setPasswordInfo({
      ...passwordInfo,
      [property]: value,
    });

    if (passwordInfo.newPasswordError || passwordInfo.confirmNewPasswordError) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  const passwordKeyPressHandler = (e) => {
    // if (e.key === 'Enter') loginAsync()
    if (e.key === ' ') e.preventDefault();
  };

  //PROFILE PIC LOGIC
  let getFileAsBase64 = (file, cb) => {
    let reader = new FileReader();
    //reader is async, so we have to handle promise below
    reader.readAsDataURL(file);

    //callback to use result elsewhere
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (err) {
      console.error(err);
    };
  };

  let handlePictureDrop = (e) => {
    //default behavior is open the file, prevent it
    e.preventDefault();

    //obj expected by service
    let newPicObj = {
      image: '',
    };

    if (e.dataTransfer.files) {
      for (let file of e.dataTransfer.files) {
        getFileAsBase64(file, (result) => {
          //just the string, not the encoding
          newPicObj.image = result.replace(`data:${file.type};base64,`, '');
          try {
            userService.updateUserImage(userInfo.id, newPicObj);
          } catch (error) {
            console.error(error);
          }
        });
        setImage(userInfo.id);
      }
    }
  };

  const [image, setImage] = useState();

  let handleUploadClick = (e) => {
    // e.preventDefault()

    //obj expected by service
    let newPicObj = {
      image: '',
    };

    if (e.target.files) {
      for (let file of e.target.files) {
        getFileAsBase64(file, (result) => {
          //just the string, not the encoding
          newPicObj.image = result.replace(`data:${file.type};base64,`, '');
          userService.updateUserImage(userInfo.id, newPicObj);
          setImage(new Date());
          setMessage({
            ...message,
            messageDisplay: true,
            autoHideDuration: '1600',
            severity: 'success',
            backgroundColor: '',
            message: 'Successfully updated picture',
          });
        });
      }
    }
  };

  let handleSave = async (e) => {
    //HANDLE PASSWORD SAVE
    if (editPassword) {
      if (
        passwordInfo?.newPassword === passwordInfo?.confirmNewPassword &&
        passwordInfo?.newPassword !== passwordInfo?.oldPassword
      ) {
        try {
          await userService.updatePassword(
            passwordInfo?.newPassword,
            passwordInfo?.oldPassword
          );

          setEditPassword(false);

          setPasswordInfo({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          });
        } catch (err) {
          passwordInfo.oldPasswordError = true;
          console.error(err);
          setMessage({
            ...message,
            messageDisplay: true,
            severity: 'error',
            message: err.response.data.message ?? 'Error updating password.',
          });
        }
      }
    }

    // If there is an error with the password save, don't continue
    if (passwordInfo.oldPasswordError) {
      return;
    }

    //Handle info save
    try {
      await userService.updateMe(userInfo);
    } catch (err) {
      console.error(err);
      setMessage({
        ...message,
        messageDisplay: true,
        severity: 'error',
        message: err.response.data.message,
      });
    }
    setDisableSave(true);
    setMessage({
      ...message,
      messageDisplay: true,
      severity: 'success',
      message: 'Successfully Updated',
    });
    props.refreshUser();
  };

  return (
    <>
      {/* ACTUAL CONTENT */}
      <div
        className="container"
        style={{
          height: 'calc(100vh - 222px)',
          backgroundColor: 'FFFFFF',
          margin: 20,
          fontFamily: 'Archivo',
        }}
      >
        <div className="row" style={{ height: 'auto' }}>
          <div className="col-md-12" style={{ marginBottom: '40px' }}>
            <h5>Personal Information</h5>
            <p>Update your personal details and photo here.</p>
          </div>

          <div className={`col-md-3`}>
            <h6>Name</h6>
          </div>
          <div className={`col-md-9`}>
            <TextField
              variant="outlined"
              label="First Name"
              size="small"
              required
              value={userInfo?.firstName}
              error={userInfo?.firstName === ''}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'firstName')}
            />
            <TextField
              variant="outlined"
              label="Last Name"
              size="small"
              required
              value={userInfo?.lastName}
              error={userInfo?.lastName === ''}
              sx={{ marginBottom: '20px' }}
              onChange={(e) => handleInfoChange(e, 'lastName')}
            />
          </div>

          <div className={`col-md-3`}>
            <h6>Email Address</h6>
          </div>
          <div className={`col-md-9`}>
            <TextField
              variant="outlined"
              label="Email"
              size="small"
              required
              value={userInfo?.email}
              error={userInfo?.email === ''}
              sx={{ marginBottom: '40px' }}
              onChange={(e) => handleInfoChange(e, 'email')}
            />
          </div>
          <div className={`col-md-3`}>
            <h6>Your Photo</h6>
          </div>
          <div className={`col-md-9 flex-row`} style={{ margin: 'auto' }}>
            <Avatar
              sx={{
                bgcolor: '#195FFB17',
                color: '#195FFB',
                fontFamily: 'Archivo',
                fontWeight: '600',
                width: 100,
                height: 100,
                fontSize: 40,
                marginRight: '20px',
              }}
              src={`api/image/user/${userInfo.id}?${image}`}
            >
              {props.thisUser?.firstName &&
                `${props.thisUser?.firstName[0]?.toUpperCase()}${props.thisUser?.lastName[0]?.toUpperCase()}`}
            </Avatar>

            {/* UPLOAD AREA */}

            <div
              style={{
                width: '300px',
                height: 'auto',
                padding: '30px',
                border: '1px solid #D9D9D9',
                borderRadius: '33px',
                textAlign: 'center',
                marginBottom: '40px',
              }}
              //Need to prevent the browsers handling of ondragover to get the ondrop
              onDragOver={(e) => e.preventDefault()}
              onDrop={handlePictureDrop}
            >
              <span style={{ fontSize: '16px' }}>
                <Button
                  style={{
                    color: 'primary.main',
                    fontWeight: '600',
                    textDecoration: 'none',
                    padding: '0',
                  }}
                  component="label"
                >
                  Click to upload
                  <input
                    hidden
                    accept="image/svg,image/png,image/jpeg,image/gif"
                    type="file"
                    onChange={handleUploadClick}
                  />
                </Button>{' '}
                or drag and drop
              </span>
              <br />
              <span style={{ fontSize: '14px' }}>
                SVG, PNG, JPG, or GIF (max.800x400px)
              </span>
            </div>
          </div>
          <div className={`col-md-3`}>
            <h6>Job Title</h6>
          </div>
          <div className={`col-md-9`}>
            <TextField
              variant="outlined"
              label="Job Title"
              size="small"
              value={userInfo?.title}
              sx={{ marginBottom: '40px' }}
              onChange={(e) => handleInfoChange(e, 'title')}
            />
          </div>

          {/* Role */}
          <div className={`col-md-3`}>
            <h6>Role</h6>
          </div>
          <div className={`col-md-9`}>
            <FormControl sx={{ width: '300px', marginBottom: '20px' }}>
              <InputLabel>Role</InputLabel>
              <Select
                variant="outlined"
                size="large"
                fullWidth
                disabled={true}
                value={
                  userInfo?.roleId &&
                  props?.roles &&
                  props.roles?.find((x) => x.roleId == userInfo?.roleId)?.roleId
                }
                label="Role"
                onChange={(e) => handleInfoChange(e, 'roleId')}
              >
                {props?.roles &&
                  props.roles?.map((role) => {
                    return (
                      <MenuItem value={role.roleId}>
                        <span
                          style={{
                            fontFamily: 'Archivo',
                            paddingLeft: '10px',
                            margin: 'auto 0px',
                          }}
                        >
                          {role.roleName}
                        </span>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>

          {/* Password */}
          <div className={`col-md-3`}>
            <h6>Password</h6>
          </div>
          <div className={`col-md-9`}>
            {editPassword && (
              <>
                <TextField
                  variant="outlined"
                  label="Old Password"
                  size="small"
                  type="password"
                  error={passwordInfo.oldPasswordError}
                  helperText={
                    passwordInfo.oldPasswordError &&
                    'Make sure old password is correct.'
                  }
                  value={passwordInfo?.oldPassword}
                  sx={{ marginBottom: '40px' }}
                  onChange={(e) => handlePasswordInfoChange(e, 'oldPassword')}
                />
                <br />
                <TextField
                  variant="outlined"
                  label="New Password"
                  size="small"
                  type="password"
                  error={passwordInfo.newPasswordError}
                  helperText={
                    passwordInfo.newPasswordError &&
                    !passwordInfo.confirmNewPasswordError &&
                    'New password must be different from your old password.'
                  }
                  value={passwordInfo?.newPassword}
                  sx={{ marginBottom: '40px' }}
                  onChange={(e) => handlePasswordInfoChange(e, 'newPassword')}
                  onKeyDown={passwordKeyPressHandler}
                />
                <br />
                <TextField
                  variant="outlined"
                  label="Confirm New Password"
                  size="small"
                  type="password"
                  error={passwordInfo.confirmNewPasswordError}
                  helperText={
                    passwordInfo.confirmNewPasswordError &&
                    'New password values must match.'
                  }
                  disabled={
                    passwordInfo.newPasswordError &&
                    !passwordInfo.confirmNewPasswordError
                  }
                  value={passwordInfo?.confirmNewPassword}
                  sx={{ marginBottom: '40px' }}
                  onChange={(e) =>
                    handlePasswordInfoChange(e, 'confirmNewPassword')
                  }
                  onKeyDown={passwordKeyPressHandler}
                />
              </>
            )}
            {!editPassword && (
              <>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  onClick={() => setEditPassword(true)}
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    padding: 20,
                    height: '20px',
                    width: 'auto',
                    borderColor: 'primary.main',
                    borderRadius: '33px',
                    marginBottom: '10px',
                  }}
                >
                  Edit Password
                </Button>
              </>
            )}
          </div>
          <div
            style={{
              position: 'fixed',
              bottom: 40,
              right: 200,
              textAlign: 'right',
              width: 'auto',
            }}
          >
            <Button
              fullWidth
              size="small"
              variant="contained"
              disabled={disableSave}
              onClick={() => handleSave()}
              style={{
                fontSize: 16,
                fontWeight: 400,
                padding: 20,
                height: '20px',
                width: 'auto',
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
        <FlexSnackbar
          displayAlert={message.messageDisplay}
          setDisplayAlert={setMessage}
          severity={message.severity}
          text={message.message}
          autoHideDuration={message.autoHideDuration}
        />
      </div>
    </>
  );
}
