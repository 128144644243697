import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
  Tooltip,
} from 'recharts';

//Mui components
import { Card, CardHeader, Slide, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

//Mui icons
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

//Services
import dashboardService from '../../../../services/dashboard-service';

//Our Components
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
import PlaceHolderReport from './PlaceHolderReport';

//Our Styles
import reportingDashboardStyles from '../reportingDashboardStyles';

export default function EnrollmentActivity(props) {
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);
  const [totalInProgress, setTotalInProgress] = useState(0);
  const [totalStartedThisWeek, setTotalStartedThisWeek] = useState(0);
  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getEnrollmentsByState(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      setResults(_results);
      // props.setThisReport(_results);
    } catch (error) {
      console.error('error getting EnrollmentActivity', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.groupPlanId && props.enrollmentWindowId) {
      if (props.enrollmentWindows?.length > 0) {
        let validId = false;
        for (let window of props.enrollmentWindows) {
          if (window.id == props.enrollmentWindowId) {
            validId = true;
            break;
          }
        }
        if (validId) getReportResults();
      }
    }
  }, [props.enrollmentWindowId, props.enrollmentWindows]);

  const buildReportStates = () => {
    for (let resultObj of results) {
      if (resultObj?.enrollmentState == 'In Progress')
        setTotalInProgress(resultObj?.count);
      if (resultObj?.enrollmentState == 'In Progress Last Week')
        setTotalStartedThisWeek(resultObj?.count);
    }
  };

  useEffect(() => {
    if (results?.length > 0) {
      buildReportStates();
      if (props.setAllowDownload) props.setAllowDownload(true);
    } else {
      if (props.setAllowDownload) props.setAllowDownload(false);
    }
  }, [results, totalInProgress, totalStartedThisWeek]);

  useEffect(() => {
    async function downloadFunction() {
      let result = await dashboardService.getCsvEnrollmentsByState(
        props.groupPlanId,
        props.enrollmentWindowId,
        1
      );
      return result;
    }
    if (props.setDownloadFunction)
      props.setDownloadFunction(() => downloadFunction);
  }, [props.setDownloadFunction, props.groupPlanId, props.enrollmentWindowId]);

  useEffect(() => {
    if (props.setReportName) props.setReportName('InProgress_Enrollments');
  }, [props.setReportName]);

  // //for pie chart
  // const COLORS = [
  //   "rgba(25, 95, 251, 1)",
  //   "rgba(0, 200, 20, 1)",
  //   "rgba(84, 33, 201, 0.65)",
  // ];

  // //full count of all enrollments (count combined for all states)
  // let totalCount =
  //   results?.length > 0 ? results?.reduce((a, b) => a + b.count, 0) : 0;

  // //Pie chart hover label
  // const [hover, setHover] = useState(null);

  return (
    <>
      <Box sx={reportingDashboardStyles.smallReportContainer}>
        {loading && (
          <>
            <InfinityLoader
              style={reportingDashboardStyles.individualReportInfinityLoader}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
            </style>
          </>
        )}
        {results?.length > 0 ? (
          <div style={{ position: 'relative' }}>
            <div
              className="flex-row"
              style={{
                height: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div className="col-12">
                <Typography
                  variant="h4"
                  sx={{
                    color: '#5421C9',
                    marginTop: '23px',
                    marginBottom: '5px',
                  }}
                >
                  {totalInProgress > 0
                    ? props?.formatNumWithCommas(totalInProgress)
                    : 0}
                </Typography>
                <Typography
                  variant="overline"
                  style={{ letterSpacing: '1px', fontSize: '12px' }}
                >
                  IN PROGRESS ENROLLMENTS
                </Typography>
              </div>

              <Divider
                sx={{
                  margin: '10px 0px',
                  width: '70%',
                  color: 'background.border',
                }}
              />

              <div className="col-12">
                <Typography
                  variant="h4"
                  sx={{ color: '#5421C9', marginBottom: '5px' }}
                >
                  {totalStartedThisWeek > 0
                    ? props?.formatNumWithCommas(totalStartedThisWeek)
                    : 0}
                </Typography>
                <Typography
                  variant="overline"
                  style={{ letterSpacing: '1px', fontSize: '12px' }}
                >
                  IN THE LAST 7 DAYS
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <Box>
            <PlaceHolderReport />
          </Box>
        )}
      </Box>
    </>
  );
}
