import React, { useEffect, useState } from 'react';

//Mui Components
import {
  Alert,
  Box,
  Card,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Typography,
} from '@mui/material';

// Assets

//Mui icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { set } from 'date-fns';

//Our shared styles
import paymentsTabStyles from '../paymentsTabStyles';

//component styles
const componentStyles = {
  accountCard: (prenoteStatus) => ({
    padding: '8px 10px 8px 8px',
    width: '93%',
    minHeight: '96px',
    margin: '12px auto 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Poppins',
    border: '1px solid',
    borderColor: prenoteStatus ? 'rgba(211, 47, 47)' : 'background.border',
    backgroundColor: prenoteStatus
      ? 'rgba(211, 47, 47, 0.04)'
      : 'background.default',
  }),
  errorIcon: {
    color: 'rgba(211, 47, 47)',
    margin: '6px auto',
  },

  editAccountButton: {
    color: 'rgba(211, 47, 47)',
    fontSize: '12px !important',
    height: '24px',
    padding: '8px 6px',
    marginRight: '18px',
  },

  viewFullAccountButton: {
    fontSize: '10px',
    fontWeight: 500,
    padding: '0px',
    maxHeight: '20px',
    color: 'primary.main',
    alignSelf: 'flex-end',
    marginBottom: '3px',
  },
};
export default function PaymentBankAccounts(props) {
  //Handle three dot menu button
  const handleEditWalletMenu = (e) => {
    props.setEditWalletMenu({
      ...props.editWalletMenu,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  //manage displaying full bank account for user w/ permissions
  const [isShowFullAccount, setIsShowFullAccount] = useState(false);

  const [sortedAccounts, setSortedAccounts] = useState([]);

  //filter and sort bank accounts for display
  useEffect(() => {
    if (!props?.bankAccount || props?.bankAccount?.length < 1) {
      return;
    } else {
      let _filteredAccounts = [];
      let _sortedAccounts = [];

      _filteredAccounts = props?.bankAccount?.filter((account) => {
        //return accounts that are deleted
        if (!account.isDeleted) return account;
      });

      //sort bank accounts and display default account at top
      _sortedAccounts = _filteredAccounts?.sort((a, b) => {
        if (a.isPrimary) {
          return -1;
        }
        if (b.isPrimary) {
          return 1;
        } else return 0;
      });
      setSortedAccounts(_sortedAccounts);
    }
  }, [
    props?.bankAccount?.length,
    props?.bankAccount,
    props?.defaultAccount,
    props?.member?.id,
  ]);

  //Handle show full bank account button click
  const handleShowFullAccount = (e, index) => {
    //if value is true or 0 (index of 0 = first in list), set to false, else set to index of account
    if (isShowFullAccount || isShowFullAccount === 0) {
      setIsShowFullAccount(false);
    } else {
      setIsShowFullAccount(index);
    }
  };

  return (
    <>
      {/* Bank Account(s)  - display all*/}
      {(sortedAccounts?.length > 0 &&
        sortedAccounts?.map((account, index) => {
          return (
            <Box key={account.id}>
              <Card
                elevation={0}
                variant="outlined"
                sx={componentStyles.accountCard(account.prenoteStatusId === 4)}
              >
                {account.prenoteStatusId === 4 ? (
                  <Box style={paymentsTabStyles.iconContainer}>
                    <ErrorOutlineIcon sx={componentStyles.errorIcon} />
                  </Box>
                ) : (
                  <Box style={paymentsTabStyles.iconContainer}>
                    <AccountBalanceIcon
                      sx={paymentsTabStyles.accountBalanceIcon()}
                    />
                  </Box>
                )}

                {/* Member Name and Account Type */}
                <Box style={paymentsTabStyles.accountInfo}>
                  {/* Account Name - 4 digit view */}
                  <Typography
                    variant="body2"
                    sx={paymentsTabStyles.accountNameText()}
                  >
                    {account?.description ? `${account?.description} - ` : ''}{' '}
                    {props.getMemberAccountType(account.bankAccountTypeId)}{' '}
                    account
                  </Typography>

                  {/* Bank Info 4 digit account*/}
                  <Box sx={paymentsTabStyles.bankAccountInfoContainer}>
                    {((!isShowFullAccount && isShowFullAccount !== 0) ||
                      isShowFullAccount !== index) && (
                      <>
                        <Typography
                          variant="body2"
                          sx={paymentsTabStyles.bankAccountText()}
                        >
                          {account.bankName || 'Account'}
                        </Typography>

                        <Typography sx={paymentsTabStyles.bankAccountText()}>
                          {` *${account.accountNumber?.slice(-4)}`}
                        </Typography>
                      </>
                    )}

                    {/* Full bank account number if user has permissions and edit wallet drawer is not open */}
                    {props?.permissionsEnabler?.viewFullBankAccounts &&
                      !props.editWalletDrawerOpen &&
                      isShowFullAccount === index && (
                        <Typography
                          variant="body2"
                          sx={paymentsTabStyles.bankAccountText()}
                        >
                          {`${account.bankName || 'Account '} - ${
                            account?.accountNumber
                          }    Routing - ${account?.routingNumber}`}
                        </Typography>
                      )}

                    {/* Show/Hide full bank button */}
                    {props?.permissionsEnabler?.viewFullBankAccounts &&
                      !props.editWalletDrawerOpen && (
                        <Box
                          type="button"
                          size="small"
                          sx={componentStyles.viewFullAccountButton}
                          onClick={(e) => handleShowFullAccount(e, index)}
                        >
                          {isShowFullAccount === index ? 'HIDE' : 'SHOW'}
                        </Box>
                      )}
                  </Box>
                </Box>
                <Box sx={paymentsTabStyles.menuContainer}>
                  {account?.isPrimary && (
                    <>
                      <CheckIcon
                        sx={{ color: '#4CAF50', marginRight: '5px' }}
                      />
                      <Typography variant="body2">Default</Typography>
                    </>
                  )}
                  {/* Show radio button to select account in Make a Payment drawer - only show when drawer is open, disable all accounts that are not 'default account' if custom date is not entered, disable radio selection if account has failed prenote */}
                  {props.showSelectBankAccount && (
                    <Radio
                      disabled={
                        (!props.isCustomDate && account.isPrimary === false) ||
                        account.prenoteStatusId === 4
                      }
                      checked={props.selectedBankAccount?.id == account.id}
                      value={account}
                      onClick={(e) => props.handleBankAccountSelect(e, account)}
                      sx={{ padding: '0px 0px 0px 15px' }}
                    />
                  )}

                  {/* Menu dropdown to remove or make accoutn default */}
                  {(!account?.isPrimary ||
                    (account?.isPrimary && account?.prenoteStatusId === 4)) &&
                    props.editWalletDrawerOpen && (
                      <>
                        <IconButton
                          onClick={handleEditWalletMenu}
                          value={account.id}
                          sx={{ borderRadius: '100%' }}
                        >
                          <MoreVertIcon sx={paymentsTabStyles.moreVertIcon} />
                        </IconButton>
                        <Menu
                          id="edit-bankAccount-menu"
                          anchorEl={props.editWalletMenu?.[account.id]}
                          open={Boolean(props.editWalletMenu?.[account.id])}
                          onClose={props.handleCloseWalletMenu}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <MenuItem
                            value={account}
                            disabled={account.prenoteStatusId === 4}
                            onClick={(e) =>
                              props.changeDefaultAccount(e, account)
                            }
                          >
                            Set as default
                          </MenuItem>

                          <MenuItem
                            onClick={(e) =>
                              props.handleDeleteBank(e, account.id)
                            }
                            disabled={props?.undoSnackbarInfo?.action}
                          >
                            Archive
                          </MenuItem>

                          {/* Edit Account only visible if account has failed prenote */}
                          {account.prenoteStatusId === 4 &&
                            props.editWalletDrawerOpen && (
                              <MenuItem
                                onClick={(e) =>
                                  props.openEditFailedPrenote(e, account)
                                }
                              >
                                Edit Account
                              </MenuItem>
                            )}
                          <MenuItem
                            disabled={account.prenoteStatusId !== 4}
                            onClick={(e) =>
                              props.handleUnblockBank(e, account.id)
                            }
                          >
                            Reset Prenote Status
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                </Box>
              </Card>

              {/* Show Failed Prenote Message if account has failed prenote */}
              {account.prenoteStatusId === 4 && (
                <Box
                  className="flex-row"
                  sx={{
                    justifyContent: 'space-between ',
                    alignContent: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'rgba(211, 47, 47)',
                      fontSize: '12px !important',
                      marginLeft: '28px',
                      fontWeight: 500,
                    }}
                  >
                    Failed Prenote
                  </Typography>
                </Box>
              )}
              {/* // Display message after prenote status update */}
              <Fade
                in={props?.displayPrenoteSuccessMessage === account.id}
                // in={true}
                mountOnEnter
                unmountOnExit
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'text.secondary',
                    width: '480px',
                    margin: '0px auto',
                    padding: '4px 10px',
                  }}
                >
                  Prenote status has been reset. Please note - this does not
                  guarantee blocks removed from the bank processor. If this
                  continues to error, please contact support.
                </Typography>
              </Fade>
            </Box>
          );
        })) || (
        <Card
          className="flex-row"
          elevation={0}
          variant="outlined"
          sx={componentStyles.accountCard(false)}
        >
          <Typography variant="h6" sx={{ padding: '10px' }}>
            No Bank Information
          </Typography>
        </Card>
      )}
    </>
  );
}
