import React from 'react';

//MuiComponents
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

//Muicons
import Home from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

export default function DrawerMenuItem(props) {
  let icon;
  switch (props.text) {
    case 'Home':
      icon = <Home sx={{ marginRight: '32px' }} />;
      break;
    case 'Plan Information':
      icon = <ReceiptIcon sx={{ marginRight: '32px' }} />;
      break;
    case 'Support':
      icon = <PhoneIcon sx={{ marginRight: '32px' }} />;
      break;
    case 'Settings':
      icon = <SettingsIcon sx={{ marginRight: '32px' }} />;
      break;
    case 'Logout':
      icon = <LogoutIcon sx={{ marginRight: '32px' }} />;
      break;
    default:
      icon = null;
      break;
  }

  return (
    <MenuItem
      className="flex-row"
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px 16px',
        color: 'primary.gray',
      }}
      data-uhtest={`${props.textNavButton}NavButton`}
      onClick={props.NavigateClick}
    >
      {icon}

      <Typography
        variant=""
        component="div"
        sx={{
          flexGrow: 1,
          fontFamily: 'Archivo',
          fontWeight: '400',
          fontSize: '16px',
          letterSpacing: '0.15px',
          fontFeatureSettings: '"clig" off, "liga" off',
        }}
      >
        {props.text}
      </Typography>
    </MenuItem>
  );
}
