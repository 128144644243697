import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, differenceInCalendarYears } from 'date-fns';

//Services
import optionService from '../../../../../services/optionService';
import memberService from '../../../../../services/member-service';
import organizationService from '../../../../../services/organization-service';

//MuiIcons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LanguageIcon from '@mui/icons-material/Language';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';

//Mui Components
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
//Our components
import MemberInfoTop from './member-info-sections/MemberInfoTop';
import MemberInfoMiddle from './member-info-sections/MemberInfoMiddle';
import MemberInfoBottom from './member-info-sections/MemberInfoBottom';

//custom styles for scrollbar
import './memberInfoScroller.css';

export default function MemberInfo(props) {
  let profileDisabled = props.profileDisabled;
  let disabledProfileStyle = props.disabledProfileStyle;

  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const [member, setMember] = useState();
  useEffect(() => {
    setLoading(true);
    if (props.phones) {
      setLoading(false);
    }
    if (props.member) {
      setMember(props.member);
      getEmployers(props.member?.organizationId);
      getEmploymentStatuses();
      getGenderOptions();
      setLoading(false);
      // getPhones()
    }
  }, [props.member, props.member?.organizationId]);

  const [employmentStatuses, setEmploymentStatuses] = useState();
  let getEmploymentStatuses = async () => {
    let _statuses = await optionService.getWorkStatus();
    setEmploymentStatuses(_statuses);
  };

  const [genderOptions, setGenderOptions] = useState();
  let getGenderOptions = async () => {
    let _genderOptions = await optionService.getGenderTypes();
    setGenderOptions(_genderOptions);
  };

  const [employers, setEmployers] = useState();
  // this function is picked as is from EnrollmentPersonalInfo.js, not imported because unsure of circular imports that might happen later.
  const getEmployers = async (orgId) => {
    let _employers;
    //for BLET only, HARDCODE organizationId=18 to require specific employer list
    if (props?.member?.organizationName?.includes('BLET' || 'blet')) {
      _employers = await organizationService.getEmployersByOrganization(18);
    } else {
      _employers = await organizationService.getEmployersByOrganization(orgId);
    }

    if (_employers) {
      // [TODO:ORGNAME] 2311 -> 2186 Bookmark.  This is targetting employer.name, not not organization.name, so it should remain unchanged.
      //sort employers alphabetically
      let sortedEmployers = _employers?.sort((a, b) => {
        const nameA = a.name.replace(/"/g, '').toLowerCase(); // Remove quotes and convert to lowercase
        const nameB = b.name.replace(/"/g, '').toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setEmployers(sortedEmployers);
    } else setEmployers([]);
  };

  return (
    <>
      <Box
        className="scrollable-content"
        sx={{
          borderRight: 'solid 1px',
          borderColor: 'background.border',
          height: 'calc(100vh - 56px)',
          minWidth: '350px',
          width: '350px',
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'relative',
          fontFamily: 'Archivo',
          ...disabledProfileStyle,
        }}
      >
        {/* TOP SECTION */}
        <>
          <MemberInfoTop
            member={member}
            permissionsEnabler={props.permissionsEnabler}
            updateMember={props.updateMember}
            getThisMember={props.getThisMember}
            genderOptions={genderOptions}
            phones={props.phones}
            setPhones={props.setPhones}
            loading={loading}
            setLoading={setLoading}
            // addPhone={addPhone} updatePhone={updatePhone}
            primaryPhone={props.primaryPhone}
            setPrimaryPhone={props.setPrimaryPhone}
            secondaryPhone={props.secondaryPhone}
            setSecondaryPhone={props.setSecondaryPhone}
            handlePhoneInsert={props.handlePhoneInsert}
            languageTypes={props.languageTypes}
            setMessage={props.setMessage}
            profileDisabled={props.profileDisabled}
          />
        </>

        {/* MIDDLE SECTION */}
        <>
          <MemberInfoMiddle
            member={member}
            getThisMember={props.getThisMember}
            updateMember={props.updateMember}
            UsdFormatter={UsdFormatter}
            employmentStatuses={employmentStatuses}
            organizations={props.organizations}
            loading={loading}
            setLoading={setLoading}
            employers={employers}
            getEmployers={getEmployers}
            profileDisabled={props.profileDisabled}
          />
        </>
        {/* BOTTOM SECTION */}
        <>
          <MemberInfoBottom
            member={member}
            getThisMember={props.getThisMember}
            dependents={props.dependents}
            saveDependents={props.saveDependents}
            updateMember={props.updateMember}
            addDependent={props.addDependent}
            deleteDependent={props.deleteDependent}
            genderOptions={genderOptions}
            getSpouse={props.getSpouse}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            saveSpouseInfo={props.saveSpouseInfo}
            loading={loading}
            setLoading={setLoading}
            refreshHistory={props?.refreshHistory}
            profileDisabled={props.profileDisabled}
          />
        </>
      </Box>
    </>
  );
}
