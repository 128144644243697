import React, { useState, useEffect } from 'react';
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  PolarAngleAxis,
} from 'recharts';

//Mui components
import {
  Card,
  CardHeader,
  IconButton,
  Input,
  Slide,
  Tooltip,
  Typography,
  breadcrumbsClasses,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

//Mui icons
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//services
import dashboardService from '../../../../../services/dashboard-service';
import { format, set } from 'date-fns';

//Our components
import InfinityLoader from '../../../../UH-loading-animation/InfinityLoader';
import PlaceHolderReport from '../PlaceHolderReport';
import RadialBarChartTemplate from './RadialBarChartTemplate';
import ZeroDashboardGoalView from './ZeroDashboardGoalView';

//Our styles
import reportingDashboardStyles from '../../reportingDashboardStyles';

export default function GoalOdometer(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  // permissions from state
  let allowView = props?.permissions?.viewGroupPlans;
  let allowEdit = props?.permissions?.editGroupPlans;
  //let allowEdit = false // temporarily false while we hide the goal setting functionality

  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    enrollments: true,
    premiums: false,
  });

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 2;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [results, setResults] = useState([]);
  const [enrollmentGoal, setEnrollmentGoal] = useState(0);
  const [premiumGoal, setPremiumGoal] = useState(0);

  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getEnrollmentGoal(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      setResults(_results);

      setEnrollmentGoal(
        _results.find((obj) => obj.name === 'Total Enrollments')
      );

      //debug
      // setEnrollmentGoal({name: "Total Enrollments", goal: 100, actual: 70})

      setPremiumGoal(_results.find((obj) => obj.name === 'Total Premium'));
    } catch (error) {
      console.error('error getting goals', error);
      //set name only of enrollment goal and premium goal
      setEnrollmentGoal({ name: 'Total Enrollments' });
      setPremiumGoal({ name: 'Total Premium' });
    }

    setLoading(false);
  };

  //download submitted enrollments
  useEffect(() => {
    if (enrollmentGoal?.actual > 0) {
      if (props.setAllowDownload) props.setAllowDownload(true);
    } else {
      if (props.setAllowDownload) props.setAllowDownload(false);
    }
  }, [enrollmentGoal?.actual]);

  useEffect(() => {
    if (props.groupPlanId && props.enrollmentWindowId) {
      if (props.enrollmentWindows?.length > 0) {
        let validId = false;
        for (let window of props.enrollmentWindows) {
          if (window.id == props.enrollmentWindowId) {
            validId = true;
            break;
          }
        }
        if (validId) getReportResults();
      }
    }
  }, [props.enrollmentWindowId, props.enrollmentWindows]);

  useEffect(() => {
    async function downloadFunction() {
      let result = await dashboardService.getCsvEnrollmentsByState(
        props.groupPlanId,
        props.enrollmentWindowId,
        2
      );
      return result;
    }
    if (props.setDownloadFunction)
      props.setDownloadFunction(() => downloadFunction);
  }, [props.setDownloadFunction, props.groupPlanId, props.enrollmentWindowId]);

  useEffect(() => {
    if (props.setReportName) props.setReportName('Submitted_Enrollments');
  }, [props.setReportName]);

  const [showPremGoalEntry, setShowPremGoalEntry] = useState(false);
  const [showEnrollGoalEntry, setShowEnrollGoalEntry] = useState(false);

  //DISPLAY EDIT GOAL
  const handleDisplayEditGoal = (e, goal) => {
    if (goal?.name === 'Total Enrollments') {
      setShowEnrollGoalEntry(true);
    } else if (goal?.name === 'Total Premium') {
      setShowPremGoalEntry(true);
    }
  };

  const handleCancelEditGoal = (e, goal) => {
    if (goal?.name === 'Total Enrollments') {
      setShowEnrollGoalEntry(false);
    } else if (goal?.name === 'Total Premium') {
      setShowPremGoalEntry(false);
    }
  };

  //all for debugging - need to delete after
  // const practiceDataEnrollment = {
  //   id: 10,
  //   name: "Total Enrollments",
  //   goal: 0,
  //   actual: 70,
  // }
  // const practiceDataPremium = {
  //   id: 2,
  //   name: "Total Premium",
  //   goal: 800, //make smaller to test fixing full circle radial chart
  //   actual: 7000,
  // }

  return (
    <>
      <Box sx={reportingDashboardStyles.smallReportContainer}>
        {loading && (
          <>
            <InfinityLoader
              style={reportingDashboardStyles.individualReportInfinityLoader}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
            </style>
          </>
        )}

        {/* Enrollment Goals */}
        <Box sx={{ height: '100%', display: activeStep == 0 ? '' : 'none' }}>
          {/* Eventually, we'll want to put all of the logic below within this switch, and put all of it within the slide. Needs the API to return "actual" even if there isn't a goal present. */}

          {/* {(enrollmentGoal && Object.keys(enrollmentGoal).length > 0) ? (
            <>
              <Insert the below logic within Slide />
            </>
          ) : (
            <Box>
              <PlaceHolderReport />
            </Box>
          )} */}

          <Slide direction="right" in={activeStep == 0}>
            {enrollmentGoal &&
            enrollmentGoal?.goal > 0 &&
            enrollmentGoal.actual > 0 ? (
              <Box>
                <RadialBarChartTemplate
                  data={enrollmentGoal}
                  // data={practiceDataEnrollment}
                  UsdFormatter={UsdFormatter}
                  formatNumWithCommas={props?.formatNumWithCommas}
                />
              </Box>
            ) : (
              <Box>
                <ZeroDashboardGoalView
                  showGoalEntry={showEnrollGoalEntry}
                  setShowEnrollGoalEntry={setShowEnrollGoalEntry}
                  setShowPremGoalEntry={setShowPremGoalEntry}
                  handleDisplayEditGoal={handleDisplayEditGoal}
                  handleCancelEditGoal={handleCancelEditGoal}
                  enrollmentGoal={enrollmentGoal}
                  // enrollmentGoal={practiceDataEnrollment}
                  getReportResults={getReportResults}
                  allowEdit={allowEdit}
                  formatNumWithCommas={props?.formatNumWithCommas}
                  UsdFormatter={UsdFormatter}
                />
              </Box>
            )}
          </Slide>
        </Box>

        <Box sx={{ height: '100%', display: activeStep == 1 ? '' : 'none' }}>
          {/* Eventually, we'll want to put all of the logic below within this switch, and put all of it within the slide. Needs the API to return "actual" even if there isn't a goal present. */}

          {/* {(premiumGoal && Object.keys(premiumGoal).length > 0) ? (
            <>
              <Insert the below logic within Slide />
            </>
          ) : (
            <Box>
              <PlaceHolderReport />
            </Box>
          )} */}

          <Slide direction="left" in={activeStep == 1}>
            {premiumGoal && premiumGoal?.goal > 0 && premiumGoal?.actual > 0 ? (
              // {practiceDataPremium &&
              // practiceDataPremium?.goal > 0 &&
              // practiceDataPremium?.actual > 0 ? (
              <Box>
                <RadialBarChartTemplate
                  UsdFormatter={UsdFormatter}
                  formatNumWithCommas={props?.formatNumWithCommas}
                  data={premiumGoal}
                  // data={practiceDataPremium}
                  width={220}
                  height={200}
                />
              </Box>
            ) : (
              <Box>
                <ZeroDashboardGoalView
                  showGoalEntry={showPremGoalEntry}
                  setShowEnrollGoalEntry={setShowEnrollGoalEntry}
                  setShowPremGoalEntry={setShowPremGoalEntry}
                  handleDisplayEditGoal={handleDisplayEditGoal}
                  handleCancelEditGoal={handleCancelEditGoal}
                  premiumGoal={premiumGoal}
                  // premiumGoal={practiceDataPremium}
                  getReportResults={getReportResults}
                  allowEdit={allowEdit}
                  formatNumWithCommas={props?.formatNumWithCommas}
                  UsdFormatter={UsdFormatter}
                />
              </Box>
            )}
          </Slide>
        </Box>
      </Box>

      <Box
        data-html2canvas-ignore
        sx={{ position: 'absolute', bottom: 3, left: 15, right: 15 }}
      >
        <MobileStepper
          steps={2}
          position="static"
          activeStep={activeStep}
          sx={{ padding: '0px', backgroundColor: 'transparent' }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{ justifyContent: 'flex-end', padding: '8px' }}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ justifyContent: 'flex-start', padding: '8px' }}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </Box>
    </>
  );
}
