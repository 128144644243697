import React, { useState } from 'react';

//Mui icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

//Mui Components
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

//Our Components
import CreateNoteDrawer from '../../member-notes-tab/CreateNoteDrawer';

//Component styles
const componentStyles = {
  componentBody: {
    minHeight: '166px',
    width: '100%',
    fontFamily: 'Archivo',
    padding: '18px 16px 26px',
    backgroundColor: 'background.default',
    // border: "1px solid red",
  },
  headerContainer: {
    margin: '0px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  title: {
    color: 'text.primary',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '32px',
    marginBottom: '8px',
  },
  description: {
    color: 'text.secondary',
    fontFamily: 'Archivo',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    letterSpacing: '0.15px',
    marginBottom: '18px',
  },
  dateAndButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dateSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: 'text.secondary',
  },
  preSelectedDatePicker: {
    margin: ' 4px 16px 4px 0px',
    width: '155px',
    '& fieldset': {
      borderRadius: '8px',
      height: '42px',
    },
    '& .MuiSelect-select': {
      padding: '8px 12px',
      fontSize: '14px',
    },
  },
  customDatePicker: {
    minWidth: '150px',
    margin: ' 4px 16px 4px 0px',
    padding: '0px',
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
      fontSize: '14px', // Adjust the font size
    },
    '& fieldset': {
      borderRadius: '8px',
      height: '42px',
    },
  },
  createNoteButton: {
    height: '36px',
    minWidth: '149px',
    fontSize: '14px',
    padding: '0px 12px 0px 8px',
    margin: '6px 0px',
  },
  addIcon: {
    height: '20px',
    marginRight: '2px',
  },
  clearDateButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clearDateButton: {
    borderRadius: '8px',
  },
  closeIcon: {
    color: 'text.secondary',
    height: '18px',
    marginRight: '4px',
  },
  clearDateText: { fontSize: '13px!important', color: 'text.secondary' },
};

export default function HistoryHeader(props) {
  //PROPS
  const {
    member,
    dateRange,
    setDateRange,
    preSelectedDateRange,
    today,
    beginningOfTimeForMember,
    getMemberHistory,
    loading,
    setLoading,
  } = props;

  //Clear date range filter and reset to past 6 months
  const clearDateRangeFilter = async () => {
    setLoading(true);
    setDateRange({
      name: 'Past 24 months',
      startDate: preSelectedDateRange[4].startDate,
      endDate: preSelectedDateRange[4].endDate,
    });

    //get new data from db
    let _history = await getMemberHistory(
      preSelectedDateRange[4].startDate,
      preSelectedDateRange[4].endDate
    );

    if (_history) {
      setLoading(false);
    }
  };
  //handle pre-selected date range change
  const handleUpdatePreSelectedDateRange = async (e) => {
    let selectedDateRange = preSelectedDateRange?.find(
      (range) => range.name === e.target.value
    );

    if (selectedDateRange?.name === 'Custom') {
      setDateRange({
        ...dateRange,
        name: 'Custom',
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });

      setLoading(false);
      return;
    }
    setDateRange(selectedDateRange);

    //get new data from db

    let _history = await getMemberHistory(
      selectedDateRange?.startDate,
      selectedDateRange?.endDate
    );

    if (_history) {
      setLoading(false);
    }
  };

  const [dateErrorOne, setDateErrorOne] = useState(null);
  const [dateErrorTwo, setDateErrorTwo] = useState(null);

  //Update history data with custom dates
  const handleDateRangeChange = async (e, type) => {
    if (e?.getTime() != e?.getTime()) {
      // alert("Invalid date entered")
      return;
    }
    let dateSelected = e;
    let validDate = dateSelected ? dateSelected.toJSON() : null;
    //issue with dobErrorOne and two, both are a step behind showing the error

    if (
      validDate
      // &&  !dateErrorOne
      // &&  !dateErrorTwo
    ) {
      setLoading(true);
      //update date range state
      setDateRange({
        ...dateRange,
        [type]: dateSelected,
        name: 'Custom',
      });
      //get new data from db
      try {
        if (type === 'endDate' && dateRange?.startDate) {
          await getMemberHistory(dateRange?.startDate, dateSelected);
          setLoading(false);
        } else if (type === 'startDate' && dateRange?.endDate) {
          await getMemberHistory(dateSelected, dateRange?.endDate);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error updating history data', error);
      }
    } else {
      //invalid date entered
      //update date range state
      setDateRange({
        ...dateRange,
        [type]: null,
        name: 'Custom',
      });
      setLoading(false);
    }
  };

  //Create new note
  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);

  const refreshHistoryAfterNoteCreation = async () => {
    await getMemberHistory(dateRange?.startDate, dateRange?.endDate);
  };

  return (
    <Box sx={componentStyles.componentBody}>
      <CreateNoteDrawer
        member={member}
        noteDrawerOpen={noteDrawerOpen}
        setNoteDrawerOpen={setNoteDrawerOpen}
        refreshHistoryAfterNoteCreation={refreshHistoryAfterNoteCreation}
      />
      <Box sx={componentStyles.headerContainer}>
        <Typography sx={componentStyles.title}>History</Typography>
        <Typography sx={componentStyles.description}>
          Dates and descriptions of significant events in the member's profile.
        </Typography>
        <Box sx={componentStyles.dateAndButtonContainer}>
          <Box sx={componentStyles.dateSelectorContainer}>
            {/* pre selected date range menu */}
            <TextField
              id="date"
              label="Date Range"
              select
              value={dateRange?.name || 'Custom'}
              InputLabelProps={{
                shrink: true,
              }}
              sx={componentStyles.preSelectedDatePicker}
              onChange={handleUpdatePreSelectedDateRange}
            >
              {preSelectedDateRange.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option?.name}
                  sx={{ fontSize: '14px' }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            {/* start date*/}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                label="Start Date"
                views={['year', 'month', 'day']}
                minDate={
                  preSelectedDateRange[4].startDate || beginningOfTimeForMember
                }
                maxDate={today}
                value={dateRange?.startDate || null}
                disableFuture
                onChange={(e) => handleDateRangeChange(e, 'startDate')}
                format="MM/dd/yyyy"
                sx={componentStyles.customDatePicker}
                onError={(error) => setDateErrorOne(error)}
                slotProps={{
                  textField: {
                    InputLabelProps: {
                      shrink: true,
                    },
                  },
                }}
              />
            </LocalizationProvider>

            {/* end date */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                label="End Date"
                views={['year', 'month', 'day']}
                minDate={
                  preSelectedDateRange[4].startDate || beginningOfTimeForMember
                }
                maxDate={today}
                value={dateRange?.endDate || null}
                disableFuture
                onChange={(e) => handleDateRangeChange(e, 'endDate')}
                format="MM/dd/yyyy"
                sx={componentStyles.customDatePicker}
                onError={(error) => setDateErrorTwo(error)}
                slotProps={{
                  textField: {
                    InputLabelProps: {
                      shrink: true,
                    },
                  },
                }}
              />
            </LocalizationProvider>

            {/* Clear Custom Dates */}
            {dateRange?.name === 'Custom' && (
              <Box sx={componentStyles.clearDateButtonContainer}>
                <IconButton
                  onClick={clearDateRangeFilter}
                  sx={componentStyles.clearDateButton}
                >
                  <CloseIcon sx={componentStyles.closeIcon} />
                  <Typography sx={componentStyles.clearDateText}>
                    CLEAR FILTER
                  </Typography>
                </IconButton>
              </Box>
            )}
          </Box>

          <Button
            variant="contained"
            sx={componentStyles.createNoteButton}
            disabled={props?.profileDisabled}
            onClick={() => setNoteDrawerOpen(true)}
          >
            <AddIcon sx={componentStyles.addIcon} />
            Create A Note
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
