import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//mui components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  formatDateObjMMDDYYYYDisplay,
  formatCurrency,
  formatPhoneNumber,
} from '../../../utils';
import { IconButton } from '@mui/material';
import { PhoneAndroidRounded } from '@mui/icons-material';

export default function PortalBenefitAdminSummary(props) {
  let history = useHistory();

  const handleCallClick = () => {
    let phoneNumber = props.commLabels[0]?.enrollPhone;
    // window.open(`tel:${phoneNumber}`);
  };
  return (
    <Box
      className="flex-row"
      sx={{ justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Box sx={{}} className="col-10">
        <Typography
          component={'div'}
          className="col-12"
          variant="body1"
          sx={{}}
        >
          {props.commLabels[0]?.name || 'Union One'}
        </Typography>
        <Typography
          component={'div'}
          className="col-12"
          variant="caption"
          sx={{
            fontFamily: 'Archivo',
            color: 'text.secondary',
          }}
        >
          {formatPhoneNumber(props.commLabels[0]?.enrollPhone) ||
            '(847) 387-3555'}
        </Typography>

        <Typography
          component={'div'}
          className="col-12"
          variant="body1"
          sx={{}}
        >
          Customer Service Hours:
        </Typography>
        <Typography
          component={'div'}
          className="col-12"
          variant="caption"
          sx={{
            fontFamily: 'Archivo',
            color: 'text.primary',
          }}
        >
          [{props.commLabels[0]?.hours || 'Mon-Fri 8am-5pm CT'}]
        </Typography>
      </Box>

      <Box sx={{}} className="col-1">
        <IconButton sx={{ color: 'primary.main' }} onClick={handleCallClick}>
          <PhoneAndroidRounded />
        </IconButton>
      </Box>
    </Box>
  );
}
