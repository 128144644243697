import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format, addYears } from 'date-fns';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//services
import paymentsService from '../../../../../../../services/payments-service';

// Assets

//Mui Components

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Alert,
  Box,
  Card,
  FormControl,
  InputLabel,
  Typography,
  Divider,
  Radio,
  Tooltip,
} from '@mui/material';

//Mui icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//Our Components
import PaymentBankAccounts from '../card-components/PaymentBankAccounts';
import InfinityLoader from '../../../../../../UH-loading-animation/InfinityLoader';
import { display } from '@mui/system';

export default function RefundOrVoidDrawer(props) {
  //Component Styles
  const componentStyles = {
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '13px 38px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 auto',
      borderBottom: '2px solid',
      borderColor: 'primary.main',
      backgroundColor: 'background.default',
    },
    title: {
      color: 'text.primary',
      fontFamily: 'Poppins',
      fontSize: '24px',
      fontWeight: '400',
    },
    componentBody: {
      padding: '40px 38px',
      fontFamily: 'Poppins',
      minWidth: '538px',
      maxWidth: '538px',
      height: '100%',
      backgroundColor: 'background.default',
    },

    transactionInfo: {
      width: '100%',
      marginBottom: '15px',
    },
    bankAccountContainer: {
      margin: '30px auto 10px',
    },
    closeDrawerButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    accountCard: (prenoteStatus) => ({
      padding: '8px 10px 8px 8px',
      width: '100%',
      minHeight: '96px',
      margin: '12px auto 0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: 'Poppins',
      border: '1px solid',
      borderColor: prenoteStatus ? 'rgba(211, 47, 47)' : 'background.border',
      backgroundColor: prenoteStatus
        ? 'rgba(211, 47, 47, 0.04)'
        : 'background.default',
    }),

    iconContainer: {
      display: 'flex',
      flex: '0.5',
      margin: '0 auto',
    },
    accountInfo: {
      flex: '3',
      paddingLeft: '6px',
    },
    defaultOrMenuContainer: {
      fontFamily: 'Archivo',
      fontSize: '15px',
      fontWeight: 500,
      flex: 1.25,
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    errorMessage: {
      padding: '0px 20px',
      width: '60%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: '20px',
    },
    buttonContainer: {
      padding: '34px 54px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    buttonStyles: {
      height: '42px',
      fontSize: '15px',
    },
    transFeeMessage: { fontSize: '11px' },
  };

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  //close refund drawer
  const handleCloseDrawer = () => {
    props.setRefundOrVoidDrawerOpen(false);
    //reset account selected for refund state
    props.setRefundOrVoidAccountSelected({
      key: '',
      paymentId: 'No transaction selected',
      type: '',
      amount: '',
      status: '',
      processor: '',
      processedDate: '',
      schedule: '',
      account: '',
    });
    //reset action or either refund or void to null when drawer is closed
    props.setAction(null);
    //reset Errors if any occured
    setError(false);
  };

  // Handle Refund
  const handleVoidOrRefundClick = async () => {
    setLoading(true);
    let transactionId = props.refundOrVoidAccountSelected?.paymentId;
    let amountToRefund = props.refundOrVoidAccountSelected?.amount;

    // //REFUND - need transaction ID and amount
    if (props.action === 'refund') {
      try {
        await paymentsService.refundPaymentByTransactionId(
          transactionId,
          amountToRefund
        );
        handleCloseDrawer();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(
          `error refunding transaction with ID: ${transactionId}, `,
          error
        );
        setError('Error refunding payment.');
      }

      setTimeout(() => {
        //refresh one-time transactions list
        props?.getOneTimePayments();
      }, 500);
    }

    // //VOID - need transaction ID
    if (props.action === 'void') {
      try {
        await paymentsService.voidPaymentByTransactionId(transactionId);
        handleCloseDrawer();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(
          `error voiding transaction with ID: ${transactionId}, `,
          error
        );
        setError('Error voiding payment.');
      }
    }

    //refresh current transactions listed
    props.getAllPayments();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={props.refundOrVoidDrawerOpen}
        onClose={() => props.setRefundOrVoidDrawerOpen(false)}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                position: 'absolute',
                zIndex: 5,
                width: '100%',
                height: '100vh',
                // backgroundColor: "#00000050",
                backdropFilter: 'blur(10px)',
              }}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:50%;
                  }
               `}
            </style>
          </>
        )}
        <Box sx={componentStyles.headerContainer}>
          <Typography variant="h5" sx={componentStyles.title}>
            {props.action === 'void' ? 'Void Payment' : 'Issue Refund'}
          </Typography>

          <Box
            sx={componentStyles.closeDrawerButton}
            role="button"
            onClick={handleCloseDrawer}
          >
            <CloseIcon sx={{ height: '34px' }} />
          </Box>
        </Box>

        <Box sx={componentStyles.componentBody}>
          {/* Transaction ID and Amount  - plan to fill this in dynamically w/ account info*/}
          <Box>
            <Typography sx={componentStyles.transactionInfo}>
              <strong>Transaction ID: </strong>{' '}
              {props.refundOrVoidAccountSelected?.paymentId ||
                'no transaction selected'}
            </Typography>
            <Typography
              sx={{ ...componentStyles.transactionInfo, marginBottom: '0px' }}
            >
              <strong>Transaction Amount: </strong>
              {/* hard coding -$1 as we automatically keep the $1 trans fee, this is handled in the backend */}
              {props?.refundOrVoidAccountSelected?.amount &&
              props?.refundOrVoidAccountSelected?.amount > 0
                ? props.UsdFormatterDec.format(
                    props.refundOrVoidAccountSelected?.amount - 1
                  )
                : ''}
            </Typography>
            <Typography sx={componentStyles.transFeeMessage}>
              * EXCLUDES $1 TRANS FEE
            </Typography>
          </Box>

          {/* Bank Account*/}
          <Box sx={componentStyles.bankAccountContainer}>
            <Card
              elevation={0}
              variant="outlined"
              sx={componentStyles.accountCard(
                props?.refundOrVoidAccountSelected?.account?.prenoteStatusId ===
                  4
              )}
            >
              <Box style={componentStyles.iconContainer}>
                <AccountBalanceIcon
                  sx={{
                    color: 'primary.main',
                    margin: '6px auto',
                  }}
                />
              </Box>
              {/* Member Name and Account Type - need to get account type sorted out from getAllPayments req. */}
              <Box style={componentStyles.accountInfo}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: 'Poppins', fontWeight: '500' }}
                >
                  {props.member?.firstName || ' '} {props.member?.lastName}
                  {props.member?.lastName?.slice(-1) === 's' ? "'" : "'s"}{' '}
                  {/* {props.getMemberAccountType(account.bankAccountTypeId)}{" "} */}
                  Checking Account
                </Typography>

                {/* Bank Information*/}
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {/* {account.bankName || "Account"} *
                    {account.accountNumber?.slice(-4)} */}
                  Bank Account *
                  {props.refundOrVoidAccountSelected?.account?.accountNumber?.slice(
                    -4
                  ) || ''}
                </Typography>
              </Box>
            </Card>
          </Box>

          {/* Buttons Container*/}
          <Box
            sx={{
              ...componentStyles.buttonContainer,
              display: 'flex',
              backgroundColor: 'background.default',
              paddingBottom: '20px',
            }}
          >
            <Button
              sx={{ ...componentStyles.buttonStyles, marginRight: '34px' }}
              onClick={handleCloseDrawer}
            >
              CANCEL
            </Button>
            {/* Submit Refund or Void payment*/}
            <Button
              sx={componentStyles.buttonStyles}
              variant="contained"
              onClick={handleVoidOrRefundClick}
            >
              {props.action === 'void' ? 'VOID PAYMENT' : 'REFUND'}
            </Button>
          </Box>
          {error && (
            <Alert sx={componentStyles.errorMessage} severity="error">
              <Typography> {error} </Typography>
            </Alert>
          )}
        </Box>
      </Drawer>
    </>
  );
}
