import React, { useState, useEffect } from 'react';

//services
import walletService from '../../../../../../../services/wallet-service';
// Assets

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {
  Alert,
  Box,
  Fade,
  Typography,
  Checkbox,
  Collapse,
  Switch,
} from '@mui/material';

//Mui icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

//Our Components
import PaymentBankAccounts from '../card-components/PaymentBankAccounts';
import ArchivedBankAccounts from '../card-components/ArchivedBankAccounts';
import ButtonInfinityLoader from '../../../../../../UH-loading-animation/ButtonInfinityLoader';
import UndoActionSnackbar from '../../../UndoActionSnackbar';
import { set } from 'date-fns';
import FlexSnackbar from '../../../../../../flex-snackbar/FlexSnackbar';

//Component Styles
const componentStyles = {
  componentContainer: {
    backgroundColor: 'background.default',
    width: '538px',
    height: '100%',
  },
  headerContainer: {
    backgroundColor: 'background.default',
    width: '100%',
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    borderBottom: '1px solid',
    borderColor: 'primary.main',
  },
  collapseContainersRow: { display: 'flex', flexDirection: 'row' },
  accountDisplayBody: {
    backgroundColor: 'background.default',
    fontFamily: 'Poppins',
    minWidth: '538px',
    height: '100%',
    overflowY: 'auto',
    padding: '10px 16px',
  },
  componentTitle: {
    color: 'text.primary',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '400',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '20px',
  },
  sectionHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Archivo',
    margin: '5px 0px 12px',
  },
  sectionTitle: {
    color: 'text.primary',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    marginRight: '10px',
  },
  defaultPaymentMethodUpdatedMessage: {
    color: 'primary.paymentScheduleChip',
    alignItems: 'center',
    lineHeight: '18px',
    fontWeight: 500,
    textAlign: 'center',
    height: '30px',
  },
  closeDrawerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addNewAccountBtn: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      color: 'primary.main',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '20px 20px 10px',
    },
    icon: { height: '20px' },
    text: {
      fontWeight: '500',
      fontFamily: 'Archivo',
      fontSize: '14px!important',
    },
  },
  //ADD and EDIT form styles
  form: {
    title: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Poppins',
      margin: '20px',
    },
    container: { padding: '0px 30px ', minWidth: '538px' },
    inputContainer: {
      marginBottom: '15px',
    },
    input: {
      '& fieldset': { borderRadius: '8px' },
    },
    selectContainer: {
      padding: '0px',
      textAlign: 'left',
      marginBottom: '15px',
    },
    select: {
      '& fieldset': { borderRadius: '8px' },
      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
        border: '2px solid #195ffb',
      },
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0px 0px 0px 0vw',
      position: 'relative',
      marginBottom: '15px',
    },
    checkbox: {
      padding: '0px',
      marginRight: '8px',
    },
    checkboxIcon: {
      height: '19px',
    },
    routingNumCheckAlertContainer: {
      padding: '10px 17px',
      justifyContent: 'center',
    },
    routingNumAlertSuccess: {
      border: '2px solid #4E9A51',
      color: '#4E9A51',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      textAlign: 'center',
    },
    routingNumAlertFail: {
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      textAlign: 'center',
    },
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  saveAndCancelButtons: {
    marginTop: '30px',
    height: '42px',
    fontSize: '15px',
  },
  errorMessage: {
    padding: '0px 20px',
    width: '60%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: '20px',
  },
};
export default function EditWalletDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  //Drawer Logic
  const handleEditWalletDrawerClose = () => {
    props.setEditWalletDrawerOpen(false);
    //clear and close form
    setRoutingBankInfo([]);
    setAddNewAccount(false);
    setEditAccount(false);
    setDisplayedValue({});
    setEditAccount(false);
    setLoading(false);
    setError(false);
    setMatchingAccountError(false);
    setShowArchived(false);

    //if there's an action still sitting in the undo snackbar, run it before closing the drawer
    if (undoSnackbarInfo?.action) {
      undoSnackbarInfo?.actionFunction();
      clearUndoSnackbar();
    }
    //refresh bank accounts
    setTimeout(() => {
      props.getBankAccount();
    }, 200);
  };

  //UNDO logic
  const [undoSnackbarInfo, setUndoSnackbarInfo] = useState({});

  const clearUndoSnackbar = () => {
    setUndoSnackbarInfo({
      ...undoSnackbarInfo,
      message: null,
      action: null,
      confirmAction: null,
      autoHideDuration: null,
      actionFunction: null,
    });
  };

  useEffect(() => {
    clearUndoSnackbar();
  }, []);

  //ADD A NEW payment method/account
  const [disableSave, setDisableSave] = useState(true);
  const [addNewAccount, setAddNewAccount] = useState(false);
  //open blank form to add new account
  const openAddNewAccount = () => {
    setRoutingBankInfo([]);
    setAddNewAccount({});
    setDisplayedValue({});
    setEditAccount(false);
    //auto check the set-default payment method box if no accounts present
    if (props?.bankAccount?.length > 0) {
      setCheckedSetNewDefault(false);
    } else setCheckedSetNewDefault(true);
  };

  const newAccountHandler = (e) => {
    const regex = /^[0-9]*$/;
    let newValue = e.target.value;

    let handledId = e.target.id.replace('Updater', '');
    //UPDATE description
    if (e.target.id === 'descriptionUpdater') {
      setDisplayedValue({
        ...displayedValue,
        [handledId]: newValue,
      });
      setAddNewAccount({
        ...addNewAccount,
        [handledId]: newValue,
      });
    } else {
      // UPDATE routing or account number - use regex parameters for routing number only
      if (e.target.id === 'routingNumberUpdater' && regex.test(newValue)) {
        let routingNumTruncated = newValue.slice(0, 9);
        setDisplayedValue({
          ...displayedValue,
          [handledId]: routingNumTruncated,
        });
        setAddNewAccount({
          ...addNewAccount,
          [handledId]: routingNumTruncated,
        });
      } else if (
        e.target.id === 'accountNumberUpdater' ||
        e.target.id === 'confirmAccountUpdater'
      ) {
        setDisplayedValue({
          ...displayedValue,
          [handledId]: newValue,
        });
        setAddNewAccount({
          ...addNewAccount,
          [handledId]: newValue,
        });
      }
    }
  };

  const saveNewAccount = async (e) => {
    setLoading(true);
    let builtNewBank = {
      description: addNewAccount.description,
      accountNumber: addNewAccount.accountNumber,
      routingNumber: addNewAccount.routingNumber,
      isPrimary: checkedSetNewDefault,
      memberId: props.member.id,
      bankName:
        routingBankInfo[0]?.status === 'success'
          ? routingBankInfo[0].data.name
          : '',
      bankAccountTypeId: addNewAccount.bankAccountTypeId
        ? addNewAccount.bankAccountTypeId
        : 1,
      // prenoteStatusId: 1,
    };

    try {
      await walletService.addBankAccountForMember(builtNewBank);
      setLoading(false);
      setError(false);
      props.setBankAccount([...props.bankAccount, builtNewBank]);
    } catch (error) {
      setLoading(false);
      setError('Error saving new bank account');
      console.error('saveNew bank account error', error);
    }

    //Clear form
    setRoutingBankInfo([]);
    setAddNewAccount(false);
    setDisplayedValue({});

    setEditAccount(false);
    setCheckedSetNewDefault(false);

    //update bank account list
    props.getBankAccount();
  };

  //function to edit default checkbox
  const [checkedSetNewDefault, setCheckedSetNewDefault] = useState(false);
  const [showDefaultSuccessMessage, setShowDefaultSuccessMessage] =
    useState(false);

  //update state and value of checkbox on add new account
  const handleCheckboxSetAsDefault = (e) => {
    setCheckedSetNewDefault(() => !checkedSetNewDefault);
  };

  //UPDATE exisiting account to the default account - backend will change all other accounts to isPrimary:false
  const changeDefaultAccount = async (e, account) => {
    //update selected account to new default account (isPrimary: true)
    let updatedDefaultAccount = {
      ...account,
      isPrimary: true,
    };
    try {
      await walletService.updateBankAccountForMember(updatedDefaultAccount);
      //display success message
      setShowDefaultSuccessMessage(true);
    } catch (error) {
      console.error('update bank account error', error);
    }
    //update bankaccount list from db
    props.getBankAccount();
    //close menu
    handleCloseWalletMenu();

    //hide success message
    setTimeout(() => {
      setShowDefaultSuccessMessage(false);
    }, 3000);
  };

  //Edit failed prenote account
  const [editAccount, setEditAccount] = useState(false);
  const [failedPrenoteAccount, setFailedPrenoteAccount] = useState(false);
  const [displayPrenoteSuccessMessage, setDisplayPrenoteSuccessMessage] =
    useState(false);

  //set display prenote success message to false every time EditWalletDrawer is opened
  useEffect(() => {
    setDisplayPrenoteSuccessMessage(false);
  }, [props?.editWalletDrawerOpen]);

  const openEditFailedPrenote = (e, account) => {
    //can only edit on click if prenoteStatusId = 4

    if (account.prenoteStatusId === 4) {
      setAddNewAccount(false);
      //reference to original account before edits
      setFailedPrenoteAccount(account);

      //this account info will be edited
      setEditAccount(account);
      setRoutingBankInfo([]);
      setDisplayedValue({
        routingNumber: account?.routingNumber,
        accountNumber: account?.accountNumber,
      });
      //uncheck the set-default payment method box IF another default account exists that is not the failed prenote account
      if (props?.bankAccount?.length > 1 && !account?.isPrimary) {
        setCheckedSetNewDefault(false);
      } else setCheckedSetNewDefault(true);
    }
    //close menu
    handleCloseWalletMenu();
  };

  const editAccountHandler = (e) => {
    const regex = /^[0-9]*$/;
    let newValue = e.target.value;
    let handledId = e.target.id.replace('Updater', '');
    //UPDATE description
    if (e.target.id === 'descriptionUpdater') {
      setEditAccount({
        ...editAccount,
        [handledId]: newValue,
      });
      setDisplayedValue({
        ...displayedValue,
        [handledId]: newValue,
      });
    } else {
      // UPDATE routing or account number (use regex parameters for routing number only)
      if (e.target.id === 'routingNumberUpdater' && regex.test(newValue)) {
        let routingNumTruncated = newValue.slice(0, 9);
        setDisplayedValue({
          ...displayedValue,
          [handledId]: routingNumTruncated,
        });
        setEditAccount({
          ...editAccount,
          [handledId]: routingNumTruncated,
        });
      } else if (
        e.target.id === 'accountNumberUpdater' ||
        e.target.id === 'confirmAccountUpdater'
      ) {
        setDisplayedValue({
          ...displayedValue,
          [handledId]: newValue,
        });
        setEditAccount({
          ...editAccount,
          [handledId]: newValue,
        });
      }
    }
  };

  const saveEditAccount = async (e) => {
    setLoading(true);
    let updatedFailedPrenoteAccount = {
      id: editAccount.id,
      description: editAccount.description,
      accountNumber: editAccount.accountNumber,
      routingNumber: editAccount.routingNumber,
      isPrimary: checkedSetNewDefault,
      memberId: props.member.id,
      bankName:
        routingBankInfo[0]?.status === 'success'
          ? routingBankInfo[0].data.name
          : '',
      bankAccountTypeId: editAccount.bankAccountTypeId
        ? editAccount.bankAccountTypeId
        : 1,
      prenoteStatusId: 1,
    };

    try {
      await walletService.updateBankAccountForMember(
        updatedFailedPrenoteAccount
      );
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError('Error updating bank account');
      console.error('update bank error', error);
    }

    //Clear form
    setRoutingBankInfo([]);
    setAddNewAccount(false);
    setEditAccount(false);
    setDisplayedValue({});

    setCheckedSetNewDefault(false);

    //update bank account list
    props.getBankAccount();
  };

  let accountTypes = [
    { id: 1, name: 'Checking' },
    { id: 2, name: 'Savings' },
  ];

  //set either new or edited bank account type id
  const bankAccountHandler = (e, addOrEdit) => {
    console.error(('bank account Type Id', e.target.value));
    let value = e.target.value;
    if (addOrEdit == 'Add') {
      setAddNewAccount({
        ...addNewAccount,
        bankAccountTypeId: value,
      });
    } else {
      setEditAccount({
        ...editAccount,
        bankAccountTypeId: value,
      });
    }
  };

  // Check Routing Number + get bank info from Routing Number
  const [routingBankInfo, setRoutingBankInfo] = useState([]);
  const checkRoutingNumber = async (e) => {
    let _bankInfo;
    if (addNewAccount) {
      _bankInfo = await walletService.getBankFromRouting(
        addNewAccount.routingNumber
      );
    } else if (editAccount) {
      _bankInfo = await walletService.getBankFromRouting(
        editAccount.routingNumber
      );
    }

    setRoutingBankInfo(_bankInfo);

    console.error('banking info', _bankInfo);
  };

  //Check account added and CONFIRM account match
  const [matchingAccountError, setMatchingAccountError] = useState(false);
  const checkMatchingAccount = () => {
    if (addNewAccount) {
      if (
        addNewAccount?.accountNumber?.length > 0 &&
        addNewAccount.accountNumber !== addNewAccount.confirmAccount
      )
        setMatchingAccountError(true);
      else setMatchingAccountError(false);
    } else if (editAccount) {
      if (
        editAccount?.accountNumber?.length > 0 &&
        editAccount.accountNumber !== editAccount.confirmAccount
      )
        setMatchingAccountError(true);
      else setMatchingAccountError(false);
    }
  };

  //disable save button if all fields are not filled AND account numbers do not match
  //Adding new account
  useEffect(() => {
    if (addNewAccount) {
      let nameProvided = addNewAccount?.description?.length > 0;
      let routingProvided = addNewAccount?.routingNumber?.length > 0;
      let accountProvided = addNewAccount?.accountNumber?.length > 0;
      let confirmProvided = addNewAccount?.confirmAccount?.length > 0;
      if (
        nameProvided &&
        routingProvided &&
        accountProvided &&
        confirmProvided &&
        !matchingAccountError
      ) {
        setDisableSave(false);
      } else setDisableSave(true);
    }
  }, [addNewAccount, matchingAccountError]);

  //Editing existing account
  useEffect(() => {
    if (editAccount) {
      let _editAccount = { ...editAccount };
      delete _editAccount.confirmAccount;
      let nameProvided = editAccount?.description?.length > 0;
      let routingProvided = editAccount?.routingNumber?.length > 0;
      let accountProvided = editAccount?.accountNumber?.length > 0;
      let confirmProvided = editAccount?.confirmAccount?.length > 0;
      if (
        nameProvided &&
        routingProvided &&
        accountProvided &&
        confirmProvided &&
        !matchingAccountError &&
        (editAccount.routingNumber !== failedPrenoteAccount.routingNumber ||
          editAccount.accountNumber !== failedPrenoteAccount.accountNumber ||
          editAccount.bankAccountTypeId !==
            failedPrenoteAccount.bankAccountTypeId)
        // JSON.stringify(_editAccount) != JSON.stringify(props.failedPrenote)
      ) {
        setDisableSave(false);
      } else setDisableSave(true);
    }
  }, [editAccount, matchingAccountError]);

  //RESET prenote status to 3
  let handleUnblockBank = async (e, id) => {
    try {
      let updatedAccount = await walletService.unblockBankAccount(id);

      if (updatedAccount) {
        //display success message temporarily w/ setTimeout, it will hide after 5 seconds
        setDisplayPrenoteSuccessMessage(id);

        //close the edit account form
        setEditAccount(false);
      }
    } catch (error) {
      console.error('ERROR unblocking account \n\n', error);
    }
    handleCloseWalletMenu();
    props.getBankAccount();
  };

  //ARCHVE BANK ACCOUNT - can only archive a bank account that is not the default
  const handleDeleteBank = (e, id) => {
    //check if any account and action are already in snackbar, if so, run current undo action BEFORE setting new action
    //update local state of bank accounts FOR DISPLAY ONLY to move account to archive/hide from Active bank list immediately
    let updatedBankAccounts = props.bankAccount.map((account) => {
      if (account.id === id) {
        return { ...account, isDeleted: true };
      }
      return account;
    });
    props?.setBankAccount(updatedBankAccounts);

    //display undo snackbar and give option to undo
    setUndoSnackbarInfo({
      ...undoSnackbarInfo,
      action: true,
      message: 'Payment method moved to archive',
      confirmAction: true,
      autoHideDuration: 5000,
      actionFunction: () => confirmDeleteBank(e, id),
    });

    //close menu
    handleCloseWalletMenu();
  };

  //run after timer runs out for undo snackbar
  const confirmDeleteBank = async (e, id) => {
    //delete from database
    let accountToDelete = props.bankAccount.find((account) => {
      return account.id === id;
    });
    try {
      await walletService.deleteBankAccount(id);
    } catch (error) {
      console.error('ERROR deleting account \n\n', error, accountToDelete);
    }

    // //refresh bank account list and close drawer
    // props.getBankAccount()
  };

  //EDIT wallet menu (on all active accounts except default)
  const [editWalletMenu, setEditWalletMenu] = useState(null);
  const handleCloseWalletMenu = () => {
    setEditWalletMenu(null);
  };

  //////////////////// Archived bank accounts \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  const [archivedBankAccounts, setArchivedBankAccounts] = useState([]);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    //filter archived accounts
    let _archivedAccounts = props?.bankAccount?.filter((account) => {
      return account.isDeleted;
    });
    setArchivedBankAccounts(_archivedAccounts);
    //check if show archive button should be disabled depending on if there are any archived accounts
    if (_archivedAccounts.length < 1) setShowArchived(false);
  }, [props?.bankAccount, props?.editWalletDrawerOpen]);

  //Edit archived account menu
  const [editArchivedAccountMenu, setEditArchivedAccountMenu] = useState(null);
  const handleCloseArchivedAccountMenu = () => {
    setEditArchivedAccountMenu(null);
  };

  //Unarchive Account - set isDeleted to 0
  const handleUnarchiveAccount = (e, account) => {
    //check if any account and action are already in snackbar - potentially need to run current undo action BEFORE setting new action // temp fix is disable archive/unarchive btn IF undo snackbar is open
    //update local state of bank accounts FOR DISPLAY ONLY to move account to archive/hide from Active bank list immediately
    let updatedBankAccounts = props.bankAccount.map((acc) => {
      if (acc.id === account.id) {
        return { ...acc, isDeleted: false };
      }
      return acc;
    });
    props?.setBankAccount(updatedBankAccounts); //updating bankAccount state will triger useEffects to refilter archived vs. unarchived accounts
    //display undo snackbar and give option to undo
    setUndoSnackbarInfo({
      ...undoSnackbarInfo,
      action: true,
      message: 'Payment method unarchived',
      confirmAction: true,
      autoHideDuration: 5000,
      actionFunction: (e) => confirmUnarchive(e, account),
    });

    //close menu
    handleCloseArchivedAccountMenu();
  };

  const confirmUnarchive = async (e, account) => {
    let _updatedAccount = {
      ...account,
      isDeleted: false,
    };
    try {
      await walletService.updateBankAccountForMember(_updatedAccount);
    } catch (error) {
      console.error('ERROR unarchiving account', error);
    }
    //refresh bank account list
    // props.getBankAccount()
  };
  ////////////////////////////////////////////////////////////////////////////////////

  //display and mask account and routing numbers
  const [displayedValue, setDisplayedValue] = useState({
    routingNumber: '',
    accountNumber: '',
    confirmAccount: '',
  });

  const handleCancelClick = () => {
    //clear and close form
    setRoutingBankInfo([]);
    setAddNewAccount(false);
    setEditAccount(false);
    setDisplayedValue({});
    setEditAccount(false);
    setError(false);
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={props?.editWalletDrawerOpen}
        onClose={handleEditWalletDrawerClose}
      >
        <FlexSnackbar
          displayAlert={undoSnackbarInfo.confirmAction}
          setDisplayAlert={setUndoSnackbarInfo}
          text={undoSnackbarInfo.message}
          parent="drawer"
          actionFunction={undoSnackbarInfo.actionFunction}
          cleanupFunctions={[clearUndoSnackbar, props.getBankAccount]}
        ></FlexSnackbar>
        <Box sx={componentStyles.componentContainer}>
          <Box sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.componentTitle}>
              Edit Wallet
            </Typography>
            <Box
              className="col-6"
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleEditWalletDrawerClose}
            >
              <CloseIcon sx={{ height: '34px' }} />
            </Box>
          </Box>
          {/* Display Accounts if not Adding or Editing */}
          <Box sx={componentStyles.collapseContainersRow}>
            <Collapse
              in={!addNewAccount && !editAccount}
              orientation="horizontal"
            >
              <Box sx={componentStyles.accountDisplayBody}>
                <Box sx={componentStyles.switchContainer}>
                  <Switch
                    onClick={() => setShowArchived(!showArchived)}
                    checked={showArchived}
                    disabled={archivedBankAccounts?.length < 1}
                  />
                  <Box>Show Archived</Box>
                </Box>

                {/* Active Bank Accounts Section*/}
                <Box sx={componentStyles.sectionHeaderContainer}>
                  <Typography variant="body2" sx={componentStyles.sectionTitle}>
                    Saved Payment Methods
                  </Typography>

                  <Fade
                    in={showDefaultSuccessMessage}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Alert
                      severity="info"
                      sx={componentStyles.defaultPaymentMethodUpdatedMessage}
                      icon={<CheckIcon />}
                    >
                      <Typography sx={{ fontSize: '14px!important' }}>
                        Default payment method updated!
                      </Typography>
                    </Alert>
                  </Fade>
                </Box>

                {/* Accounts */}

                <PaymentBankAccounts
                  bankAccount={props.bankAccount}
                  member={props.member}
                  getMemberAccountType={props.getMemberAccountType}
                  // bankInfoFromRouting={props.bankInfoFromRouting}
                  showDefaultSuccessMessage={showDefaultSuccessMessage}
                  editWalletDrawerOpen={props.editWalletDrawerOpen}
                  editWalletMenu={editWalletMenu}
                  setEditWalletMenu={setEditWalletMenu}
                  handleUnblockBank={handleUnblockBank}
                  handleDeleteBank={handleDeleteBank}
                  defaultAccount={props.defaultAccount}
                  setDefaultAccount={props.setDefaultAccount}
                  changeDefaultAccount={changeDefaultAccount}
                  handleCloseWalletMenu={handleCloseWalletMenu}
                  openEditFailedPrenote={openEditFailedPrenote}
                  editWalletFromPlans={props.editWalletFromPlans}
                  displayPrenoteSuccessMessage={displayPrenoteSuccessMessage}
                  undoSnackbarInfo={undoSnackbarInfo}
                />
                {!addNewAccount && !editAccount && (
                  <Box
                    type="button"
                    sx={componentStyles.addNewAccountBtn.container}
                    onClick={openAddNewAccount}
                  >
                    <AddIcon sx={componentStyles.addNewAccountBtn.icon} />
                    <Typography sx={componentStyles.addNewAccountBtn.text}>
                      ADD NEW PAYMENT METHOD
                    </Typography>
                  </Box>
                )}

                {/* Archived Accounts */}
                <Collapse in={showArchived && archivedBankAccounts?.length > 0}>
                  <Box sx={componentStyles.sectionHeaderContainer}>
                    <Typography
                      variant="body2"
                      sx={componentStyles.sectionTitle}
                    >
                      Archived Payment Methods
                    </Typography>
                  </Box>

                  <ArchivedBankAccounts
                    archivedBankAccounts={archivedBankAccounts}
                    member={props.member}
                    getMemberAccountType={props.getMemberAccountType}
                    editWalletDrawerOpen={props.editWalletDrawerOpen}
                    editArchivedAccountMenu={editArchivedAccountMenu}
                    setEditArchivedAccountMenu={setEditArchivedAccountMenu}
                    handleCloseArchivedAccountMenu={
                      handleCloseArchivedAccountMenu
                    }
                    handleUnarchiveAccount={handleUnarchiveAccount}
                    undoSnackbarInfo={undoSnackbarInfo}
                  />
                </Collapse>
              </Box>
            </Collapse>

            {/* Adding a new account - display form */}
            <Collapse
              in={addNewAccount && !editAccount}
              orientation="horizontal"
            >
              <Typography variant="body2" sx={componentStyles.form.title}>
                Add New Payment Method
              </Typography>

              <Box sx={componentStyles.form.container}>
                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="descriptionUpdater"
                    onChange={newAccountHandler}
                    sx={componentStyles.form.input}
                    required
                    label="Name on Account"
                    value={addNewAccount?.description || ''}
                  />
                </Box>
                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="routingNumberUpdater"
                    onChange={newAccountHandler}
                    required
                    sx={componentStyles.form.input}
                    onBlur={(e) => {
                      checkRoutingNumber();
                    }}
                    label="Routing Number"
                    value={displayedValue?.routingNumber || ''}
                    placeholder="Routing Number"
                  />
                </Box>

                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="accountNumberUpdater"
                    onChange={newAccountHandler}
                    required
                    sx={componentStyles.form.input}
                    label="Account Number"
                    value={displayedValue?.accountNumber || ''}
                    placeholder="Account Number"
                  />
                </Box>

                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="confirmAccountUpdater"
                    onChange={newAccountHandler}
                    required
                    error={matchingAccountError}
                    sx={componentStyles.form.input}
                    onBlur={(e) => {
                      // maskOnBlur(e);
                      checkMatchingAccount();
                    }}
                    // onFocus={unmaskOnFocus}
                    label="Confirm Account Number"
                    value={displayedValue?.confirmAccount || ''}
                    placeholder="Confirm Account Number"
                  />
                </Box>

                <Box sx={componentStyles.form.selectContainer}>
                  <TextField
                    fullWidth
                    required
                    select
                    sx={componentStyles.form.select}
                    variant="outlined"
                    value={addNewAccount?.bankAccountTypeId || 1}
                    onChange={(e) => bankAccountHandler(e, 'Add')}
                    label="Account Type"
                  >
                    {accountTypes.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                {/* Set as default account checkbox */}
                <Box
                  sx={componentStyles.form.checkboxContainer}
                  onClick={handleCheckboxSetAsDefault}
                >
                  <Checkbox
                    onChange={handleCheckboxSetAsDefault}
                    value={checkedSetNewDefault}
                    checked={checkedSetNewDefault}
                    name={'isPrimary'}
                    sx={componentStyles.form.checkbox}
                    icon={
                      <CheckBoxOutlineBlankIcon
                        sx={componentStyles.form.checkboxIcon}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon sx={componentStyles.form.checkboxIcon} />
                    }
                  />
                  Set as default
                </Box>

                {/* Display Bank associated w/ Routing number -success message */}
                <Fade
                  in={routingBankInfo[0]?.status != undefined}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box sx={componentStyles.form.routingNumCheckAlertContainer}>
                    {routingBankInfo[0]?.status == 'success' && (
                      <Alert
                        severity="success"
                        sx={componentStyles.form.routingNumAlertSuccess}
                        icon={<CheckIcon />}
                      >
                        {routingBankInfo[0]?.data?.name}{' '}
                        <span style={{ display: 'inline-block' }}>
                          Succesfully Connected
                        </span>
                      </Alert>
                    )}
                    {routingBankInfo[0]?.status == 'fail' && (
                      <Alert
                        severity="warning"
                        sx={componentStyles.form.routingNumAlertFail}
                      >
                        <span style={{ display: 'inline-block' }}>
                          Incorrect routing number - no bank found
                        </span>
                      </Alert>
                    )}
                  </Box>
                </Fade>

                <Box sx={componentStyles.saveButtonContainer}>
                  <Button
                    sx={{
                      ...componentStyles.saveAndCancelButtons,
                      marginRight: '10px',
                    }}
                    onClick={handleCancelClick}
                  >
                    CANCEL
                  </Button>

                  <Button
                    variant="contained"
                    sx={componentStyles.saveAndCancelButtons}
                    disabled={disableSave || loading}
                    // size="large"
                    onClick={saveNewAccount}
                  >
                    {loading ? (
                      <ButtonInfinityLoader
                        buttonTitle={'SAVE PAYMENT METHOD'}
                      />
                    ) : (
                      'SAVE PAYMENT METHOD'
                    )}
                  </Button>
                </Box>
              </Box>
            </Collapse>

            {/* Edit Failed Prenote Account */}
            <Collapse
              in={editAccount && !addNewAccount}
              orientation="horizontal"
            >
              <Typography variant="body2" sx={componentStyles.form.title}>
                Edit Failed Prenote Account
              </Typography>

              <Box sx={componentStyles.form.container}>
                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="descriptionUpdater"
                    onChange={editAccountHandler}
                    sx={componentStyles.form.input}
                    label="Name on Account"
                    InputLabelProps={{ shrink: 'auto' }}
                    value={editAccount.description}
                  />
                </Box>
                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="routingNumberUpdater"
                    onChange={editAccountHandler}
                    required
                    sx={componentStyles.form.input}
                    onBlur={(e) => {
                      checkRoutingNumber();
                    }}
                    label="Routing Number"
                    InputLabelProps={{ shrink: 'auto' }}
                    value={displayedValue?.routingNumber || ''}
                  />
                </Box>

                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="accountNumberUpdater"
                    onChange={editAccountHandler}
                    required
                    sx={componentStyles.form.input}
                    // onBlur={(e) => {
                    //   maskOnBlur(e);
                    // }}
                    // onFocus={unmaskOnFocus}
                    label="Account Number"
                    InputLabelProps={{ shrink: 'auto' }}
                    value={displayedValue.accountNumber}
                  />
                </Box>

                <Box sx={componentStyles.form.inputContainer}>
                  <TextField
                    fullWidth
                    id="confirmAccountUpdater"
                    onChange={editAccountHandler}
                    required
                    error={matchingAccountError}
                    onBlur={(e) => {
                      // maskOnBlur(e);
                      checkMatchingAccount();
                    }}
                    // onFocus={unmaskOnFocus}
                    label="Confirm Account Number"
                    InputLabelProps={{ shrink: 'auto' }}
                    value={displayedValue.confirmAccount}
                  />
                </Box>

                <Box sx={componentStyles.form.selectContainer}>
                  <TextField
                    fullWidth
                    required
                    select
                    sx={componentStyles.form.select}
                    variant="outlined"
                    value={editAccount?.bankAccountTypeId || 1}
                    onChange={(e) => bankAccountHandler(e, 'Edit')}
                    label="Account Type"
                    InputLabelProps={{ shrink: 'auto' }}
                  >
                    {accountTypes.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                {/* Set as default account checkbox */}
                <Box
                  sx={componentStyles.form.checkboxContainer}
                  onClick={handleCheckboxSetAsDefault}
                >
                  <Checkbox
                    onChange={handleCheckboxSetAsDefault}
                    value={checkedSetNewDefault}
                    checked={checkedSetNewDefault}
                    name={'isPrimary'}
                    sx={componentStyles.form.checkbox}
                    icon={
                      <CheckBoxOutlineBlankIcon
                        sx={componentStyles.form.checkboxIcon}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon sx={componentStyles.form.checkboxIcon} />
                    }
                    // inputProps={{
                    //    'data-amount':lifeADDPlan?.spouseOptions[props.spouseSlider].premiumAmount,
                    //    'data-person':"spouse",
                    //    'data-coverage':lifeADDPlan?.spouseOptions[props.spouseSlider]?.coverageAmount
                    // }}
                  />
                  Set as default
                </Box>

                {/* Display Bank associated w/ Routing number -success message */}
                <Fade
                  in={routingBankInfo[0]?.status === 'success'}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box sx={componentStyles.form.routingNumCheckAlertContainer}>
                    <Alert
                      severity="success"
                      sx={componentStyles.form.routingNumAlertSuccess}
                      icon={<CheckIcon />}
                    >
                      {routingBankInfo[0]?.data?.name}{' '}
                      <span style={{ display: 'inline-block' }}>
                        Succesfully Connected
                      </span>
                    </Alert>
                  </Box>
                </Fade>

                <Box sx={componentStyles.saveButtonContainer}>
                  <Button
                    sx={{
                      ...componentStyles.saveAndCancelButtons,
                      marginRight: '10px',
                    }}
                    onClick={handleCancelClick}
                  >
                    CANCEL
                  </Button>

                  <Button
                    variant="contained"
                    sx={componentStyles.saveAndCancelButtons}
                    disabled={disableSave || loading}
                    // size="large"
                    onClick={saveEditAccount}
                  >
                    {loading ? (
                      <ButtonInfinityLoader
                        buttonTitle={'SAVE PAYMENT METHOD'}
                      />
                    ) : (
                      'SAVE PAYMENT METHOD'
                    )}
                  </Button>
                </Box>
              </Box>
            </Collapse>
          </Box>

          {/* display error if save new or update existing account fails */}
          {error && (
            <Alert sx={componentStyles.errorMessage} severity="error">
              <Typography> {error} </Typography>
            </Alert>
          )}
        </Box>
      </Drawer>
    </>
  );
}
