import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, formatDistance, set, subMinutes } from 'date-fns';

//services
import ticketService from '../../../../../../services/ticket-service';
import optionService from '../../../../../../services/optionService';
import userService from '../../../../../../services/user-service';
import registerService from '../../../../../../services/register-service';
import enrollmentService from '../../../../../../services/enrollment-service';
import attachmentService from '../../../../../../services/attachment-service';

//Mui Components
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';

//Mui icons
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';

//Our Components
import { thisUserContext } from '../../../../nav-container/NavContainer';
import DocumentDrawer from './DocumentDrawer';

export default function MemberDocumentsTab(props) {
  // Error / Success Messages
  const [message, setMessage] = useState({});

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: '',
        severity: '',
        backgroundColor: '',
        message: '',
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message.messageDisplay]);

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: '',
      severity: '',
      backgroundColor: '',
      message: '',
    });
  };

  //Utilities
  const formatDate = (rawDate) => {
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);
    return date.toJSON();
  };

  const [allDocuments, setAllDocuments] = useState([]);
  const getAllDocuments = async (memberId) => {
    await props.getAllDocuments();
  };

  const [attachmentTypes, setAttachmentTypes] = useState([]);
  useEffect(() => {
    if (props.attachmentTypes) setAttachmentTypes(props.attachmentTypes);
  }, [props.attachmentTypes]);

  useEffect(() => {
    if (props.documents) setAllDocuments(props.documents);
  }, [props.documents]);

  const downloadAllDocuments = () => {
    if (allDocuments) {
      for (let file of allDocuments) {
        //document is a protected term for the actual HTML doc
        const link = document.createElement('a');
        link.download = `/api/attachment/${file?.uniqueId}`;
        link.href = `./api/attachment/${file?.uniqueId}`;
        link.click();
        //clear it from the dom
        link.remove();
      }
    }
  };

  //Only holds the unique id right now, not an obj
  const [selectedDocument, setSelectedDocument] = useState(false);
  const handleDocumentSelect = (documentUid) => {
    if (documentUid != selectedDocument) setSelectedDocument(documentUid);
    else setSelectedDocument('');
  };
  const downloadSingleDocument = () => {
    const link = document.createElement('a');
    link.download = `/api/attachment/${selectedDocument}`;
    link.href = `./api/attachment/${selectedDocument}`;
    link.click();
    //clear it from the dom
    link.remove();
  };

  // UPLOADING FILES
  // BASE 64
  let getFileAsBase64 = (file, cb) => {
    let reader = new FileReader();
    //reader is async, so we have to handle promise below
    reader.readAsDataURL(file);

    //callback to use result elsewhere
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (err) {
      console.error(err);
    };
  };

  const anchorEl = useRef(null);

  const [uploadDocument, setUploadDocument] = useState({
    file: null,
    attachmentTypeId: 1,
    fileName: '',
    base64: '',
  });
  const handleUploadFinalize = async () => {
    try {
      await attachmentService.uploadAttachment(
        props.member.id,
        uploadDocument.attachmentTypeId,
        uploadDocument.fileName,
        uploadDocument.base64
      );
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: '1600',
        severity: 'success',
        backgroundColor: '',
        message: 'Successfully uploaded file',
      });
      getAllDocuments(props.member.id);
    } catch (error) {
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: '1600',
        severity: 'error',
        backgroundColor: '',
        message: 'Error uploading file',
      });
    }
    let blankState = {
      file: null,
      attachmentTypeId: 1,
      fileName: '',
      base64: '',
    };
    setUploadDocument(blankState);
  };
  // CLICK UPLOAD
  let handleUploadClick = async (e) => {
    if (e.target.files) {
      for (let file of e.target.files) {
        getFileAsBase64(file, async (result) => {
          //just the string, not the encoding
          let base64 = result.replace(`data:${file.type};base64,`, '');
          setUploadDocument({
            ...uploadDocument,
            file: file,
            fileName: file.name,
            base64: base64,
          });
        });
      }
    }
  };

  // DROP UPLOAD
  let handleFileDrop = async (e) => {
    //default behavior is open the file, prevent it
    e.preventDefault();
    setDragActive(false);

    if (e.dataTransfer.files) {
      for (let file of e.dataTransfer.files) {
        getFileAsBase64(file, async (result) => {
          //just the string, not the encoding
          let base64 = result.replace(`data:${file.type};base64,`, '');
          setUploadDocument({
            ...uploadDocument,
            file: file,
            fileName: file.name,
            base64: base64,
          });
        });
      }
    }
  };

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const [thisUser, setThisUser] = useState();
  let _thisUser = useContext(thisUserContext);
  let getThisUser = async () => {
    setThisUser(_thisUser.thisUser);
  };
  useEffect(() => {
    if (_thisUser) getThisUser();
  }, [_thisUser]);

  const [documentDrawerOpen, setDocumentDrawerOpen] = useState(false);

  const sendDocument = () => {
    setDocumentDrawerOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.background',
          color: 'text.primary',
        }}
        style={{ padding: '20px', height: 'calc(100vh - 104px)' }}
      >
        <Paper
          elevation={0}
          sx={{
            height: '100% ',
            border: '1px solid',
            borderColor: 'background.border',
            backgroundColor: 'background.default',
            padding: '20px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontWeight: 500,
              marginBottom: '32px',
            }}
          >
            Your Documents
          </Typography>

          <Box
            sx={{
              border: '2px dashed',
              borderColor: 'background.border',
              backgroundColor: dragActive
                ? 'background.paper'
                : 'background.box',
              textAlign: 'center',
              borderRadius: '4px',
              minHeight: '150px',
              display: 'grid',
              alignContent: 'center',
              marginBottom: '20px',
              paddingTop: '6px',
            }}
            ref={anchorEl}
            onDragEnter={!props?.profileDisabled && handleDrag}
            onDragLeave={!props?.profileDisabled && handleDrag}
            onDragOver={!props?.profileDisabled && handleDrag}
            onDrop={!props?.profileDisabled && handleFileDrop}
          >
            <Avatar
              sx={{
                color: 'primary.main',
                backgroundColor: 'primary.light',
                borderRadius: '20px',
                margin: 'auto',
              }}
            >
              <UploadFileIcon />
            </Avatar>
            <Typography>
              <Button
                style={{
                  color: 'primary.main',
                  fontWeight: '600',
                  textDecoration: 'none',
                  padding: '0',
                }}
                disabled={props?.profileDisabled}
                component="label"
              >
                Click to Upload
                <input
                  hidden
                  accept="image/svg,image/png,image/jpeg,image/gif,audio/*,image/*,video/*,.pdf,.doc,.xlsx"
                  type="file"
                  onChange={handleUploadClick}
                />
              </Button>{' '}
              or drag and drop.
            </Typography>
            <Typography>PDF, PNG, DOC, XLSX, CSV (max. 1MB)</Typography>
            {uploadDocument?.file && (
              <Menu
                open={uploadDocument?.file}
                anchorEl={anchorEl.current}
                MenuListProps={{ sx: { padding: '20px' } }}
              >
                <Typography>
                  Selected File: {uploadDocument?.file?.name}
                </Typography>
                <br />
                <FormControl
                  fullWidth
                  sx={{
                    margin: 'auto',
                    marginTop: '6px',
                    marginBottom: '6px',
                    height: '45px',
                    '& fieldset': { height: '45px' },
                  }}
                >
                  <InputLabel id="document-type-label">
                    Document Type
                  </InputLabel>
                  <Select
                    sx={{ height: '45px', '& fieldset': { height: '45px' } }}
                    value={uploadDocument?.attachmentTypeId}
                    onChange={(e) =>
                      setUploadDocument({
                        ...uploadDocument,
                        attachmentTypeId: e.target.value,
                      })
                    }
                    label="Document Type"
                    labelId="document-type-label"
                  >
                    {attachmentTypes?.length > 0 &&
                      attachmentTypes.map((type) => {
                        return <MenuItem value={type.id}>{type.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                <br />
                <TextField
                  fullWidth
                  sx={{ margin: 'auto', marginTop: '6px', marginBottom: '6px' }}
                  label="File Name"
                  value={uploadDocument?.fileName}
                  onChange={(e) =>
                    setUploadDocument({
                      ...uploadDocument,
                      fileName: e.target.value,
                    })
                  }
                />
                <br />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button variant="contained" onClick={handleUploadFinalize}>
                    Upload
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setUploadDocument({ ...uploadDocument, file: null })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Menu>
            )}
          </Box>

          <Box
            sx={{
              borderColor: 'background.border',
              borderRadius: '4px',
              justifyContent: 'center',
              textAlign: 'center',
              marginBottom: '24px',
              height: 'calc(100vh - 495px)',
            }}
          >
            <List
              sx={{
                maxHeight: '100%',
                overflow: 'auto',
                padding: '16px',
                border: '1px solid',
                borderColor: 'background.border',
                borderRadius: '4px',
              }}
            >
              {allDocuments.length > 0 ? (
                allDocuments.map((file) => {
                  let fileSelected = selectedDocument == file.uniqueId;

                  return (
                    <ListItem
                      sx={{
                        border: '1px solid',
                        borderColor: fileSelected
                          ? 'primary.main'
                          : 'background.border',
                        borderRadius: '4px',
                        marginBottom: '24px',
                        backgroundColor: fileSelected ? '#195ffb14' : '',
                      }}
                      key={file.id}
                      value={file.uniqueId}
                      onClick={() => handleDocumentSelect(file.uniqueId)}
                      secondaryAction={
                        <Radio
                          edge="end"
                          value={file.uniqueId}
                          onClick={() => handleDocumentSelect(file.uniqueId)}
                          checked={fileSelected}
                          sx={{ right: '12px' }}
                          // inputProps={{ 'aria-labelledby': labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <IconButton
                            sx={{
                              backgroundColor: fileSelected
                                ? 'primary.light'
                                : '#195FFB0A',
                            }}
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: 100,
                              marginBottom: '8px',
                            }}
                          >
                            <DownloadIcon
                              sx={{
                                width: '20px',
                                height: '20px',
                                color: 'primary.main',
                              }}
                            />
                          </IconButton>
                        </ListItemAvatar>
                        <ListItemText
                          primary={file?.filename || 'No file name'}
                          primaryTypographyProps={{
                            sx: { color: fileSelected ? 'primary.main' : '' },
                          }}
                          secondary={
                            file?.uploadDate
                              ? format(
                                  new Date(formatDate(file.uploadDate)),
                                  'EEEE M/d/yyyy h:mma'
                                )
                              : 'No date info'
                          }
                        />
                        {/* <ListItemText primary={file.uniqueId} /> */}
                      </ListItemButton>
                    </ListItem>
                  );
                })
              ) : (
                <Typography>No Documents</Typography>
              )}
            </List>
          </Box>
          <div className="flex-row" style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={downloadAllDocuments}
              disabled={allDocuments?.length < 1}
              size="medium"
              sx={{ fontSize: '14px' }}
            >
              DOWNLOAD ALL
            </Button>
            <div>
              <Button
                variant="text"
                onClick={sendDocument}
                disabled={!selectedDocument || props?.profileDisabled}
                size="medium"
                sx={{ fontSize: '14px', marginRight: '10px' }}
              >
                Send via Email
              </Button>

              <Button
                variant="contained"
                onClick={downloadSingleDocument}
                disabled={!selectedDocument}
                size="medium"
                sx={{ fontSize: '14px' }}
              >
                DOWNLOAD
              </Button>
            </div>
          </div>
        </Paper>
        {/* NEW TICKET DRAWER */}
        <DocumentDrawer
          documentDrawerOpen={documentDrawerOpen}
          setDocumentDrawerOpen={setDocumentDrawerOpen}
          member={props.member}
          thisUser={thisUser}
          selectedDocument={selectedDocument}
          allDocuments={allDocuments}
          setMessage={setMessage}
          message={message}
        />
      </Box>
      <Snackbar
        open={message.messageDisplay}
        autoHideDuration={message.autoHideDuration}
        onClose={handleMessageClose}
        sx={{ opacity: 0.8 }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          // onClose={handleClose}
          severity={message.severity}
          sx={{ width: '100%', backgroundColor: message.backgroundColor }}
        >
          {message.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
