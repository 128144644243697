import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
  Tooltip,
} from 'recharts';

//Mui components
import { Card, CardHeader, Slide, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

//Mui icons
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

//Services
import dashboardService from '../../../../../services/dashboard-service';
//Our Components
import InfinityLoader from '../../../../UH-loading-animation/InfinityLoader';
import ChartLegend from '../ChartLegend';

//Our Styles
import reportingDashboardStyles from '../../reportingDashboardStyles';

export default function PostEnrollmentPayments(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const [loading, setLoading] = useState(false);

  //for pie chart
  const COLORS = [
    '#195FFB',
    '#E94235',
    '#9747FF',
    '#00C814',
    '#5421C9',
    '#FBBC04',
    '#FE7C04',
    '#B90270',
  ];

  let practiceMetric = [
    { metric: 1000.0, type: 'Accepted' },
    { metric: 100.0, type: 'Failed Payments' },
    { metric: 0.0, type: 'Failed Prenotes' },
  ];

  let practicePaymentText = [
    { metric: 2000.0, type: 'Premium Paid' },
    { metric: 100.0, type: 'Avg Cost Paid' },
  ];

  //Scroll between 2 pages
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 2;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [resultsPieChart, setResultsPieChart] = useState({});
  const [resultsTextDisplay, setResultsTextDisplay] = useState({});

  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getPostEnrollPayments(
        props?.groupPlanId
      );

      //map through results and create a new array of objects to display
      if (_results?.length > 0) {
        let _formattedResultsPieChart = _results?.filter((result) => {
          return (
            result?.type !== 'Avg Cost Paid' && result?.type !== 'Premium Paid'
          );
        });

        //   update the value of types
        for (let i = 0; i < _formattedResultsPieChart.length; i++) {
          if (_formattedResultsPieChart[i].type === 'Accepted') {
            _formattedResultsPieChart[i].type = 'Created';
          }
          if (_formattedResultsPieChart[i].type === 'Originated') {
            _formattedResultsPieChart[i].type = 'Processing';
          }
        }

        let _formattedResultsTextDisplay = _results?.filter((result) => {
          return (
            result?.type === 'Avg Cost Paid' || result?.type === 'Premium Paid'
          );
        });

        setResultsPieChart(_formattedResultsPieChart);
        setResultsTextDisplay(_formattedResultsTextDisplay);
      }
    } catch (error) {
      console.error('error getting PostEnrollmentPayments', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.groupPlanId) {
      getReportResults();
    }
  }, [props.groupPlanId]);

  //Pie chart hover label
  const [hover, setHover] = useState(null);

  let totalCount =
    resultsPieChart?.length > 0
      ? resultsPieChart?.reduce((a, b) => a + b.metric, 0)
      : 0;

  // CSV Download
  useEffect(() => {
    if (resultsPieChart?.length > 0) {
      if (props.setAllowDownload) props.setAllowDownload(true);
    } else {
      if (props.setAllowDownload) props.setAllowDownload(false);
    }
  }, [resultsPieChart]);

  useEffect(() => {
    async function downloadFunction() {
      let result = await dashboardService.getCSVPostEnrollPayments(
        props.groupPlanId
      );
      return result;
    }
    if (props.setDownloadFunction)
      props.setDownloadFunction(() => downloadFunction);
  }, [props.setDownloadFunction, props.groupPlanId]);

  useEffect(() => {
    if (props.setReportName) props.setReportName('Payment_Activity');
  }, [props.setReportName]);

  // debug w/ fake data
  // let totalCount =
  //   practiceMetric?.length > 0
  //     ? practiceMetric?.reduce((a, b) => a + b.metric, 0)
  //     : 0;

  return (
    <>
      <Box sx={reportingDashboardStyles.smallReportContainer}>
        {loading && (
          <>
            <InfinityLoader
              style={reportingDashboardStyles.individualReportInfinityLoader}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
            </style>
          </>
        )}

        {/* Payment Pie Chart */}
        <div style={{ height: '100%', display: activeStep == 0 ? '' : 'none' }}>
          <Slide direction="right" in={activeStep == 0}>
            {totalCount > 0 ? (
              <div
                style={{
                  height: '100%',
                  padding: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div>
                  <PieChart
                    width={220}
                    height={200}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <Pie
                      data={resultsPieChart}
                      // data={practiceMetric}
                      cx={110}
                      cy={100}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={1}
                      dataKey="metric"
                      labelLine={false}
                      // display metric count as percentage over corresponding pie slice
                      label={
                        hover
                          ? ({ cx, cy, midAngle, value, index }) => {
                              const RADIAN = Math.PI / 180;
                              const radius = 80;
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <>
                                  <rect
                                    x={x - 20} // Adjust the x position to center the background
                                    y={y - 10} // Adjust the y position to center the background
                                    width={40} // Width of the background rectangle
                                    height={24} // Height of the background rectangle
                                    fill={
                                      resultsPieChart[index].metric > 0
                                        ? 'rgba(97,97, 97, 0.8)'
                                        : 'transparent'
                                    } // Background color
                                    rx={4} // Border radius for rounded corners
                                  />
                                  <text
                                    x={x}
                                    y={y}
                                    textAnchor={'middle'}
                                    dominantBaseline="central"
                                    fill={hover ? 'white' : ''}
                                    fontSize={12}
                                    fontWeight="500"
                                  >
                                    {/* {practiceMetric[index].metric > 0 &&
                                        Math.round(
                                          (practiceMetric[index].metric /
                                            totalCount) *
                                            100
                                        ) + "%"} */}

                                    {resultsPieChart[index].metric > 0 &&
                                      Math.round(
                                        (resultsPieChart[index].metric /
                                          totalCount) *
                                          100
                                      ) + '%'}
                                  </text>
                                </>
                              );
                            }
                          : false
                      }
                    >
                      {/* {practiceMetric?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))} */}

                      {resultsPieChart?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}

                      <Tooltip />
                    </Pie>
                  </PieChart>
                </div>
                <div
                  style={{
                    minWidth: '100px',
                    minHeight: '200px',
                    position: 'relative',
                    backgroundColor: 'transparent',
                  }}
                >
                  <ChartLegend
                    results={resultsPieChart}
                    colors={COLORS}
                    formatNumWithCommas={props?.formatNumWithCommas}
                  />
                </div>
              </div>
            ) : (
              <Typography
                variant="body2"
                sx={{ textAlign: 'center', padding: '20px 0px' }}
              >
                No enrollment data found for this plan.
              </Typography>
            )}
          </Slide>
        </div>

        {/* Payment Text Info */}

        <div style={{ height: '100%', display: activeStep == 1 ? '' : 'none' }}>
          <Slide direction="left" in={activeStep == 1}>
            <div style={{ position: 'relative' }}>
              <div
                className="flex-row"
                style={{
                  height: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div className="col-12">
                  <Typography
                    variant="h4"
                    sx={{
                      color: '#5421C9',
                      marginTop: '23px',
                      marginBottom: '5px',
                    }}
                  >
                    {resultsTextDisplay[0]?.type &&
                      UsdFormatter.format(resultsTextDisplay[0].metric)}
                  </Typography>
                  <Typography
                    variant="overline"
                    style={{ letterSpacing: '1px', fontSize: '12px' }}
                  >
                    TOTAL PAID
                  </Typography>
                </div>

                <div className="col-12">
                  <Divider
                    className="col-12 flex-row"
                    sx={{
                      marginTop: '7px',
                      marginBottom: '7px',
                      width: '50%',
                      marginLeft: '25%',
                      color: 'background.border',
                    }}
                  />
                </div>

                <div className="col-12">
                  <Typography
                    variant="h4"
                    sx={{ color: '#5421C9', marginBottom: '5px' }}
                  >
                    {resultsTextDisplay[1]?.type &&
                      UsdFormatter.format(resultsTextDisplay[1].metric)}
                  </Typography>
                  <Typography
                    variant="overline"
                    style={{ letterSpacing: '1px', fontSize: '12px' }}
                  >
                    AVERAGE COST PAID
                  </Typography>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </Box>

      <div style={{ position: 'absolute', bottom: 5, left: 15, right: 15 }}>
        <MobileStepper
          steps={2}
          position="static"
          activeStep={activeStep}
          sx={{ padding: '0px', backgroundColor: 'transparent' }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{ justifyContent: 'flex-end' }}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ justifyContent: 'flex-start' }}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </div>
    </>
  );
}
