import React, { useState } from 'react';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//Mui Icons

//Our components
import AccidentSpouseInputs from './AccidentSpouseInputs';
import AccidentDependentsInputs from './AccidentDependentsInputs';
import ExpandAndCollapseIcon from '../../../enrollment-custom-components/ExpandAndCollapseIcon';

//Our enrollment shared styles
import enrollmentPlanStyles from '../../enrollmentPlanStyles';

//Component Styles
const componentStyles = {
  separateInputContainer: (expandedSection) => ({
    display: 'flex',
    flexDirection: 'row',
    minHeight: '57px',
    color: expandedSection ? 'primary.main' : 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '30px',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px',
    fontFamily: 'Poppins',
  }),
  inputSectionTitle: {
    cursor: 'pointer',
    fontSize: '15px',
    fontFamily: 'Poppins',
    marginLeft: '10px',
  },
};

export default function AccidentFamilyInputs(props) {
  const [expanded, setExpanded] = useState({
    spouse: true,
    dependents: true,
  });

  const handleExpandClick = (e) => {
    let key = e.target.getAttribute('data-value');
    setExpanded({
      ...expanded,
      [key]: !expanded[key],
    });
  };

  return (
    <>
      <Box sx={componentStyles.separateInputContainer(expanded.spouse)}>
        <Typography
          data-value="spouse"
          sx={componentStyles.inputSectionTitle}
          onClick={handleExpandClick}
        >
          Spouse
        </Typography>

        <Box
          onClick={handleExpandClick}
          data-value="spouse"
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          <ExpandAndCollapseIcon
            isExpanded={expanded.spouse}
            smallVersion={true}
          />
        </Box>
      </Box>
      <Collapse in={expanded.spouse}>
        <AccidentSpouseInputs {...props} />
      </Collapse>

      <Box sx={componentStyles.separateInputContainer(expanded.dependents)}>
        <Typography
          data-value="dependents"
          sx={componentStyles.inputSectionTitle}
          onClick={handleExpandClick}
        >
          Dependents
        </Typography>

        <Box
          onClick={handleExpandClick}
          data-value="dependents"
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          <ExpandAndCollapseIcon
            isExpanded={expanded.dependents}
            smallVersion={true}
          />
        </Box>
      </Box>
      <Collapse in={expanded.dependents}>
        <AccidentDependentsInputs {...props} />
      </Collapse>
    </>
  );
}
