import React, { useState, useEffect } from 'react';
import { set, format } from 'date-fns';

//Mui Components
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//Our components
import InfinityLoader from '../../../../../../../UH-loading-animation/InfinityLoader';
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';
import AccAddCanOrHospitalCoverage from './viewAndEditCoverageBody/AccAddCanOrHospitalActiveCoverage';
import IncrementalStdAndLtdActiveCoverage from './viewAndEditCoverageBody/IncrementalStdAndLtdActiveCoverage';
import NonIncrementalStdAndLtdActiveCoverage from './viewAndEditCoverageBody/NonIncrementalStdAndLtdActiveCoverage';

//Serviees
import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

//Our styles
import activeCoverageStyles from './activeCoverageStyles';

export default function ActiveLineOfCoverage(props) {
  const componentStyles = {
    menuOptions: {
      color: 'text.primary',
      fontFamily: 'Archivo',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      height: '48px',
      '&:hover': { backgroundColor: 'primary.light' },
    },
  };

  //loading state
  const [loading, setLoading] = useState(false);

  //set single plan in state
  const [plan, setPlan] = useState({});
  const [planOptions, setPlanOptions] = useState([]);

  useEffect(() => {
    if (props?.singlePlan?.options) {
      setPlanOptions(props?.singlePlan?.options);
    }

    if (props?.singlePlan?.plan) {
      setPlan(props?.singlePlan?.plan);
    }
  }, [props?.singlePlan?.options, props?.singlePlan?.plan]);

  //check if plan is add, accident, cancer, or hospital - we edit these differently

  const checkIfPlanIsAccAddCanOrHosp = () => {
    let _isAccAddCanOrHosp = false;
    if (
      plan?.calculatedPlanTypeId &&
      (plan?.calculatedPlanTypeId === 3 ||
        plan?.calculatedPlanTypeId === 7 ||
        plan?.calculatedPlanTypeId === 8 ||
        plan?.calculatedPlanTypeId === 9)
    ) {
      _isAccAddCanOrHosp = true;
    }
    setIsAccAddCanOrHosp(_isAccAddCanOrHosp);
  };

  const [isAccAddCanOrHosp, setIsAccAddCanOrHosp] = useState(false);

  useEffect(() => {
    checkIfPlanIsAccAddCanOrHosp();
  }, [plan?.calculatedPlanTypeId]);

  //// ELIMINATION PERIOD & BENEFIT DURATION EDIT LOGIC - some plans will have more than one option for these /////

  const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState([]);
  const [benefitDurTypes, setBenefitDurTypes] = useState([]);
  //selected elimination period
  const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
    useState(null);
  // selected benefit duration
  const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

  //get only unique values of elimination period from member options excluding duplicates and 0
  const getUniqueEliminationPeriodList = () => {
    let elimPeriodInfo = [];

    for (let option of planOptions?.options) {
      if (option.eliminationPeriod > 0) {
        let object = {
          eliminationPeriod: option.eliminationPeriod,
          type: option.eliminationPeriodType,
          typeId: option.eliminationPeriodTypeId,
        };
        elimPeriodInfo.push(object);
      }
    }

    let uniqueElimPeriodArr = [
      ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
    ];

    let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
      (eliminationPeriod) => {
        return elimPeriodInfo.find(
          (obj) => obj.eliminationPeriod === eliminationPeriod
        );
      }
    );

    setEliminationPeriodTypes(uniqueElimPeriodOptions);
    setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
  };

  // get only unique values of benefit duration from member options excluding duplicates and 0
  const getUniqueBenefitDurationList = () => {
    let benefitDurationInfo = [];

    for (let option of planOptions?.options) {
      if (option.benefitDuration > 0) {
        let object = {
          duration: option.benefitDuration,
          type: option.benefitDurationType,
          typeId: option.benefitDurationTypeId,
        };
        benefitDurationInfo.push(object);
      }
    }

    let uniqueBenefitDurList = [
      ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
    ];

    let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
      return benefitDurationInfo.find((obj) => obj.duration === duration);
    });

    //set member benefit duration options off of unique options only
    setBenefitDurTypes(uniqueBenefitDurOptions);
    setSelectedBenefitDuration(uniqueBenefitDurOptions[0]?.duration);
  };

  useEffect(() => {
    if (planOptions?.options) {
      getUniqueEliminationPeriodList();
      getUniqueBenefitDurationList();
    }
  }, [planOptions?.options]);

  //EDIT benefit duration or elimination period for incremental plans

  const handleSelectBenefitDuration = (e) => {
    setSelectedBenefitDuration(e.target.value);
  };

  const handleSelectEliminationPeriod = (e) => {
    setSelectedEliminationPeriod(e.target.value);
  };

  ////////////////////////////////////////////////////////////////////

  //Edit Plan Info
  //open verticle dot menu to edit or cancel coverage
  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  //update accident, add, cancer, hospital plan premium amount
  const [uniquePlanAdditionalInfo, setUniquePlanAdditionalInfo] = useState({
    personTypeId: plan?.personTypeId,
    premiumAmount: plan?.premiumAmount,
  });

  //get currently selected option for plan
  useEffect(() => {
    //for all plans excluding accident, add, cancer, hospital
    if (planOptions?.options?.length > 0 && !isAccAddCanOrHosp) {
      let _selectedOption = planOptions?.options.find(
        (option) => option.memberOptionId === plan?.planOptionId
      );
      setSelectedOption(_selectedOption);
    }

    if (planOptions?.options?.length > 0 && isAccAddCanOrHosp) {
      let memberIsSelected;
      let memberAndSpouseIsSelected;
      let memberAndDependentsIsSelected;
      let familyIsSelected;

      let _selectedOption;
      for (let option of planOptions.options) {
        if (planOptions?.selectedOptionId == option.memberOptionId)
          memberIsSelected = true;
        if (planOptions?.selectedOptionId == option.memberAndSpouseOptionId)
          memberAndSpouseIsSelected = true;
        if (planOptions?.selectedOptionId == option.memberAndDependentsOptionId)
          memberAndDependentsIsSelected = true;
        if (planOptions?.selectedOptionId == option.familyOptionId)
          familyIsSelected = true;
        if (
          memberIsSelected ||
          memberAndSpouseIsSelected ||
          memberAndDependentsIsSelected ||
          familyIsSelected
        ) {
          _selectedOption = option;
          setSelectedOption(option);
          break;
        }
      }
      let premiumAmount = 0;
      let _personTypeId = 0;
      if (memberIsSelected) {
        premiumAmount = _selectedOption?.memberPremiumAmount;
        _personTypeId = 0;
      } else if (memberAndSpouseIsSelected) {
        premiumAmount = _selectedOption?.memberAndSpousePremiumAmount;
        _personTypeId = 1;
      } else if (memberAndDependentsIsSelected) {
        premiumAmount = _selectedOption?.memberAndDependentsPremiumAmount;
        _personTypeId = 2;
      } else if (familyIsSelected) {
        premiumAmount = _selectedOption?.familyPremiumAmount;
        _personTypeId = 3;
      }
      setUniquePlanAdditionalInfo({
        ...uniquePlanAdditionalInfo,
        premiumAmount: premiumAmount,
        personTypeId: _personTypeId,
      });
    }
  }, [
    planOptions?.options?.length,
    planOptions?.selectedOptionId,
    plan?.planOptionId,
    isAccAddCanOrHosp,
  ]);

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  //Edit local state value of plan
  let handlePlanEdit = (e, option) => {
    let value = e.target.value;

    // update plan value
    setPlan({
      ...plan,
      planOptionId: value,
    });
    //update planOptions selectedOptionId value
    setPlanOptions({
      ...planOptions,
      selectedOptionId: value,
    });
  };

  //Update plan info
  const updateActiveLineOfCoverage = async () => {
    setLoading(true);

    //build copy of plan object to send w/ new benefit amount, personTypeId, premium amount - planOptionId already updated from selection
    //update accident, add, cancer, hospital plans premium amount and personTypeId conditionally
    let _updatedPlanObj = {
      ...plan,
      benefitAmount: selectedOption?.benefitAmount,
      premiumAmount: isAccAddCanOrHosp
        ? uniquePlanAdditionalInfo?.premiumAmount
        : selectedOption?.memberPremiumAmount,
      personTypeId: isAccAddCanOrHosp
        ? uniquePlanAdditionalInfo?.personTypeId
        : plan?.personTypeId,
    };

    //update line of coverage in db if benefit amount > 0 OR plan is a acc, add, can, or hosp plan (these have benefit amount=0 BUT need to check premium amount to determine if decline option selected), else oopen cancel coverage drawer
    if (
      _updatedPlanObj.benefitAmount > 0 ||
      (isAccAddCanOrHosp && _updatedPlanObj.premiumAmount > 0)
    ) {
      try {
        let updatedPlan =
          await activeCoverageService.updateActiveCoverageForMember(
            _updatedPlanObj
          );
      } catch (error) {
        console.error('error updating plan', error);
      }
      //refresh active coverages and payments
      await props?.getActiveCoverage(props?.member?.id);
      await props?.getPaymentSchedule();
    } else if (_updatedPlanObj.benefitAmount === 0) {
      //open cancel coverage drawer
      props?.handleOpenCancelCoverageDrawer(null, plan);
    }

    setEdit(false);
    setLoading(false);
  };

  //Cancel individual line of coverage

  return (
    <Box sx={activeCoverageStyles.container}>
      {/* Plan Title and Amount */}
      <Box sx={activeCoverageStyles.planTitleRow}>
        <Typography sx={activeCoverageStyles.planTitle}>
          {plan?.displayName}
        </Typography>
        <Box sx={activeCoverageStyles.planAmountContainer}>
          <Typography sx={activeCoverageStyles.planAmount}>
            {props?.UsdFormatterDec.format(plan?.premiumAmount)}
          </Typography>
          <Typography sx={activeCoverageStyles.perMonth}>/per month</Typography>

          {/* Edit Plan Menu */}
          <Box>
            <IconButton
              onClick={(e) => handleOpenRowMenu(e, plan)}
              value={plan?.planId}
              sx={activeCoverageStyles.threeDotMenu.iconButton}
            >
              <MoreVertIcon
                sx={activeCoverageStyles.threeDotMenu.MoreVertIcon}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl?.[plan.planId]}
              open={Boolean(anchorEl?.[plan.planId])}
              onClose={handleCloseRowMenu}
              sx={{ ul: { padding: '0px' }, justifyContent: 'flex-start' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {plan?.isCancelled && (
                <MenuItem
                  onClick={(e) => {
                    props?.handleOpenReinstateDrawer(e, plan);
                    handleCloseRowMenu();
                  }}
                  sx={componentStyles.menuOptions}
                  // disabled={!props.allowModify || !plan?.isCancelled}
                  disabled={true}
                >
                  Re-Instate Coverage
                </MenuItem>
              )}

              {!plan?.isCancelled && (
                <MenuItem
                  onClick={(e) => {
                    if (props?.activePlansNotPendingCancel?.length > 1) {
                      //cancel single line of coverage
                      props?.handleOpenCancelCoverageDrawer(e, plan);
                    } else if (
                      props?.activePlansNotPendingCancel?.length === 1
                    ) {
                      //cancel all coverage
                      props?.handleOpenCancelCoverageDrawer(
                        null,
                        'cancelAllCoverage'
                      );
                    }
                    handleCloseRowMenu();
                  }}
                  sx={componentStyles.menuOptions}
                  disabled={!props.allowModify || plan?.isCancelled || edit}
                >
                  {props?.activePlansNotPendingCancel?.length === 1
                    ? 'Cancel All Coverage'
                    : 'Cancel Coverage'}
                </MenuItem>
              )}
              {!plan?.isCancelled && (
                <MenuItem
                  //   value={}
                  onClick={() => {
                    setEdit(true);
                    handleCloseRowMenu();
                  }}
                  sx={componentStyles.menuOptions}
                  disabled={!props.allowModify || plan?.isCancelled}
                >
                  Edit Coverage
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </Box>
      {/* Pending cancelation or modification warning */}
      {(plan?.futureActiveCoverageId || plan?.isCancelled) &&
        props?.pendingChangesMessage(plan)}
      {/* ////////// Viw/Edit Active Coverage Body ////////////*/}
      {/* Non-incremental plans */}
      {!planOptions?.isIncremental && !isAccAddCanOrHosp && (
        <NonIncrementalStdAndLtdActiveCoverage
          UsdFormatterDec={props?.UsdFormatterDec}
          formatDate={props?.formatDate}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          plan={plan}
          setPlan={setPlan}
          planOptions={planOptions}
          setPlanOptions={setPlanOptions}
          edit={edit}
          setEdit={setEdit}
          benefitDurTypes={benefitDurTypes}
          handlePlanEdit={handlePlanEdit}
        />
      )}
      {/* Incremental plans */}
      {planOptions?.isIncremental && !isAccAddCanOrHosp && (
        <IncrementalStdAndLtdActiveCoverage
          UsdFormatterDec={props?.UsdFormatterDec}
          formatDate={props?.formatDate}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          plan={plan}
          setPlan={setPlan}
          planOptions={planOptions}
          setPlanOptions={setPlanOptions}
          edit={edit}
          setEdit={setEdit}
          benefitDurTypes={benefitDurTypes}
          handlePlanEdit={handlePlanEdit}
          selectedEliminationPeriod={selectedEliminationPeriod}
          selectedBenefitDuration={selectedBenefitDuration}
          handleSelectBenefitDuration={handleSelectBenefitDuration}
          handleSelectedEliminationPeriod={handleSelectEliminationPeriod}
        />
      )}
      {/* accident, add, cancer, hospital */}
      {isAccAddCanOrHosp && (
        <AccAddCanOrHospitalCoverage
          UsdFormatterDec={props?.UsdFormatterDec}
          formatDate={props?.formatDate}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          plan={plan}
          setPlan={setPlan}
          planOptions={planOptions}
          setPlanOptions={setPlanOptions}
          edit={edit}
          setEdit={setEdit}
          handlePlanEdit={handlePlanEdit}
        />
      )}
      {/* Offset row - non editable */}
      {plan?.benefitOffsets ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Offset</Typography>
          <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
            {plan?.benefitOffsets}
          </Typography>
        </Box>
      ) : null}
      {/* Coverage Effective Date and Edit/Save Edit buttons*/}
      {!edit ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">{`Effective Date: ${
            plan?.effectiveStartDate
              ? format(
                  props?.formatDate(plan?.effectiveStartDate),
                  'MM/dd/yyyy'
                )
              : 'N/A'
          }`}</Typography>
        </Box>
      ) : (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <EditCoverageEffectiveDateStepper
            currentEffectiveDate={plan?.effectiveStartDate}
          />
          <Box sx={activeCoverageStyles.editButtonsContainer}>
            <Button
              variant="outlined"
              sx={activeCoverageStyles.saveAndCancelEditButtons}
              onClick={() => {
                setEdit(false);
                setLoading(false);
              }}
            >
              BACK
            </Button>

            <Button
              variant="contained"
              sx={activeCoverageStyles.saveAndCancelEditButtons}
              onClick={
                edit
                  ? () => updateActiveLineOfCoverage()
                  : console.log(
                      'this will be where we edit coverage effective date in future'
                    )
              }
              disabled={loading}
            >
              {loading ? (
                <>
                  <Box style={{ width: '100%', position: 'absolute' }}>
                    <InfinityLoader
                      style={{
                        zIndex: 5,
                        height: '30px',
                      }}
                    />
                  </Box>
                  <Typography sx={{ opacity: 0 }}>SAVE CHANGES</Typography>
                </>
              ) : (
                <>SAVE CHANGES</>
              )}
            </Button>
          </Box>
        </Box>
      )}{' '}
    </Box>
  );
}
