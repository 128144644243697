import React, { useState, useEffect } from 'react';

//MuiIcons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Tooltip } from '@mui/material';

//Our icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//Our components
import RecommendedBenefitDisplay from '../../RecommendedBenefitDisplay';
import ExpandAndCollapseIcon from '../../enrollment-custom-components/ExpandAndCollapseIcon';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../enrollmentPlanStyles';
import externalEnrollmentSharedStyles from '../../../externalEnrollmentSharedStyles';

export default function EnrollmentMembership(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let membershipPlan = results?.membershipPlan;
  let membershipHelpText = membershipPlan?.helpText;
  // let membershipHelpText = membershipPlan?.helpText.split(`\n`);

  const [expanded, setExpanded] = useState(true);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  const [currRec, setCurrRec] = useState(0);

  useEffect(() => {
    //if recs exist, check rec
    if (props?.results?.membershipPlan?.options?.length > 0) {
      //check each option against rec w/ the selectionRec function - function returns true if option = rec
      let _recValue = results?.membershipPlan?.options?.find(
        (option, index) => {
          let _rec = props?.selectionRec(
            props?.results?.membershipPlan?.options,
            index
          );
          if (_rec) return _rec;
        }
      );

      //if rec exists, set currRec to rec value
      if (_recValue) setCurrRec(_recValue);
    }
  }, [props?.recommendations, props?.results?.membershipPlan?.options]);

  return (
    <>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <Typography
          sx={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          {results?.membershipPlan?.title || 'Union Membership'}
          {/* bigger clickable area */}
        </Typography>

        <Box
          sx={enrollmentPlanStyles.expandAndCollapseButton}
          onClick={handleExpandSection}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: membershipHelpText }}
          />
        </Box>

        {/* <RecommendedBenefitDisplay currRec={currRec} /> */}

        <RadioGroup
          onChange={props.selectionHandler}
          value={props.selections.membership || currRec?.memberOptionId}
        >
          {results &&
            membershipPlan.options
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((option, index) => {
                let isSelected =
                  props.selections.membership == option.memberOptionId ||
                  (!props.selections.membership &&
                    currRec?.memberOptionId == option.memberOptionId);
                if (!option.isDecline)
                  return (
                    <React.Fragment key={`${JSON.stringify(option)}`}>
                      <Box sx={enrollmentPlanStyles.optionRow}>
                        {/* radio button, benefit amount, type */}
                        <Box
                          sx={enrollmentPlanStyles.optionRadioAndTextContainer}
                        >
                          <Radio
                            sx={enrollmentPlanStyles.planSelectRadioButton}
                            value={option.memberOptionId}
                            id={`${option.memberOptionId}`}
                            name="membership"
                            icon={
                              <RadioButtonIcon
                                sx={enrollmentPlanStyles.radioButtonIcon(false)}
                              />
                            }
                            checkedIcon={
                              <RadioButtonCheckedIcon
                                sx={enrollmentPlanStyles.radioButtonIcon(false)}
                              />
                            }
                            onClick={() => {
                              props.handleEnrollClick(option.memberOptionId);
                              // props.setExpanded({ ...props.expanded, ltd: true })
                            }}
                            inputProps={{
                              'data-amount': option.memberPremiumAmount,
                              'data-uhtest': 'Membership_' + option.name,
                            }}
                          />

                          <Box
                            sx={enrollmentPlanStyles.singleOptionTextContainer}
                          >
                            <Typography
                              sx={enrollmentPlanStyles.singleOptionText(
                                isSelected
                              )}
                            >
                              {option?.name && (
                                <>
                                  {option.name}{' '}
                                  {option.name.includes('%') &&
                                    option?.benefitAmount &&
                                    `(Max ${UsdFormatter.format(
                                      option.benefitAmount
                                    )})`}
                                </>
                              )}
                            </Typography>{' '}
                            {/* {option?.memberOptionId ==
                              currRec?.memberOptionId && (
                              <Typography
                                sx={enrollmentPlanStyles.recommendedText}
                              >{`(recommended)`}</Typography>
                            )} */}
                          </Box>
                        </Box>

                        {/* premium amount */}
                        <Box
                          sx={enrollmentPlanStyles.singleOptionText(isSelected)}
                        >
                          {isSelected && '+'}
                          {!props.dummyDisplay &&
                            UsdFormatterDec.format(option.memberPremiumAmount)}
                          {props.dummyDisplay && '$0.00'}
                        </Box>
                      </Box>

                      {/* explanation of benefits */}
                      {option.explanationOfBenefits && (
                        <Box
                          sx={enrollmentPlanStyles.optionExplanationOfBenefits}
                        >
                          {option.explanationOfBenefits}
                        </Box>
                      )}

                      {/* benefit offsets */}
                      {option.benefitOffsets && (
                        <Box
                          sx={enrollmentPlanStyles.optionExplanationOfBenefits}
                        >
                          {option.benefitOffsets}
                        </Box>
                      )}
                    </React.Fragment>
                  );
              })}
        </RadioGroup>
      </Collapse>
      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.membership &&
            props.selections.membershipVal > 0 && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  {
                    membershipPlan.options.find(
                      (option) =>
                        option.memberOptionId == props.selections.membership
                    )?.name
                  }
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.membershipVal)}
                </Box>
              </>
            )}
          {props.selections.membership &&
            props.selections.membershipVal == 0 && (
              <>
                <Typography>
                  <em>not elected</em>
                </Typography>
                <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          {!props.selections.membership && 'No plan selected'}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
