import React, { useState, useEffect } from 'react';
import { set, format } from 'date-fns';

//Mui icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Mui Components
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from '@mui/material';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';

//our components
import InfinityLoader from '../../../../../../../UH-loading-animation/InfinityLoader';
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';
import BeneficiaryViewAndEdit from './BeneficiaryViewAndEdit';

//Our active coverage global Styles
import activeCoverageStyles from './activeCoverageStyles';
import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

//component local styles
const componentStyles = {
  menuOptions: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    height: '48px',
    '&:hover': { backgroundColor: 'primary.light' },
  },
  benefitInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'top',
    minHeight: '40px',
    marginRight: '8px',
    fontFamily: 'Archivo',
  },
  benefitAmountContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
  },
  benefitAmountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'right',
    width: '100%',
    minHeight: '40px',
  },
};

export default function ActiveLineOfCoverageLadd(props) {
  //loading state
  const [loading, setLoading] = useState(false);

  //set array of local Ladd plans in state of this component
  const [memberLaddPlan, setMemberLaddPlan] = useState({});
  const [spouseLaddPlan, setSpouseLaddPlan] = useState({});
  const [dependentLaddPlan, setDependentLaddPlan] = useState({});
  const [totalPremium, setTotalPremium] = useState(0);
  const [laddSelections, setLaddSelections] = useState({});

  useEffect(() => {
    if (props?.activeLaddPlansAndOptions?.length > 0) {
      //set local state of all ladd plans
      // setLaddPlans(activeLaddPlansAndOptions)
      //set member ladd plan object to only the active coverage object
      let _memberLaddPlan = props?.activeLaddPlansAndOptions.find((plan) => {
        return plan?.plan?.displayName === 'LADD';
      });
      let _spouseLaddPlan = props?.activeLaddPlansAndOptions.find((plan) => {
        return plan?.plan?.displayName === 'LADD-S';
      });
      let _dependentLaddPlan = props?.activeLaddPlansAndOptions.find((plan) => {
        return plan?.plan?.displayName === 'LADD-D';
      });
      setMemberLaddPlan(_memberLaddPlan?.plan);
      setSpouseLaddPlan(_spouseLaddPlan?.plan);
      setDependentLaddPlan(_dependentLaddPlan?.plan);

      //if member, spouse, or dep elections exist in db, set selections
      //member
      let _laddSelections = {
        member: {
          isElected: false,
          benefitAmount: 0,
          premiumAmount: 0,
          isUpdated: false,
          activeCoverageId: 0,
        },
        spouse: {
          isElected: false,
          benefitAmount: 0,
          premiumAmount: 0,
          isUpdated: false,
          activeCoverageId: 0,
        },
        dependent: {
          isElected: false,
          benefitAmount: 0,
          premiumAmount: 0,
          isUpdated: false,
          activeCoverageId: 0,
        },
      };
      if (_memberLaddPlan?.plan?.id) {
        _laddSelections = {
          ..._laddSelections,
          member: {
            isElected: true,
            benefitAmount: _memberLaddPlan?.plan?.benefitAmount,
            premiumAmount: _memberLaddPlan?.plan?.premiumAmount,
            isUpdated: false,
            activeCoverageId: _memberLaddPlan?.plan?.id,
          },
        };
      }
      //spouse
      if (_spouseLaddPlan?.plan?.id) {
        _laddSelections = {
          ..._laddSelections,
          spouse: {
            isElected: true,
            benefitAmount: _spouseLaddPlan?.plan?.benefitAmount,
            premiumAmount: _spouseLaddPlan?.plan?.premiumAmount,
            isUpdated: false,
            activeCoverageId: _spouseLaddPlan?.plan?.id,
          },
        };
      }
      //dependent
      if (_dependentLaddPlan?.plan?.id) {
        _laddSelections = {
          ..._laddSelections,
          dependent: {
            isElected: true,
            benefitAmount: _dependentLaddPlan?.plan?.benefitAmount,
            premiumAmount: _dependentLaddPlan?.plan?.premiumAmount,
            isUpdated: false,
            activeCoverageId: _dependentLaddPlan?.plan?.id,
          },
        };
      }

      //SET LADD SELECTIONS
      setLaddSelections(_laddSelections);

      //get total premium amount for all ladd plans
      let total = 0;
      props?.activeLaddPlansAndOptions.forEach((plan) => {
        if (plan?.plan?.premiumAmount > 0) {
          total += plan?.plan?.premiumAmount;
        }
      });
      setTotalPremium(total);
    }
  }, [props?.activeLaddPlansAndOptions]);

  //Combined options for member, spouse, and deps
  const [planOptions, setPlanOptions] = useState({});

  useEffect(() => {
    if (props?.activeLaddPlansAndOptions[0]?.options) {
      setPlanOptions(props?.activeLaddPlansAndOptions[0]?.options);
    }
  }, [props?.activeLaddPlansAndOptions[0]?.options]);

  ///////////// Spouse and Dependent option max logic ////////////
  const [spouseMaxAmount, setSpouseMaxAmount] = useState(0);
  const [dependentMaxAmount, setDependentMaxAmount] = useState(0);

  //CHECK SPOUSE max amount w/ member benefit changes
  useEffect(() => {
    if (planOptions?.spouseMaximumAmount && planOptions?.spousePercentageCap) {
      //check spouse max amount against member amount AND spouse percentage cap
      if (
        planOptions?.spouseMaximumAmount <=
        laddSelections?.member?.benefitAmount * planOptions?.spousePercentageCap
      ) {
        setSpouseMaxAmount(planOptions?.spouseMaximumAmount);
      } else {
        let _spouseMax =
          laddSelections?.member?.benefitAmount *
          planOptions?.spousePercentageCap;
        setSpouseMaxAmount(_spouseMax);

        //IF current spouse selection > new spouse max, find and update spouse w/ new closest spouse option that meets spouse max parameters
        if (laddSelections?.spouse?.benefitAmount > _spouseMax) {
          let _newSpouseSelection = planOptions?.spouseOptions.reduce(
            (prevOption, currOption) => {
              if (
                currOption.coverageAmount <= _spouseMax &&
                prevOption.coverageAmount <= _spouseMax
              ) {
                return prevOption.coverageAmount > currOption.coverageAmount
                  ? prevOption
                  : currOption;
              }
              // Return the previous option if the condition is not met
              return prevOption;
            },
            planOptions?.spouseOptions[0]
          );

          //update spouse selection
          if (_newSpouseSelection) {
            setLaddSelections({
              ...laddSelections,
              spouse: {
                ...laddSelections.spouse,
                benefitAmount: _newSpouseSelection.coverageAmount,
                premiumAmount: _newSpouseSelection.premiumAmount,
                isUpdated: true,
              },
            });
          }
        }
      }
    }
  }, [
    laddSelections?.member?.benefitAmount,
    planOptions?.spouseMaximumAmount,
    planOptions?.spousePercentageCap,
  ]);

  //CHECK DEPENDENT max amount w/ member benefit changes
  useEffect(() => {
    if (
      planOptions?.dependentMaximumAmount &&
      planOptions?.dependentPercentageCap
    ) {
      if (
        planOptions?.dependentMaximumAmount &&
        planOptions?.dependentPercentageCap
      ) {
        //check dependent max amount agains member amount AND dependent percentage cap
        if (
          planOptions?.dependentMaximumAmount <=
          laddSelections?.member?.benefitAmount *
            planOptions?.dependentPercentageCap
        ) {
          setDependentMaxAmount(planOptions?.dependentMaximumAmount);
        } else {
          let _dependentMax =
            laddSelections?.member?.benefitAmount *
            planOptions?.dependentPercentageCap;
          setDependentMaxAmount(_dependentMax);

          //IF current dependent selection > new dependent max, find and update dependent w/ new closest dependent option that meets dependent max parameters
          if (laddSelections?.dependent?.benefitAmount > _dependentMax) {
            let _newDependentSelection = planOptions?.dependentOptions.reduce(
              (prevOption, currOption) => {
                if (
                  currOption.coverageAmount <= _dependentMax &&
                  prevOption.coverageAmount <= _dependentMax
                ) {
                  return prevOption.coverageAmount > currOption.coverageAmount
                    ? prevOption
                    : currOption;
                }
                // Return the previous option if the condition is not met
                return prevOption;
              },
              planOptions?.dependentOptions[0]
            );
            //update dependent selection
            if (_newDependentSelection) {
              setLaddSelections({
                ...laddSelections,
                dependent: {
                  ...laddSelections.dependent,
                  benefitAmount: _newDependentSelection.coverageAmount,
                  premiumAmount: _newDependentSelection.premiumAmount,
                  isUpdated: true,
                },
              });
            }
          }
        }
      }
    }
  }, [
    laddSelections?.member?.benefitAmount,
    planOptions?.dependentMaximumAmount,
    planOptions?.dependentPercentageCap,
  ]);

  //Ladd Benefit Amount Change
  const handleLaddAmountChange = (e, type, optionSelected) => {
    //update selections
    if (optionSelected === 0) {
      setLaddSelections({
        ...laddSelections,
        [type]: {
          ...laddSelections[type],
          benefitAmount: 0,
          premiumAmount: 0,
          isUpdated: true,
        },
      });
    } else {
      setLaddSelections({
        ...laddSelections,
        [type]: {
          ...laddSelections[type],
          benefitAmount: optionSelected?.coverageAmount,
          premiumAmount: optionSelected?.premiumAmount,
          isUpdated: true,
        },
      });
    }
    //need to updat this to check for member amount before allowing spouse amount update - may need to lower spouse amount if member amount is too low???
  };

  //UPDATE BENEFIT AMOUNTS IN DB
  let updateActiveLineOfCoverage = async () => {
    // setLoading(true)
    //check permissions before updating beneficiary changes and plan amount/ coverage effective changes
    if (props?.allowEditEnrollment) {
      //check for edited selections before updating
      //Member
      if (laddSelections?.member?.isUpdated) {
        let _memberLaddPlan = {
          ...memberLaddPlan,
          benefitAmount: laddSelections?.member?.benefitAmount,
          premiumAmount: laddSelections?.member?.premiumAmount,
        };
        //if benefit amount > 0, update active coverage, else open cancel coverage drawer
        if (_memberLaddPlan.benefitAmount > 0) {
          try {
            await activeCoverageService.updateActiveCoverageForMember(
              _memberLaddPlan
            );
          } catch (error) {
            console.error('error updating member ladd plan', error);
          }
        } else {
          props?.handleOpenCancelCoverageDrawer(null, memberLaddPlan);
        }
      }

      //Spouse
      if (laddSelections?.spouse?.isUpdated) {
        let _spouseLaddPlan = {
          ...spouseLaddPlan,
          benefitAmount: laddSelections?.spouse?.benefitAmount,
          premiumAmount: laddSelections?.spouse?.premiumAmount,
        };
        //if benefit amount > 0, update active coverage, else open cancel coverage drawer
        if (_spouseLaddPlan.benefitAmount > 0) {
          try {
            await activeCoverageService.updateActiveCoverageForMember(
              _spouseLaddPlan
            );
          } catch (error) {
            console.error('error updating spouse ladd plan', error);
          }
        } else {
          props?.handleOpenCancelCoverageDrawer(null, spouseLaddPlan);
        }
      }

      //Dependent
      if (laddSelections?.dependent?.isUpdated) {
        let _dependentLaddPlan = {
          ...dependentLaddPlan,
          benefitAmount: laddSelections?.dependent?.benefitAmount,
          premiumAmount: laddSelections?.dependent?.premiumAmount,
        };
        //if benefit amount > 0, update active coverage, else open cancel coverage drawer
        if (_dependentLaddPlan.benefitAmount > 0) {
          try {
            await activeCoverageService.updateActiveCoverageForMember(
              _dependentLaddPlan
            );
          } catch (error) {
            console.error('error updating dependent ladd plan', error);
          }
        } else {
          props?.handleOpenCancelCoverageDrawer(null, dependentLaddPlan);
        }
      }
    }
    setEdit(false);
    setLoading(false);
    //refresh active coverage and payments
    await props?.getActiveCoverage(props?.member?.id);
    await props?.getPaymentSchedule();
  };
  //cancel member will cancel all ladd plans

  const [edit, setEdit] = useState(false);
  const [editBeneficiary, setEditBeneficiary] = useState(false);

  //open verticle dot menu to edit or cancel coverage
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const getSinglePlanDisplayName = (name) => {
    switch (name) {
      case 'LADD':
        return 'Member';
      case 'LADD-S':
        return 'Spouse';
      case 'LADD-D':
        return 'Dependent';
      default:
        return '';
    }
  };

  //   const handleCheckForEdit = () => {
  //     // If user selects "Edit" and value hasn't changed, set to isUpdated
  //     if(laddSelections?.member?.isElected || laddSelections?.spouse?.isElected ||laddSelections?.dependent?.isElected){
  //       setLaddSelections({
  //         ...laddSelections,
  //         member: {
  //           ...laddSelections["member"],
  //           isUpdated: laddSelections?.member?.isElected,
  //         },
  //         spouse: {
  //           ...laddSelections["spouse"],
  //           isUpdated: laddSelections?.spouse?.isElected,
  //         },
  //         dependent: {
  //           ...laddSelections["dependent"],
  //           isUpdated: laddSelections?.dependent?.isElected,
  //         },
  //       })
  //     }
  // }

  const handleEditBackCheck = () => {
    setLaddSelections({
      ...laddSelections,
      member: {
        ...laddSelections['member'],
        isUpdated: false,
      },
      spouse: {
        ...laddSelections['spouse'],
        isUpdated: false,
      },
      dependent: {
        ...laddSelections['dependent'],
        isUpdated: false,
      },
    });
  };

  return (
    <Box sx={activeCoverageStyles.container}>
      {/* Plan Title and Amount */}
      <Box sx={activeCoverageStyles.planTitleRow}>
        <Typography sx={activeCoverageStyles.planTitle}>LIFE & AD&D</Typography>
        <Box sx={activeCoverageStyles.planAmountContainer}>
          <Typography sx={activeCoverageStyles.planAmount}>
            {props?.UsdFormatterDec.format(totalPremium) || ''}
          </Typography>
          <Typography sx={activeCoverageStyles.perMonth}>/per month</Typography>

          {/* Edit Plan Menu */}
          <Box>
            <IconButton
              onClick={(e) =>
                handleOpenRowMenu(e, props?.activeLaddPlansAndOptions)
              }
              value={props?.activeLaddPlansAndOptions[0]?.plan?.planId}
              sx={activeCoverageStyles.threeDotMenu.iconButton}
            >
              <MoreVertIcon
                sx={activeCoverageStyles.threeDotMenu.MoreVertIcon}
              />
            </IconButton>
            <Menu
              anchorEl={
                anchorEl?.[props?.activeLaddPlansAndOptions[0]?.plan?.planId]
              }
              open={Boolean(
                anchorEl?.[props?.activeLaddPlansAndOptions[0]?.plan?.planId]
              )}
              onClose={handleCloseRowMenu}
              sx={{ ul: { padding: '0px' }, justifyContent: 'flex-start' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {/* {!memberLaddPlan?.isCancelled && */}
              {!memberLaddPlan?.isCancelled && (
                <MenuItem
                  //   value={}
                  onClick={(e) => {
                    setEdit(true);
                    setEditBeneficiary(false); //cannot edit benny and plan coverage at same time
                    handleCloseRowMenu();
                    // handleCheckForEdit()
                  }}
                  sx={componentStyles.menuOptions}
                  disabled={!props?.allowModify || memberLaddPlan?.isCancelled}
                >
                  Edit Coverage
                </MenuItem>
              )}

              {!memberLaddPlan?.isCancelled && (
                <MenuItem
                  //   value={}
                  onClick={() => {
                    setEditBeneficiary(true);
                    setEdit(false); //cannot edit benny and plan coverage at same time
                    handleCloseRowMenu();
                  }}
                  sx={componentStyles.menuOptions}
                  disabled={
                    !props?.allowEditEnrollment || memberLaddPlan?.isCancelled
                  }
                >
                  Edit Beneficiaries
                </MenuItem>
              )}
              {/* } */}
              {!memberLaddPlan?.isCancelled && (
                <MenuItem
                  onClick={(e) => {
                    if (props?.activePlansNotPendingCancel?.length > 1) {
                      //cancel member coverage - spouse and dep will also be cancelled
                      props?.handleOpenCancelCoverageDrawer(
                        null,
                        memberLaddPlan
                      );
                    } else if (
                      props?.activePlansNotPendingCancel?.length === 1
                    ) {
                      //cancel all coverage
                      props?.handleOpenCancelCoverageDrawer(
                        null,
                        'cancelAllCoverage'
                      );
                    }
                    handleCloseRowMenu();
                  }}
                  //  disabled={!allowModify || pendingCancellation}
                  sx={componentStyles.menuOptions}
                  disabled={!props?.allowModify || memberLaddPlan?.isCancelled}
                >
                  {props?.activePlansNotPendingCancel?.length === 1
                    ? 'Cancel All Coverage'
                    : 'Cancel Coverage'}
                </MenuItem>
              )}
              {memberLaddPlan?.isCancelled && (
                <Tooltip title="Coming Soon" disabled={false}>
                  <div>
                    <MenuItem
                      onClick={(e) => {
                        props?.handleOpenReinstateDrawer(e, memberLaddPlan);
                        handleCloseRowMenu();
                      }}
                      sx={componentStyles.menuOptions}
                      // disabled={!props.allowModify || !memberLaddPlan?.isCancelled}
                      disabled={true}
                    >
                      Re-Instate Coverage
                    </MenuItem>
                  </div>
                </Tooltip>
              )}
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* BenefitAmount Section */}
      <Box sx={componentStyles.benefitInfoRow}>
        <Box>Benefit Amount</Box>

        {/* Benefit Amounts */}
        <Box sx={componentStyles.benefitAmountContainer}>
          {/* display current amounts from db */}
          {!edit && props?.activeLaddPlansAndOptions?.length > 0
            ? props?.activeLaddPlansAndOptions?.map((plan, index) => {
                return (
                  <Box key={index} sx={componentStyles.benefitAmountRow}>
                    {/* pending changes message */}
                    {plan?.plan?.futureActiveCoverageId ||
                    plan?.plan?.isCancelled
                      ? props?.pendingChangesMessage(plan?.plan)
                      : null}

                    <Box>
                      {getSinglePlanDisplayName(plan?.plan?.displayName)}
                    </Box>
                    <Box sx={{ margin: '0px 4px' }}>{' - '}</Box>
                    <Box>
                      {` ${props?.UsdFormatterDec.format(
                        plan?.plan?.benefitAmount
                      )} - 
                       ${props?.UsdFormatterDec.format(
                         plan?.plan?.premiumAmount
                       )}` || ''}
                    </Box>
                  </Box>
                );
              })
            : null}

          {edit &&
            props?.allowModify &&
            props?.activeLaddPlansAndOptions?.length > 0 && (
              <>
                {/* Member edit */}
                {laddSelections?.member?.isElected && (
                  <FormControl fullWidth>
                    <InputLabel id="memberBenefit">Member Benefit</InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Member Benefit"
                      labelId="memberBenefit"
                      sx={activeCoverageStyles.selectDropDown}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={activeCoverageStyles.selectDownArrowIcon}
                        />
                      )}
                      value={laddSelections?.member?.benefitAmount}
                    >
                      <MenuItem
                        value={0}
                        key={0}
                        onClick={(e) => handleLaddAmountChange(e, 'member', 0)}
                      >
                        {props?.UsdFormatterDec.format(0)}
                      </MenuItem>
                      {planOptions?.memberOptions?.map((option) => {
                        return (
                          <MenuItem
                            value={option.coverageAmount}
                            key={option.coverageAmount}
                            onClick={(e) =>
                              handleLaddAmountChange(e, 'member', option)
                            }
                          >
                            {props?.UsdFormatterDec.format(
                              option?.coverageAmount || 0
                            )}{' '}
                            {` (${props?.UsdFormatterDec.format(
                              option?.premiumAmount || 0
                            )})`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

                {/* Spouse edit */}
                {laddSelections?.spouse?.isElected && (
                  <FormControl fullWidth>
                    <InputLabel id="spouseBenefit">Spouse Benefit</InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Spouse Benefit"
                      labelId="spouseBenefit"
                      sx={activeCoverageStyles.selectDropDown}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={activeCoverageStyles.selectDownArrowIcon}
                        />
                      )}
                      value={laddSelections.spouse?.benefitAmount}
                      // onChange={(e) => handleLaddAmountChange(e, "spouse")}
                    >
                      <MenuItem
                        value={0}
                        key={0}
                        onClick={(e) => handleLaddAmountChange(e, 'spouse', 0)}
                      >
                        {props?.UsdFormatterDec.format(0)}
                      </MenuItem>
                      {planOptions?.spouseOptions?.map((option) => {
                        //only show spouse max amount options available
                        if (option.coverageAmount <= spouseMaxAmount) {
                          return (
                            <MenuItem
                              value={option.coverageAmount}
                              key={option.coverageAmount}
                              onClick={(e) =>
                                handleLaddAmountChange(e, 'spouse', option)
                              }
                            >
                              {props?.UsdFormatterDec.format(
                                option.coverageAmount
                              )}
                              {` (${props?.UsdFormatterDec.format(
                                option?.premiumAmount || 0
                              )})`}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                )}

                {/* Dependent edit */}
                {laddSelections?.dependent?.isElected && (
                  <FormControl fullWidth>
                    <InputLabel id="dependentBenefit">
                      Dependent Benefit
                    </InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Dependent Benefit"
                      labelId="dependentBenefit"
                      sx={activeCoverageStyles.selectDropDown}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={activeCoverageStyles.selectDownArrowIcon}
                        />
                      )}
                      value={laddSelections?.dependent?.benefitAmount}
                      // onChange={(e) => handleLaddAmountChange(e, "dependent")}
                    >
                      <MenuItem
                        value={0}
                        key={0}
                        onClick={(e) =>
                          handleLaddAmountChange(e, 'dependent', 0)
                        }
                      >
                        {props?.UsdFormatterDec.format(0)}
                      </MenuItem>
                      {planOptions?.dependentOptions?.map((option) => {
                        if (option?.coverageAmount <= dependentMaxAmount) {
                          return (
                            <MenuItem
                              value={option.coverageAmount}
                              key={option.coverageAmount}
                              onClick={(e) =>
                                handleLaddAmountChange(e, 'dependent', option)
                              }
                            >
                              {props?.UsdFormatterDec.format(
                                option.coverageAmount
                              )}
                              {` (${props?.UsdFormatterDec.format(
                                option?.premiumAmount || 0
                              )})`}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
        </Box>
      </Box>

      {/* Beneficiaries */}
      <BeneficiaryViewAndEdit
        edit={editBeneficiary}
        setEdit={setEditBeneficiary}
        member={props?.member}
        plan={memberLaddPlan}
        relationshipTypes={props?.relationshipTypes}
      />

      {/* Coverage Effective Date & Save/cancel edit coverage buttons */}
      {!edit && !editBeneficiary && (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">{`Effective Date: ${
            props?.activeLaddPlansAndOptions[0]?.plan?.effectiveStartDate
              ? format(
                  props?.formatDate(
                    props?.activeLaddPlansAndOptions[0]?.plan
                      ?.effectiveStartDate
                  ),
                  'MM/dd/yyyy'
                )
              : 'N/A'
          }`}</Typography>
        </Box>
      )}

      {edit &&
        !editBeneficiary && ( //only display for editing coverage and not beneficiaries
          <Box sx={activeCoverageStyles.planInfoRow}>
            <EditCoverageEffectiveDateStepper
              currentEffectiveDate={
                props?.activeLaddPlansAndOptions[0]?.plan?.effectiveStartDate
              }
            />
            <Box sx={activeCoverageStyles.editButtonsContainer}>
              <Button
                variant="outlined"
                sx={activeCoverageStyles.saveAndCancelEditButtons}
                onClick={() => {
                  setEdit(false);
                  handleEditBackCheck();
                }}
              >
                BACK
              </Button>

              <Button
                variant="contained"
                sx={activeCoverageStyles.saveAndCancelEditButtons}
                onClick={
                  edit
                    ? updateActiveLineOfCoverage
                    : console.log(
                        'this will be where we edit coverage effective date possibly happens'
                      )
                }
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Box sx={{ width: '100%', position: 'absolute' }}>
                      <InfinityLoader
                        style={{
                          zIndex: 5,
                          height: '30px',
                        }}
                      />
                    </Box>
                    <Typography sx={{ opacity: 0 }}>SAVE CHANGES</Typography>
                  </>
                ) : (
                  <>SAVE CHANGES</>
                )}
              </Button>
            </Box>
          </Box>
        )}
    </Box>
  );
}
