import React, { useEffect, useState, useRef } from 'react';

// Services
import enrollmentService from '../../../../../services/enrollment-service';

//MuiComponents
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//MuiIcons
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';

//Our Components

export default function LegalDrawer(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const [enrollmentTerms, setEnrollmentTerms] = useState('');

  const getEnrollmentTerms = async () => {
    let thisEnrollmentTerm = await enrollmentService.getEnrollmentTerms(
      props.results?.groupPlanId
    );
    setEnrollmentTerms(thisEnrollmentTerm);
  };

  const [achTerms, setAchTerms] = useState('');

  const getAchTerms = async () => {
    let thisAchTerm = await enrollmentService.getACHTerms(
      props.results?.groupPlanId
    );
    setAchTerms(thisAchTerm);
  };
  const [verbalAgreement, setVerbalAgreement] = useState('');
  const [verbalAgreementObj, setVerbalAgreementObj] = useState({
    firstName: '',
    lastName: '',
    enrolledIn: '',
    totalCost: '',
    salary: '',
    email: '',
  });

  const [dynamicText, setDynamicText] = useState('');
  const createVerbalAgreementObj = () => {
    let transactionFee = props.bank?.paymentFrequencyId === 2 ? 2 : 1;
    let std = props?.selections?.std != null || '' || 0 ? true : false;
    let ltd = props?.selections?.ltd != null || '' || 0 ? true : false;
    let ladd = props?.selections?.ladd[0] != 0 || '' || null ? true : false;
    let enrolledInString = `${std ? 'Short Term Disability ' : ''} ${
      std && ltd ? '& ' : ''
    } ${ltd ? 'Long Term Disability ' : ''} ${ltd && ladd ? '&' : ''} ${
      ladd ? 'Life & AD&D ' : ''
    }`;
    setVerbalAgreementObj({
      ...verbalAgreementObj,
      firstName: props?.fullMemberObj?.firstName,
      lastName: props?.fullMemberObj?.lastName,
      enrolledIn: enrolledInString,
      totalCost: props.runningTotal + transactionFee,
      salary: props?.fullMemberObj?.annualSalary,
      email: props?.fullMemberObj?.email,
    });
    if (
      props?.results?.name?.length > 0 &&
      props?.results?.name.includes('VIP+')
    ) {
      setDynamicText(
        `<p>Please state your first and last name: ${
          verbalAgreementObj.firstName
            ? verbalAgreementObj.firstName
            : 'First Name'
        } ${
          verbalAgreementObj.lastName
            ? verbalAgreementObj.lastName
            : 'Last Name'
        }. Today you called and enrolled in: ${
          verbalAgreementObj.enrolledIn
            ? verbalAgreementObj.enrolledIn
            : 'Benefit Summary'
        } <strong>(Enroller reviewed the above selected coverages, applicable offsets, payments and transaction fees)</strong> with a total monthly cost of ${
          verbalAgreementObj.totalCost
            ? UsdFormatterDec.format(verbalAgreementObj.totalCost)
            : 'Total Cost'
        }. Shortly, you will receive an email at ${
          verbalAgreementObj.email
        } from UnionHub with your enrollment documentation. Please review the email to ensure everything is correct, and contact us if anything needs to be updated. If we do not hear from you, we will assume all information is accurate. It is your responsibility to thoroughly read the full policy booklet, which will be accessible at <a href='https://www.teamstersvip.com' target='_blank'>www.teamstersvip.com</a></p>`
      );
    } else if (
      props?.results?.name?.length > 0 &&
      props?.results?.name.includes('SMART-TD')
    ) {
      setDynamicText(
        `<p>Please state your first and last name: ${
          verbalAgreementObj.firstName
            ? verbalAgreementObj.firstName
            : 'First Name'
        } ${
          verbalAgreementObj.lastName
            ? verbalAgreementObj.lastName
            : 'Last Name'
        }. Today you called and enrolled in: ${
          verbalAgreementObj.enrolledIn
            ? verbalAgreementObj.enrolledIn
            : 'Benefit Summary'
        } <strong>(Enroller reviewed the above selected coverages, applicable offsets, payments and transaction fees)</strong> with a total monthly cost of ${
          verbalAgreementObj.totalCost
            ? UsdFormatterDec.format(verbalAgreementObj.totalCost)
            : 'Total Cost'
        }. It is your responsibility to thoroughly read the full policy booklet. To request a booklet, please email info@smart-vltd.com. You have reported an annual income of ${
          verbalAgreementObj.salary != ''
            ? UsdFormatter.format(verbalAgreementObj.salary)
            : 'Salary'
        }; <strong>If your elected benefit is tied to your income, please be aware if you report an income greater than what you make, you will not receive benefits on the overstated income amount.</strong></p>`
      );
    } else {
      setDynamicText(
        `<p>Please state your first and last name: ${
          verbalAgreementObj.firstName
            ? verbalAgreementObj.firstName
            : 'First Name'
        } ${
          verbalAgreementObj.lastName
            ? verbalAgreementObj.lastName
            : 'Last Name'
        }. Today you called and enrolled in: ${
          verbalAgreementObj.enrolledIn
            ? verbalAgreementObj.enrolledIn
            : 'Benefit Summary'
        } <strong>(Enroller reviewed the above selected coverages, applicable offsets, payments and transaction fees)</strong> with a total monthly cost of ${
          verbalAgreementObj.totalCost
            ? UsdFormatterDec.format(verbalAgreementObj.totalCost)
            : 'Total Cost'
        }. It is your responsibility to thoroughly read the full policy booklet. To request a booklet, please email admin@unionone.com. You have reported an annual income of ${
          verbalAgreementObj.salary != ''
            ? UsdFormatter.format(verbalAgreementObj.salary)
            : 'Salary'
        }; <strong>If your elected benefit is tied to your income, please be aware if you report an income greater than what you make, you will not receive benefits on the overstated income amount.</strong></p>`
      );
    }
  };

  useEffect(() => {
    getEnrollmentTerms();
    getAchTerms();
    createVerbalAgreementObj();
    getVerbalAgreement();
  }, []);

  useEffect(() => {
    createVerbalAgreementObj();
  }, [props.fullMemberObj, props.selections, props.runningTotal]);

  const getVerbalAgreement = async () => {
    let thisVerbalAgreement = await enrollmentService.getVerbalAgreement(
      props.results?.groupPlanId
    );
    setVerbalAgreement(thisVerbalAgreement);
  };

  return (
    <>
      <Drawer
        open={props.open}
        onClose={props.handleClose}
        anchor="bottom"
        sx={{ maxHeight: '200px' }}
        PaperProps={{
          sx: {
            borderRadius: '12px 12px 0px 0px',
            padding: '0px 16px 24px',
            bgcolor: 'background.default',
            color: 'text.primary',
            maxHeight: '75%',
          },
        }}
        disableScrollLock={true}
      >
        <Box
          sx={{
            bgcolor: 'paper.background.default',
            color: 'text.primary',
            maxWidth: '600px',
            margin: 'auto',
          }}
        >
          <div
            className="row"
            style={{
              marginBottom: '20px',
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              paddingTop: '16px',
            }}
          >
            <Typography className="col-10" variant="blueAccent">
              {props.document}
            </Typography>
            <div className="col-2" style={{ textAlign: 'end' }}>
              <IconButton onClick={props.handleClose}>
                <CloseIcon sx={{ color: '#195ffb' }} />
              </IconButton>
            </div>
          </div>
          <div className="col-12" style={{ marginBottom: '20px' }}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html:
                  (props.document == 'Terms and Conditions' &&
                    enrollmentTerms) ||
                  (props.document == 'ACH Debit Entries' && achTerms) ||
                  (props.document == 'Verbal Confirmation' &&
                    dynamicText + verbalAgreement) ||
                  '',
              }}
            ></Typography>
          </div>
        </Box>
      </Drawer>
    </>
  );
}
