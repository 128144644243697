export const getConnectionString = () => {
  let hostArr = window.location.host.split('.');
  let connectionString;
  if (hostArr.findIndex((el) => el == 'unionhub') > -1) {
    connectionString = appInsightsConnections.prod.connectionString;
  } else if (hostArr.findIndex((el) => el == 'unionhub-uat') > -1) {
    connectionString = appInsightsConnections.uat.connectionString;
  } else if (hostArr.findIndex((el) => el == 'unionhub-demo') > -1) {
    connectionString = appInsightsConnections.demo.connectionString;
  } else {
    connectionString = appInsightsConnections.dev.connectionString;
  }
  console.log(hostArr, connectionString);
  return connectionString;
};
const appInsightsConnections = {
  prod: {
    connectionString:
      'InstrumentationKey=946e2523-13fe-4436-8864-b3406a882735;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=2199a744-b898-4113-8b74-ed4526cbdb9e',
  },
  demo: {
    connectionString:
      'InstrumentationKey=a3b6f446-157f-490e-be15-fb72d290dfb7;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=bf9b0391-0f76-4057-a523-dd088c2af036',
  },
  uat: {
    connectionString:
      'InstrumentationKey=66002aa6-7b4c-476c-a719-637594e8b13d;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=99ed4a54-b214-4cdd-b06a-63fe4c96c9fc',
  },
  dev: {
    connectionString:
      'InstrumentationKey=6c9ddf78-b513-427c-afcc-441361ee8cfc;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=d0cb26be-a8ef-453f-acc6-4e1c5e213bdd',
  },
};
