// import './BenefitsWizardAccident.css';

import React, { useState } from 'react';

//Assets

//Services

//MuiComponents
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//Mui icons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';

//Our Components
import AccidentSpouseInputs from './accident-components/AccidentSpouseInputs';
import AccidentDependentsInputs from './accident-components/AccidentDependentsInputs';
import AccidentFamilyInputs from './accident-components/AccidentFamilyInputs';
import ExpandAndCollapseIcon from '../../enrollment-custom-components/ExpandAndCollapseIcon';
import CurrentAcLineChipDisplay from '../CurrentAcLineChipDisplay';
// import AssistanceFooter from '../assistance-footer/AssistanceFooter';
// import SaveChangesSnackbar from '../../../../save-changes-snackbar/SaveChangesSnackbar'
// import UmpMobileStepper from '../../../mobile-stepper/UmpMobileStepper';
// import BenefitsWizardBeneficiary from '../benefits-wizard-beneficiary/BenefitsWizardBeneficiary';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../enrollmentPlanStyles';

export default function EnrollmentADD(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let results = props.results;
  let planInfo = results?.addPlan;
  let options = planInfo?.options;
  let helpText = planInfo?.helpText;

  const componentStyles = {};

  const [spouseOpacity, setSpouseOpacity] = useState(
    props.recommendations.ShowSpouse ? 1 : 0.6
  );
  const [dependentOpacity, setDependentOpacity] = useState(
    props.recommendations.ShowDependent ? 1 : 0.6
  );
  const [familyOpacity, setFamilyOpacity] = useState(
    props.recommendations.ShowSpouse && props.recommendations.ShowDependent
      ? 1
      : 0.6
  );

  const [expanded, setExpanded] = useState(false);

  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  //    const checkExpanded = () => {
  //     let thisPlanId = planInfo.planId
  //     let planInExpandArray = props.expanded.find(expandObj => Object.keys(expandObj)[0] == thisPlanId)
  //     if (planInExpandArray) {
  //       let bool = planInExpandArray[thisPlanId]
  //       setExpanded(bool)
  //     }
  //   }
  //   useEffect(() => {
  //     if (props.expanded) checkExpanded()
  //   },[props.expanded])

  const [currRecc, setCurrRec] = useState(0);

  return (
    <>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <Typography
          sx={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          AD&D
        </Typography>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        {/* Current AC Line - display only to agent side if member already has this line type of active coverage */}
        {props?.agentEnroller &&
          props?.matchingACPlanType &&
          props?.matchingACPlanType?.length > 0 && (
            <CurrentAcLineChipDisplay plan={props.matchingACPlanType} />
          )}
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: helpText }}
          />
        </Box>

        <RadioGroup
          onChange={props.selectionHandler}
          value={props.selections.add || currRecc}
        >
          <>
            {options &&
              options
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((option) => {
                  let memberIsSelected =
                    props.selections.add == option.memberOptionId;
                  let memberAndSpouseIsSelected =
                    props.selections.add == option.memberAndSpouseOptionId;
                  let memberAndDependentsIsSelected =
                    props.selections.add == option.memberAndDependentsOptionId;
                  let familyIsSelected =
                    props.selections.add == option.familyOptionId;
                  let onlyFamilyAndMemberAvailable =
                    !option.memberAndDependentsAreEnabled &&
                    !option.memberAndSpouseOptionsAreEnabled &&
                    option.familyOptionsAreEnabled &&
                    option.memberOptionsAreEnabled;

                  if (!option.isDecline) {
                    return (
                      <React.Fragment key={option.memberOptionId}>
                        {/* Title/Benefit Amount */}
                        <Box sx={{ marginTop: '15px' }}>
                          <Typography variant="h6" sx={{ color: '#195FFB' }}>
                            {option?.name && (
                              <>
                                {option.name}{' '}
                                {option.name.includes('%') &&
                                  option?.benefitAmount &&
                                  `(Max ${UsdFormatterDec.format(
                                    option.benefitAmount
                                  )})`}
                              </>
                            )}
                          </Typography>

                          <Divider sx={{ color: '#0000001F' }} />
                        </Box>

                        {/* MEMBER */}
                        {planInfo.memberOptionsAreEnabled &&
                          option.memberOptionId > 0 && (
                            <Box sx={enrollmentPlanStyles.optionRow}>
                              <Box
                                sx={
                                  enrollmentPlanStyles.optionRadioAndTextContainer
                                }
                              >
                                <Radio
                                  sx={
                                    enrollmentPlanStyles.planSelectRadioButton
                                  }
                                  value={option.memberOptionId}
                                  id={`${option.memberOptionId}`}
                                  name="add"
                                  icon={
                                    <RadioButtonIcon
                                      sx={enrollmentPlanStyles.radioButtonIcon(
                                        false
                                      )}
                                    />
                                  }
                                  checkedIcon={
                                    <RadioButtonCheckedIcon
                                      sx={enrollmentPlanStyles.radioButtonIcon(
                                        false
                                      )}
                                    />
                                  }
                                  onClick={() => {
                                    props.handleEnrollClick(
                                      option.memberOptionId
                                    );
                                  }}
                                  inputProps={{
                                    'data-amount': option.memberPremiumAmount,
                                  }}
                                />

                                <Box
                                  sx={
                                    enrollmentPlanStyles.singleOptionTextContainer
                                  }
                                >
                                  <Typography
                                    sx={enrollmentPlanStyles.singleOptionText(
                                      memberIsSelected
                                    )}
                                  >
                                    Member
                                  </Typography>
                                  {/* {props.selectionRec(stdPlan.options, index) && (
                                    <img
                                    alt="check"
                                    style={{
                                       marginLeft: "5px",
                                       position: "relative",
                                       top: "-2px",
                                    }}
                                    src={CustomCheckIcon}
                                    />
                                 )} */}
                                </Box>
                              </Box>

                              {/* premium amount */}
                              <Box
                                sx={enrollmentPlanStyles.singleOptionText(
                                  memberIsSelected
                                )}
                              >
                                {memberIsSelected && '+'}
                                {UsdFormatterDec.format(
                                  option.memberPremiumAmount
                                )}
                              </Box>
                            </Box>
                          )}
                        {/* MEMBER AND SPOUSE */}
                        {planInfo.memberAndSpouseOptionsAreEnabled &&
                          option.memberAndSpouseOptionId > 0 && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionAndRequiredInputsContainer
                              }
                            >
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box
                                  sx={
                                    enrollmentPlanStyles.optionRadioAndTextContainer
                                  }
                                >
                                  <Radio
                                    sx={
                                      enrollmentPlanStyles.planSelectRadioButton
                                    }
                                    value={option.memberAndSpouseOptionId}
                                    id={`${option.memberAndSpouseOptionId}`}
                                    name="add"
                                    icon={
                                      <RadioButtonIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    onClick={() => {
                                      props.handleEnrollClick(
                                        option.memberAndSpouseOptionId
                                      );
                                    }}
                                    inputProps={{
                                      'data-amount':
                                        option.memberAndSpousePremiumAmount,
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      ...enrollmentPlanStyles.singleOptionTextContainer,
                                      opacity: memberAndSpouseIsSelected
                                        ? 1
                                        : spouseOpacity,
                                    }}
                                  >
                                    <Typography
                                      sx={enrollmentPlanStyles.singleOptionText(
                                        memberAndSpouseIsSelected
                                      )}
                                    >
                                      Member and spouse
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* premium amount */}
                                <Box
                                  sx={enrollmentPlanStyles.singleOptionText(
                                    memberAndSpouseIsSelected
                                  )}
                                >
                                  {memberAndSpouseIsSelected && '+'}
                                  {UsdFormatterDec.format(
                                    option.memberAndSpousePremiumAmount
                                  )}
                                </Box>
                              </Box>

                              {/* spouse inputs */}
                              {memberAndSpouseIsSelected && (
                                <AccidentSpouseInputs
                                  spouse={props.spouse}
                                  setSpouse={props.setSpouse}
                                  genderTypes={props?.genderTypes}
                                />
                              )}
                            </Box>
                          )}

                        {/* MEMBER AND DEPENDENTS  */}
                        {planInfo.memberAndDependentsAreEnabled &&
                          option.memberAndDependentsOptionId > 0 && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionAndRequiredInputsContainer
                              }
                            >
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box
                                  sx={
                                    enrollmentPlanStyles.optionRadioAndTextContainer
                                  }
                                >
                                  <Radio
                                    sx={
                                      enrollmentPlanStyles.planSelectRadioButton
                                    }
                                    value={option.memberAndDependentsOptionId}
                                    id={`${option.memberAndDependentsOptionId}`}
                                    name="add"
                                    icon={
                                      <RadioButtonIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    onClick={() => {
                                      props.handleEnrollClick(
                                        option.memberAndDependentsOptionId
                                      );
                                    }}
                                    inputProps={{
                                      'data-amount':
                                        option.memberAndDependentsPremiumAmount,
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      ...enrollmentPlanStyles.singleOptionTextContainer,
                                      opacity: memberAndDependentsIsSelected
                                        ? 1
                                        : dependentOpacity,
                                    }}
                                  >
                                    <Typography
                                      sx={enrollmentPlanStyles.singleOptionText(
                                        memberAndDependentsIsSelected
                                      )}
                                    >
                                      Member and dependents
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* premium amount */}
                                <Box
                                  sx={enrollmentPlanStyles.singleOptionText(
                                    memberAndDependentsIsSelected
                                  )}
                                >
                                  {memberAndDependentsIsSelected && '+'}
                                  {UsdFormatterDec.format(
                                    option.memberAndDependentsPremiumAmount
                                  )}
                                </Box>
                              </Box>

                              {/* dependent inputs */}
                              {memberAndDependentsIsSelected && (
                                <AccidentDependentsInputs
                                  dependents={props.dependents}
                                  setDependents={props.setDependents}
                                  genderTypes={props?.genderTypes}
                                />
                              )}
                            </Box>
                          )}

                        {/* FAMILY */}
                        {planInfo.familyOptionsAreEnabled &&
                          option.familyOptionId > 0 && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionAndRequiredInputsContainer
                              }
                            >
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box
                                  sx={
                                    enrollmentPlanStyles.optionRadioAndTextContainer
                                  }
                                >
                                  <Radio
                                    sx={
                                      enrollmentPlanStyles.planSelectRadioButton
                                    }
                                    value={option.familyOptionId}
                                    id={`${option.familyOptionId}`}
                                    name="add"
                                    icon={
                                      <RadioButtonIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    onClick={() => {
                                      props.handleEnrollClick(
                                        option.familyOptionId
                                      );
                                    }}
                                    inputProps={{
                                      'data-amount': option.familyPremiumAmount,
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      ...enrollmentPlanStyles.singleOptionTextContainer,
                                      opacity: familyIsSelected
                                        ? 1
                                        : familyOpacity,
                                    }}
                                  >
                                    <Typography
                                      sx={enrollmentPlanStyles.singleOptionText(
                                        familyIsSelected
                                      )}
                                    >
                                      Member and family
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* premium amount */}
                                <Box
                                  sx={enrollmentPlanStyles.singleOptionText(
                                    familyIsSelected
                                  )}
                                >
                                  {familyIsSelected && '+'}
                                  {UsdFormatterDec.format(
                                    option.familyPremiumAmount
                                  )}
                                </Box>
                              </Box>

                              {/* family inputs */}
                              {familyIsSelected && (
                                <AccidentFamilyInputs
                                  spouse={props.spouse}
                                  setSpouse={props.setSpouse}
                                  dependents={props.dependents}
                                  setDependents={props.setDependents}
                                  genderTypes={props?.genderTypes}
                                />
                              )}
                            </Box>
                          )}
                      </React.Fragment>
                    );
                  } else {
                    // DECLINE
                    return (
                      <React.Fragment key={option.memberOptionId}>
                        <Box style={{ marginTop: '15px' }}>
                          <Typography variant="h6" sx={{ color: '#195FFB' }}>
                            {option.name}
                          </Typography>
                          <Divider sx={{ color: '#0000001F' }} />
                        </Box>
                        <Box
                          key={option.memberPremiumAmount}
                          sx={enrollmentPlanStyles.declineOptionRow}
                        >
                          <Radio
                            sx={enrollmentPlanStyles.planSelectRadioButton}
                            value={option.memberOptionId}
                            name="add"
                            icon={
                              <RadioButtonIcon
                                sx={enrollmentPlanStyles.radioButtonIcon(false)}
                              />
                            }
                            checkedIcon={
                              <RadioButtonCheckedIcon
                                sx={enrollmentPlanStyles.radioButtonIcon(false)}
                              />
                            }
                            onClick={() => {
                              props.handleEnrollClick(option.memberOptionId);
                            }}
                            inputProps={{
                              'data-amount': option.memberPremiumAmount,
                            }}
                          />

                          <Box
                            sx={enrollmentPlanStyles.declineOptionText(
                              memberIsSelected
                            )}
                          >
                            Not Now
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  }
                })}
          </>
        </RadioGroup>
      </Collapse>

      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.add && props.selections.addVal > 0 && (
            <>
              <Box>
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtionIconCollapse}
                />
                {planInfo.options.map((option) => {
                  if (props.selections.add == option.memberOptionId)
                    return 'Member';
                  else if (
                    props.selections.add == option.memberAndSpouseOptionId
                  )
                    return 'Member and Spouse';
                  else if (
                    props.selections.add == option.memberAndDependentsOptionId
                  )
                    return 'Member and Dependents';
                  else if (props.selections.add == option.familyOptionId)
                    return 'Family';
                })}
              </Box>

              <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.addVal)}
              </Box>
            </>
          )}
          {props.selections.add && props.selections.addVal == 0 && (
            <>
              <Typography>
                <em>not elected</em>
              </Typography>
              <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                $0.00
              </Typography>
            </>
          )}
          {!props.selections.add && 'No plan selected'}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
