import React, { useState } from 'react';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//MuiComponents
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Box,
} from '@mui/material';

//Our styles
import enrollmentPlanStyles from '../../enrollmentPlanStyles';

//utils

export default function AccidentSpouseInputs(props) {
  let results = props.results;

  //DatePicker DOB change
  const handleMobileDobChange = (e, i, key) => {
    let value = e; //date object format from datepicker by default
    props.setSpouse({
      ...props.spouse,
      dateOfBirth: value,
    });
  };

  // const handleDobFocus = (e) => {
  //   e.target.setAttribute("type", "date");
  // };
  // const handleDobBlur = (e, value) => {
  //   if (!props.spouse.dateOfBirth) e.target.setAttribute("type", "");
  //   // console.log("HANDLE DOB BLUR IN ACCIDENT SPOUSE DOB e and value", e, value)
  //   checkError(e, value);
  // };

  const [addContact, setAddContact] = useState(false);

  //  Error Messaging

  const [fNameError, setFNameError] = useState(false);
  const [lNameError, setLNameError] = useState(false);
  const [dobError, setDobError] = useState(false);

  const checkError = (e, value) => {
    if (value == 'firstName') {
      if (props.spouse.firstName == '') {
        setFNameError(true);
      } else {
        setFNameError(false);
      }
    }

    if (value == 'lastName') {
      if (props.spouse.lastName == '') {
        setLNameError(true);
      } else {
        setLNameError(false);
      }
    }

    // To Do: Set error messaging for bad birth date value
    if (value == 'dob') {
      let birthYearString = e.target.value?.slice(0, 4);
      let birthYearNum = Number(birthYearString);
      if (props.spouse.dateOfBirth == '') {
        setDobError(true);
      } else {
        setDobError(false);
      }
    }
  };

  return (
    <>
      <Box sx={enrollmentPlanStyles.inputSection}>
        <Box sx={enrollmentPlanStyles.inputField}>
          <TextField
            fullWidth
            error={fNameError}
            onBlur={(e) => checkError(e, 'firstName')}
            label="Spouse First Name"
            variant="outlined"
            required={true}
            value={props.spouse?.firstName || ''}
            onChange={(e) =>
              props.setSpouse({ ...props.spouse, firstName: e.target.value })
            }
            InputLabelProps={enrollmentPlanStyles.inputLabelProps}
          />
        </Box>
        <Box sx={enrollmentPlanStyles.inputField}>
          <TextField
            fullWidth
            required={true}
            error={lNameError}
            onBlur={(e) => checkError(e, 'lastName')}
            label="Spouse Last Name"
            variant="outlined"
            value={props.spouse?.lastName || ''}
            onChange={(e) =>
              props.setSpouse({ ...props.spouse, lastName: e.target.value })
            }
            InputLabelProps={enrollmentPlanStyles.inputLabelProps}
          />
        </Box>

        {/* Spouse Gender */}
        <Box sx={enrollmentPlanStyles.inputField}>
          <FormControl fullWidth>
            <InputLabel sx={enrollmentPlanStyles.inputLabelProps.sx}>
              Spouse Gender
            </InputLabel>
            <Select
              variant="outlined"
              label="Spouse Gender"
              value={props?.spouse?.gender ? props.spouse.gender : ''}
              onChange={(e) =>
                props?.setSpouse({
                  ...props.spouse,
                  gender: e.target.value,
                })
              }
            >
              {props?.genderTypes?.length > 0 &&
                props?.genderTypes.map((option) => {
                  return (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>

        {/* Spouse DOB */}
        <Box sx={enrollmentPlanStyles.inputField}>
          <LocalizationProvider required={false} dateAdapter={AdapterDateFns}>
            <DatePicker
              // onBlur={(e) => checkError("dob")}
              disableFuture
              openTo="year"
              views={['year', 'month', 'day']}
              value={props.spouse.dateOfBirth || null}
              onChange={handleMobileDobChange}
              format="MM/dd/yyyy"
              label="Date of birth"
              onAccept={(e) => checkError('dob')}
              // onError={(error) => setDobError(error)} //will need to use this in the future and connect to error messaging
              sx={enrollmentPlanStyles.datePickerInput}
              slotProps={{
                textField: {
                  // helperText: dobErrorMessage,
                  InputLabelProps: enrollmentPlanStyles.inputLabelProps,
                },
                openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
              }}
            />
          </LocalizationProvider>
        </Box>

        {/* <div
          className="flex-row"
          style={{
            textAlign: "left",
            fontWeight: 500,
            fontSize: "15px",
            color: "#646464",
            lineHeight: "25px",
            marginBottom: "11px",
          }}
        >
          <span>
            Can your spouse make changes to your account on your behalf?
          </span>
        </div>

        <div className='flex-row' style={{marginBottom:'20px'}}>
            <div className='col-6' 
            onClick={(e) => props.setSpouse({...props.spouse,coverageAuthorize:true})}
            >
              <Button variant='contained' className='enroll-survey-btn' 
              disabled={props.spouse.coverageAuthorize == false || props.spouse.coverageAuthorize == null}
              sx={{ borderRadius:'100px', 
              width:"95%!important",
              height:"50px"}}
              >
                  YES
              </Button>
            </div>
            <div className='col-6' style={{textAlign:'end'}} 
            onClick={(e) => {
              props.setSpouse({...props.spouse,coverageAuthorize:false,email:''})
              setAddContact(false)
            }}
            >
              <Button variant='contained' className='enroll-survey-btn'
              disabled={props.spouse.coverageAuthorize === true || props.spouse.coverageAuthorize == null}
              sx={{ borderRadius:'100px', 
              width:"95%!important",
              height:"50px"}}
              >
                  NO
              </Button>
            </div>
        </div> */}
      </Box>
      {!addContact && (
        <Box sx={enrollmentPlanStyles.addAdditionalButtonContainer}>
          <Button
            onClick={(e) => setAddContact(true)}
            variant="text"
            sx={enrollmentPlanStyles.addAdditionalButton}
          >
            + add spouse contact information
          </Button>
        </Box>
      )}

      {addContact && (
        <Box sx={enrollmentPlanStyles.inputField}>
          <TextField
            fullWidth
            label="Spouse Email"
            variant="outlined"
            value={props.spouse?.email || ''}
            onChange={(e) =>
              props.setSpouse({ ...props.spouse, email: e.target.value })
            }
          />
        </Box>
      )}
    </>
  );
}
