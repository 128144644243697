import React, { useContext, useEffect, useRef, useState } from 'react';

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import { Chip, Divider, Typography } from '@mui/material';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';

// Methods
import attachmentService from '../../../../../../services/attachment-service';

export default function DocumentDrawer(props) {
  //Drawer Logic
  const handleDrawerClose = (e) => {
    props.setDocumentDrawerOpen(false);
  };

  const [customMessage, setCustomMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = async () => {
    let selectedDocument = props.allDocuments?.length
      ? props?.selectedDocument
      : props.selectedDocument?.uniqueId;
    try {
      setLoading(true);
      let sendEmail = await attachmentService.emailAttachmentToMember(
        props?.member?.id,
        customMessage,
        selectedDocument
      );
      props.setMessage({
        ...props.message,
        messageDisplay: true,
        autoHideDuration: '1600',
        severity: 'success',
        backgroundColor: '',
        message: 'Successfully Sent Email',
      });
      setCustomMessage('');
      props.setDocumentDrawerOpen(false);
    } catch (err) {
      console.error(err);
      props.setMessage({
        ...props.message,
        messageDisplay: true,
        autoHideDuration: '2400',
        severity: 'error',
        backgroundColor: '',
        message: `${err}`,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { marginTop: '56px!important' },
        }}
        hideBackdrop={true}
        anchor="right"
        open={props.documentDrawerOpen}
        onClose={handleDrawerClose}
      >
        <div style={{ width: '560px', padding: '19px 38px' }}>
          <div className="flex-row">
            <div
              className="col-6"
              style={{ fontFamily: 'Poppins', fontWeight: 600 }}
            >
              <Typography variant="body1" fontFamily="Poppins" fontWeight="600">
                Send Document via Email
              </Typography>
            </div>

            <div
              className="col-6"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              role="button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </div>
          </div>
          <Divider
            style={{ margin: '17px -38px 30px', opacity: 1, height: '2px' }}
            sx={{ color: 'primary.main' }}
          />

          <TextField
            fullWidth
            variant="outlined"
            label="Send To"
            InputProps={{
              startAdornment: (
                <Chip
                  tabIndex={-1}
                  label={`${props?.member?.firstName} ${props?.member?.lastName} <${props.member?.email}>`}
                />
              ),
            }}
            sx={{ marginBottom: '20px' }}
            value=""
            disabled={true}
          />

          <TextField
            fullWidth
            variant="outlined"
            label="From"
            InputProps={{
              startAdornment: (
                <Chip
                  tabIndex={-1}
                  label={`${props?.thisUser?.firstName} ${props?.thisUser?.lastName} <enrolled@unionhub.com>`}
                />
              ),
            }}
            sx={{ marginBottom: '20px' }}
            value=""
            disabled={true}
          />

          <TextField
            fullWidth
            variant="outlined"
            label="Attachment"
            InputProps={{
              startAdornment: (
                <Chip
                  tabIndex={-1}
                  label={
                    props.allDocuments
                      ? props.allDocuments.filter(
                          (document) =>
                            document.uniqueId == props.selectedDocument
                        )[0]?.filename
                      : props.selectedDocument?.filename
                  }
                />
              ),
            }}
            sx={{ marginBottom: '20px' }}
            value=""
            disabled={true}
          />

          <TextField
            multiline
            fullWidth
            minRows={5}
            label="Message"
            variant="outlined"
            placeholder="Provide a custom message along with your attachment."
            InputProps={{ disableUnderline: true }}
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            sx={{
              marginBottom: '40px',
            }}
          />

          <div
            className="flex-row"
            style={{ justifyContent: 'center', marginBottom: '50px' }}
          >
            <Button
              variant="contained"
              disableRipple
              sx={{
                height: 40,
                minWidth: 200,
                borderRadius: 100,
                width: '152px',
                fontWeight: '500!important',
              }}
              onClick={sendEmail}
              disabled={loading}
            >
              Send Email
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
