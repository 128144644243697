import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  format,
  formatDistance,
  subMinutes,
  addDays,
  addYears,
} from 'date-fns';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//services
import ticketService from '../../../../../../services/ticket-service';
import optionService from '../../../../../../services/optionService';
import userService from '../../../../../../services/user-service';

//Mui Components
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

// Assets
import openSvg from '../../../../../../assets/images/openSvg.svg';
import closedSvg from '../../../../../../assets/images/closedSvg.svg';

//Mui icons
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SyncIcon from '@mui/icons-material/Sync';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//Our Components
import OurDataGrid from '../../../../DataGrid/OurDataGrid';
import { thisUserContext } from '../../../../nav-container/NavContainer';
import CreateTicketDrawer from './CreateTicketDrawer';
import { useGridApiRef } from '@mui/x-data-grid';
import TicketPriorityChips from '../../../DisplayChips/TicketPriorityChips';

//utils
import {
  formatUTCDateToObjWithTimeZone,
  formatDateObjMMDDYYYYDisplay,
  formatDateObjYYYYMMDD,
  titleCase,
} from '../../../../../utils';

export default function MemberTicketsTab(props) {
  let history = useHistory();

  const apiRef = useGridApiRef();

  const [tickets, setTickets] = useState([]);
  const [origins, setOrigins] = useState(null);
  const [types, setTypes] = useState(null);
  const [priorities, setPriorities] = useState(null);
  const [SLAs, setSLAs] = useState(null);
  const [selectedList, setSelectedList] = useState('All Tickets');
  const [account, setAccount] = useState(null);
  const [thisUser, setThisUser] = useState();
  const [checks, setChecks] = useState({
    open: true,
    closed: false,
    system: false,
  });

  useEffect(() => {
    buildRows();
    getGridCols();
  }, [checks]);

  // STATE GETTERS AND CALLING EFFECTS
  let _thisUser = useContext(thisUserContext);
  const [allUsers, setAllUsers] = useState([]);
  let getThisUser = async () => {
    setThisUser(_thisUser.thisUser);
    setAllUsers(_thisUser.allUsers);
  };
  useEffect(() => {
    if (_thisUser) getThisUser();
  }, [_thisUser]);

  const [loadingState, setLoadingState] = useState(false);
  const refreshTickets = async () => {
    setLoadingState(true);
    let tickets = await ticketService.getAllTickets();

    switch (selectedList) {
      case 'All Tickets':
        setTickets(
          tickets.filter((ticket) => ticket?.memberId == props.member?.id)
        );
        break;
    }
    setLoadingState(false);
  };
  const getTickets = async () => {
    let tickets = await ticketService.getTicketsByMemberId(props.member?.id);
    setTickets(tickets);

    if (!origins) {
      let origins = await optionService.getTicketOrigins();
      setOrigins(origins);
    }
    if (!types) {
      let types = await optionService.getTicketTypes();
      setTypes(types);
    }
    if (!priorities) {
      let priorities = await optionService.getTicketPriority();
      setPriorities(priorities);
    }
    if (!priorities) {
      let SLAs = await optionService.getTicketSLA();
      setSLAs(SLAs);
    }
  };

  useEffect(() => {
    getThisUser();
  }, []);

  useEffect(() => {
    getTickets();
  }, [thisUser, selectedList, props.member]);

  useEffect(() => {
    if (origins && types && priorities && SLAs && tickets) {
      buildRows();
    }
  }, [origins, types, priorities, SLAs, tickets]);

  //NEW TICKET DRAWER
  const [ticketDrawerOpen, setTicketDrawerOpen] = useState(false);

  // Event Handlers
  const handleChangeSelectedList = (event) => {
    setSelectedList(event?.target?.value);
  };

  const handleCheckBox = (event) => {
    let key = event.target.value;
    let value = event.target.checked;
    setChecks({ ...checks, [key]: value });
  };

  const getStatusIcon = (statusId) => {
    switch (statusId) {
      case 1:
        let openStatus = {
          value: 'open',
          image: openSvg,
        };
        return openStatus;
        break;
      case 2:
        let closedStatus = {
          value: 'closed',
          image: closedSvg,
        };
        return closedStatus;
        break;
      default:
        console.log('no status found');
    }
  };

  let dateMinObj = new Date();
  let dateMaxObj = addYears(new Date(), 1);

  const handleDueDateChange = async (newDate, params) => {
    //format new date that is in date obj format to short date string for db
    newDate = formatDateObjYYYYMMDD(newDate);
    let ticket = tickets.find((ticket) => ticket.id == params.id);
    setLoadingState(true);
    ticket.dueDate = newDate;
    try {
      await ticketService.updateTicket(ticket);
    } catch (error) {
      console.error('error updating due date', error);
    }
    refreshTickets();
    let outerClick = document.getElementById('member-tickets-grid');
    if (outerClick) outerClick.click();
    // if ((newDate >= dateMinObj) && (newDate <= dateMaxObj)){
    //    setCreatedTicket({
    //       ...createdTicket,
    //       dueDate:newDate
    //    })
    // }
  };

  // This state object determines which columns are hidden on page load.  The default setting if the user has no past interactions is below.  The name in the grid col MUST match the "Field" property within the row definitions in each individual grid.
  const [gridCols, setGridCols] = useState({});

  // Returns all filters from local storage or builds new
  const getGridCols = () => {
    let _gridCols = localStorage.getItem('memberTicketGridCols');
    if (_gridCols) {
      _gridCols = JSON.parse(_gridCols);
      setGridCols(_gridCols);
    } else {
      setGridCols({
        id: false,
        status: true,
        organization: true,
        uniqueId: true,
        requester: true,
        subject: true,
        assignee: true,
        lastUpdated: true,
        origin: true,
        priority: true,
        dueDate: true,
        ticketTypeId: true,
      });
    }
  };

  // Updates local storage with current settings
  const saveGridCols = () => {
    localStorage.setItem('memberTicketGridCols', JSON.stringify(gridCols));
    getGridCols();
  };

  useEffect(() => {
    if (Object.keys(gridCols).length != 0) {
      saveGridCols();
    }
  }, [JSON.stringify(gridCols)]);

  //DATAGRID STUFF
  const [rows, setRows] = useState([]);
  const buildRows = () => {
    let _rows = [];

    let filterCheck = [];
    for (let [key, val] of Object.entries(checks)) {
      if (val === true) filterCheck.push(key);
    }

    tickets.forEach((ticket) => {
      if (filterCheck.length > 0) {
        if (filterCheck.includes(getStatusIcon(ticket.ticketStatusId)?.value)) {
          let builtRow = {
            id: ticket.id,
            status: getStatusIcon(ticket.ticketStatusId)?.value,
            uniqueId: ticket.uniqueId,
            requester: ticket.memberDisplayName,
            subject: ticket.subject,
            assignee: ticket.userId,
            lastUpdated: ticket.lastModifiedDate,
            origin: origins.find((origin) => origin.id == ticket.ticketOriginId)
              ?.name,
            priority: ticket.ticketPriorityId,
            dueDate: ticket.dueDate
              ? formatUTCDateToObjWithTimeZone(ticket.dueDate)
              : null, //convert to date obj
          };
          _rows.push(builtRow);
        }
      } else {
        let builtRow = {
          id: ticket.id,
          status: getStatusIcon(ticket.ticketStatusId)?.value,
          uniqueId: ticket.uniqueId,
          requester: ticket.memberDisplayName,
          subject: ticket.subject,
          assignee: ticket.userId,
          lastUpdated: ticket.lastModifiedDate,
          origin: origins.find((origin) => origin.id == ticket.ticketOriginId)
            ?.name,
          priority: ticket.ticketPriorityId,
          dueDate: ticket.dueDate
            ? formatUTCDateToObjWithTimeZone(ticket.dueDate)
            : null, //convert to date obj
        };
        _rows.push(builtRow);
      }
    });
    setRows(_rows);
  };

  //CUSTOM DATE RANGE OPERATOR
  function DateRangeInput(props) {
    const { item, applyValue, focusElementRef = null } = props;

    const filterTimeout = useRef();
    const [filterValueState, setFilterValueState] = useState(item.value ?? '');

    const [applying, setIsApplying] = useState(false);

    useEffect(() => {
      return () => {
        clearTimeout(filterTimeout.current);
      };
    }, []);

    useEffect(() => {
      const itemValue = item.value ?? [undefined, undefined];
      setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (lowerBound, upperBound) => {
      clearTimeout(filterTimeout.current);
      setFilterValueState([lowerBound, upperBound]);

      setIsApplying(true);
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false);
        applyValue({ ...item, value: [lowerBound, upperBound] });
      }, 500);
    };

    const handleUpperFilterChange = (event) => {
      const newUpperBound = event.target.value;
      updateFilterValue(filterValueState[0], newUpperBound);
    };
    const handleLowerFilterChange = (event) => {
      const newLowerBound = event.target.value;
      updateFilterValue(newLowerBound, filterValueState[1]);
    };

    return (
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'end',
          height: 48,
          pl: '4px',
        }}
      >
        <style>
          {`
               .MuiDataGrid-filterFormValueInput {
                  width:220px;
               }
               `}
        </style>
        <TextField
          name="lower-bound-input"
          placeholder="From"
          label="From"
          variant="standard"
          value={filterValueState[0]}
          onChange={handleLowerFilterChange}
          type="date"
          inputRef={focusElementRef}
          sx={{ mr: '8px' }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="upper-bound-input"
          placeholder="To"
          label="To"
          variant="standard"
          value={filterValueState[1]}
          onChange={handleUpperFilterChange}
          type="date"
          InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    );
  }

  const quantityOnlyOperators = [
    {
      label: 'Between',
      value: 'between',
      getApplyFilterFn: (filterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            new Date(filterItem.value[0]) <= new Date(value) &&
            new Date(value) <= addDays(new Date(filterItem.value[1]), 1)
          );
        };
      },
      InputComponent: DateRangeInput,
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: '',
      hide: true,
    },
    {
      field: 'importance',
      headerName: '',
      width: 60,
      align: 'center',
      headerClassName: 'hide-right-sep hide-left-sep',
      renderCell: (params) => {
        // return <div style={{ backgroundColor: '#F0F0F0', borderRadius: '100px', fontWeight: 700, fontSize: 10, textAlign: 'center', fontFamily: 'Archivo', padding: '2px 13px' }}>1</div>
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: ' hide-right-sep hide-left-sep',
      renderCell: (params) => (
        <>
          <Box
            sx={{
              height: 24,
              width: 24,
              backgroundColor:
                params.formattedValue === 2 ? 'text.secondary' : 'primary.main',
              color: 'white',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '16px',
            }}
          >
            <Typography textAlign="center" variant="body1" fontWeight="600">
              {params.formattedValue === 2 ? 'C' : 'O'}
            </Typography>
          </Box>
          <Typography variant="overline">
            {params.formattedValue === 2 ? 'Closed' : 'Open'}
          </Typography>
        </>
      ),
      valueFormatter: ({ value }) => {
        switch (value) {
          case 'open':
            return 1;
            break;
          case 'closed':
            return 2;
            break;
        }
      },
      type: 'string',
      width: 150,
      // align:'center',
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 200,
      valueFormatter: ({ value }) => {
        return `${value}`;
      },
      renderCell: ({ value }) => (
        <>
          <Typography variant="text.primary">{value}</Typography>
        </>
      ),
      valueFormatter: ({ value }) => {
        return `${value}`;
      },
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      headerClassName: 'hide-right-sep hide-left-sep',
      renderCell: (params) =>
        `${formatDistance(new Date(params.value), new Date())} ago`,
      type: 'date',
      width: 170,
    },
    {
      field: 'origin',
      headerName: 'Origin',
      headerClassName: 'hide-right-sep hide-left-sep',
      width: 120,
      valueFormatter: ({ value }) => {
        return `${value}`;
      },
      renderCell: ({ value }) => (
        <>
          <Typography variant="text.primary">{value}</Typography>
        </>
      ),
      valueFormatter: ({ value }) => {
        return `${value}`;
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
      headerClassName: 'hide-right-sep hide-left-sep',
      width: 120,
      valueGetter: (params) => {
        switch (params.value) {
          case 1:
            return 'Low';
            break;
          case 2:
            return 'Medium';
            break;
          case 3:
            return 'High';
            break;
        }
      },
      renderCell: ({ value }) => {
        return <TicketPriorityChips ticketPriority={value} />;
      },
      valueFormatter: ({ value }) => {
        return `${value}`;
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      headerClassName: 'hide-right-sep hide-left-sep',
      width: 170,
      type: 'date',
      filterOperators: quantityOnlyOperators,
      editable: true,
      renderCell: (params) => {
        return (
          <div data-field="dueDate">
            <span data-field="dueDate">
              {params.value ? formatDateObjMMDDYYYYDisplay(params.value) : ''}
            </span>
          </div>
        );
      },
      renderEditCell: (params) => {
        let topParams = params;
        return (
          <Box data-field="dueDate">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // disabled={sendTicketLoading}
                // disableFuture
                // openTo="year"
                maxDate={dateMaxObj}
                minDate={dateMinObj}
                views={['year', 'month', 'day']}
                value={params.row?.dueDate || null}
                onChange={(e) => handleDueDateChange(e, topParams)}
                format="MM/dd/yyyy"
                sx={{
                  width: '100%',
                  padding: '0px',
                  '& .MuiOutlinedInput-input': {
                    padding: '12px 12px',
                    fontSize: '15px', // Adjust the font size
                  },
                  '& fieldset': {
                    height: '50px',
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        );
      },
    },
    {
      field: 'uniqueId',
      headerName: 'Ticket ID',
      headerClassName: 'hide-right-sep hide-left-sep',
      valueGetter: (params) => {
        return `${params.row.id}`;
      },
      renderCell: (params) => {
        return `${params.row.id}`;
      },
      valueFormatter: (params) => params?.value,
      width: 100,
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      headerClassName: 'hide-right-sep hide-left-sep',
      width: 220,
      editable: true,
      valueGetter: (params) => {
        return params.row.assignee
          ? `${
              params.row.assignee &&
              allUsers &&
              allUsers?.find((user) => user.id == params.value)?.firstName
            } ${
              params.row.assignee &&
              allUsers &&
              allUsers?.find((user) => user.id == params.value)?.lastName
            }`
          : '';
      },
      renderCell: (params) => {
        apiRef.current = params.api;
        return (
          <div data-field="assignee">
            {params.row.assignee ? (
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Avatar
                  sx={{
                    bgcolor: 'primary.light',
                    color: 'primary.main',
                    fontFamily: 'Archivo',
                    fontWeight: '600',
                    width: 40,
                    height: 40,
                    fontSize: 16,
                  }}
                  src={`api/image/user/${params.row.assignee}`}
                >
                  <span>
                    {allUsers &&
                      `${allUsers
                        ?.find((user) => user.id == params.row.assignee)
                        ?.firstName[0].toUpperCase()}
                       ${allUsers
                         ?.find((user) => user.id == params.row.assignee)
                         ?.lastName[0].toUpperCase()}`}
                  </span>
                </Avatar>

                <span
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}
                >
                  <Typography variant="text.primary" fontWeight="400">
                    {titleCase(
                      allUsers &&
                        allUsers?.find((user) => user.id == params.row.assignee)
                          ?.firstName
                    )}{' '}
                    &nbsp;
                    {titleCase(
                      allUsers &&
                        allUsers?.find((user) => user.id == params.row.assignee)
                          ?.lastName
                    )}
                  </Typography>
                </span>
              </Stack>
            ) : (
              <>No User Assigned</>
            )}
          </div>
        );
      },
      renderEditCell: (params) => {
        let topParams = params;
        return (
          <>
            <Autocomplete
              disablePortal
              id="assignee-search"
              fullWidth
              loading={loading}
              options={allUsers}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              sx={{ padding: '5px' }}
              popupIcon={<KeyboardArrowDownIcon />}
              value={assigneeObj}
              onChange={(e, newValue) =>
                handleAssigneeChange(e, newValue, topParams)
              }
              renderInput={(params) => (
                <TextField {...params} label="Assignee" />
              )}
              getOptionLabel={(option) =>
                option.firstName ? `${option.firstName} ${option.lastName}` : ''
              }
              renderOption={(params, option) => {
                return (
                  <Stack
                    direction="row"
                    spacing={1}
                    {...params}
                    key={params['data-option-index']}
                  >
                    <Avatar
                      sx={{
                        bgcolor: '#195FFB17',
                        color: '#195FFB',
                        fontFamily: 'Archivo',
                        fontWeight: '600',
                        width: 28,
                        height: 28,
                        fontSize: 14,
                      }}
                      src={option?.id > 0 ? `api/image/user/${option.id}` : ''}
                    >
                      {`${params?.key[0].toUpperCase()}${params?.key[1].toUpperCase()}`}
                    </Avatar>
                    <span
                      style={{
                        fontFamily: 'Archivo',
                        paddingLeft: '10px',
                        margin: 'auto 0px',
                      }}
                    >
                      {params.key}
                    </span>
                  </Stack>
                );
              }}
            />
          </>
        );
      },
    },
  ];

  //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  function NoTicketRows(props) {
    return (
      <div style={{ backgroundColor: '', height: '100%', textAlign: 'center' }}>
        <Box
          sx={{ backgroundColor: 'primary.light' }}
          style={{
            margin: '54px auto 13px',
            width: '58px',
            height: '58px',
            borderRadius: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ManageSearchIcon
            sx={{ width: '28px', height: '28px', color: 'primary.main' }}
          />
        </Box>
        <div style={{ marginBottom: '19px' }}>
          No tickets currently assigned here
        </div>

        <div>
          {/* <Button variant='contained' disableRipple
                  sx={{height:40,minWidth:200,borderRadius:100,width:'152px',fontWeight:'500!important'}}
                  onClick={()=>setTicketDrawerOpen(true)}
                  // startIcon={<AddIcon/>}
                  >
                     Open New Ticket
                  </Button> */}
        </div>
      </div>
    );
  }

  //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  function NoTicketResults(props) {
    return (
      <div style={{ backgroundColor: '', height: '100%', textAlign: 'center' }}>
        <div
          style={{
            margin: '54px auto 13px',
            width: '58px',
            height: '58px',
            backgroundColor: '#195FFB12',
            borderRadius: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ManageSearchIcon
            sx={{ width: '28px', height: '28px', color: '#195FFB' }}
          />
        </div>
        <div style={{ marginBottom: '19px' }}>No tickets found</div>

        <div>
          {/* <Button variant='contained' disableRipple
                  sx={{height:40,minWidth:200,borderRadius:100,width:'152px',fontWeight:'500!important'}}
                  onClick={()=>setTicketDrawerOpen(true)}
                  // startIcon={<AddIcon/>}
                  >
                     Open New Ticket
                  </Button> */}
        </div>
      </div>
    );
  }

  const handleRowClick = (params, e) => {
    let clickedCell = e.target;
    let fieldName = clickedCell.getAttribute('data-field');
    if (fieldName == 'assignee' || fieldName == 'dueDate') {
    } else history.push(`/tickets/${params.row.id}`);
  };

  //Assignee AutoComplete Logic
  const [open, setOpen] = useState(false);
  const loading = open && allUsers.length === 0;
  const [assigneeObj, setAssigneeObj] = useState({});
  const handleAssigneeChange = async (e, newValue, params) => {
    let rowId = params.id;
    let ticket = tickets.find((ticket) => ticket.id == params.id);
    setLoadingState(true);
    if (newValue?.id) {
      setAssigneeObj(newValue);
      // ticket.userId = newValue.id
      await ticketService.assignTicket(ticket.id, newValue.id);

      // if(document.createEventObject) {
      //    var eventObj = document.createEventObject();
      //    eventObj.keyCode = 27;
      //    e.target.fireEvent("onkeydown", eventObj);
      // } else if(document.createEvent) {
      //    var eventObj = document.createEvent("Events");
      //    eventObj.initEvent("keydown", true, true);
      //    eventObj.keyCode = 27;
      //    eventObj.which = 27;
      //    e.target.dispatchEvent(eventObj);
      // }
      if (apiRef.current) {
        // apiRef.current.stopCellEditMode({id:rowId, field:'assignee'})
        // apiRef.current.commitCellChange({id:rowId, field:'assignee'})
      }
      refreshTickets();
      let outerClick = document.getElementById('member-tickets-grid');
      if (outerClick) outerClick.click();
    } else {
      setAssigneeObj({});
    }
  };

  const sendUpdatedTicket = async (params, e, ticket) => {
    let _ticket = ticket;
    if (!_ticket) _ticket = tickets.find((ticket) => ticket.id == params.id);
    // if (_ticket){
    //    await ticketService.updateTicket(_ticket)
    //    getTickets()

    // }
  };

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: 'and',
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  });

  return (
    <Box
      sx={{
        bgcolor: 'background.background',
        color: 'text.primary',
      }}
      style={{ padding: '20px', height: 'calc(100vh - 104px)' }}
    >
      <style>
        {`
            .popperOverride li, .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
            font-family:Poppins;
            font-size:.92rem;
            // color:red;
            }

            .hide-right-sep .MuiDataGrid-columnSeparator, .hide-left-sep .MuiDataGrid-columnSeparator {
               visibility:hidden
            }
            .center-grid-header .MuiDataGrid-columnHeaderTitleContainer{
               // color:red;
               display:flex;
               justify-content:center
            }
            .css-j204z7-MuiFormControlLabel-root {
               margin-left:50px;
               margin-right:20px;
            }
         `}
      </style>
      <div
        className="flex-row"
        style={{ fontFamily: 'Archivo', flexWrap: 'wrap' }}
      >
        {/* MAIN AREA */}
        <div className="col-12" style={{ backgroundColor: '#FFFFFF' }}>
          <Box
            sx={{
              bgcolor: 'background.background',
              color: 'text.primary',
            }}
            style={{ fontWeight: 500, paddingBottom: '15px' }}
          >
            <div className="flex-row">
              <div
                className="col"
                style={{ paddingLeft: '20px', justifyContent: 'flex-start' }}
              >
                <span
                  style={{
                    fontSize: '32px',
                    fontWeight: 500,
                    fontFamily: 'Poppins',
                  }}
                >
                  Tickets
                </span>
              </div>

              <div
                className="col"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: '50px',
                }}
              >
                <Button
                  variant="contained"
                  disableRipple
                  sx={{
                    height: 40,
                    minWidth: 200,
                    borderRadius: 100,
                    width: '152px',
                    fontWeight: '500!important',
                  }}
                  disabled={props?.profileDisabled}
                  onClick={() => setTicketDrawerOpen(true)}

                  // startIcon={<AddIcon/>}
                >
                  Open New Ticket
                </Button>
              </div>
            </div>
            <FormGroup row sx={{ paddingLeft: '20px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checks.open}
                    value="open"
                    onClick={handleCheckBox}
                  />
                }
                label="Open"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checks.closed}
                    value="closed"
                    onClick={handleCheckBox}
                  />
                }
                label="Closed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checks.system}
                    value="system"
                    onClick={handleCheckBox}
                  />
                }
                label="System Assigned"
              />
            </FormGroup>

            {/* NEW TICKET DRAWER */}
            <CreateTicketDrawer
              refreshTickets={refreshTickets}
              ticketDrawerOpen={ticketDrawerOpen}
              setTicketDrawerOpen={setTicketDrawerOpen}
              member={props.member}
              singleEnrollment={props.singleEnrollment}
              user={thisUser}
              origins={origins}
              types={types}
              priorities={priorities}
              SLAs={SLAs}
            />
          </Box>
        </div>
        <div
          id="member-tickets-grid"
          className="col-12"
          style={{ height: 'calc(100vh - 240px)' }}
        >
          <OurDataGrid
            manualHeight
            columnVisibilityModel={gridCols}
            setGridCols={setGridCols}
            rows={rows}
            columns={columns}
            handleRowClick={handleRowClick}
            manualStyle={{ borderRadius: '15px' }}
            noRowsComp={NoTicketRows}
            noResultsComp={NoTicketResults}
            loadingState={loadingState}
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            showSaveFilterModel={false}
            components={{
              NoRowsOverlay: NoTicketRows,
            }}
            exportBtn={true}
            apiRef={apiRef}
            dataSet={`Tickets_Member_${props?.member?.firstName}_${props?.member?.lastName}`}
          />
        </div>
      </div>
    </Box>
  );
}
