import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Legend,
  Tooltip,
  PolarAngleAxis,
  RadialBarChart,
  RadialBar,
} from 'recharts';

//Mui components
import { Card, CardHeader, Slide, Typography } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material/';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';

//Mui icons
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

//Services
import dashboardService from '../../../../services/dashboard-service';
import EnrollmentsByDate from './EnrollmentsByDateAndCoverage-reports/EnrollmentsByDate';
import EnrollmentsByCoverage from './EnrollmentsByDateAndCoverage-reports/EnrollmentsByCoverage';

//Our components
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';

//Our Styles
import reportingDashboardStyles from '../reportingDashboardStyles';

export default function EnrollmentsByDateAndCoverage(props) {
  const [loading, setLoading] = useState(false);
  const [dateResults, setDateResults] = useState([]);
  const [coverageResults, setCoverageResults] = useState([]);
  const [selectedEnrollmentWindowObj, setSelectedEnrollmentWindowObj] =
    useState({});
  const getReportResults = async () => {
    setLoading(true);
    try {
      let _dateResults = await dashboardService.getEnrolledByDate(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      if (_dateResults?.length > 0)
        _dateResults.sort((a, b) => (a.date > b.date ? 1 : -1));
      setDateResults(_dateResults);
    } catch (error) {
      console.error('error getting EnrollmentByDate', error);
    }
    try {
      let _coverageResults = await dashboardService.getPremiumsByType(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      setCoverageResults(_coverageResults);
    } catch (error) {
      console.error('error getting PremiumsByType', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.groupPlanId && props.enrollmentWindowId) {
      if (props.enrollmentWindows?.length > 0) {
        let validId = false;
        for (let window of props.enrollmentWindows) {
          if (window.id == props.enrollmentWindowId) {
            validId = true;
            break;
          }
        }
        if (validId) {
          getReportResults();
          //set current enrollment window to display in header
          let _selectedEnrollmentWindowObj = props?.enrollmentWindows?.find(
            (window) => window.id === props?.enrollmentWindowId
          );
          setSelectedEnrollmentWindowObj(_selectedEnrollmentWindowObj);
          props.setSelectedEnrollmentWindowObjParent(
            _selectedEnrollmentWindowObj
          );
        }
      }
    }
  }, [props.enrollmentWindowId, props.enrollmentWindows]);

  // const [enrollmentStatus, setEnrollmentStatus] = useState([
  //    {
  //       status: 0,
  //       count: 0
  //    },
  //    {
  //       status: 1,
  //       count: 0
  //    }
  // ])
  const [enrollDate, setEnrollDate] = useState([]);
  const [coverageType, setCoverageType] = useState([]);

  const [maxEnrollmentCount, setMaxEnrollmentCount] = useState(0);

  // const [std, setStd] = useState(0)
  // const [ltd, setLtd] = useState(0)
  // const [ladd, setLadd] = useState({
  //    member: 0,
  //    spouse: 0,
  //    dependent: 0
  // })

  // const [lineOfCoverage, setLineOfCoverage] = useState([])

  const createCoverageReport = () => {
    // let _std = 0
    // let _ltd = 0
    // let _laddMember = 0
    // let _laddSpouse = 0
    // let _laddDependent = 0
    let linesOfCoverage = [];
    if (coverageResults) {
      for (let coverageResult of coverageResults) {
        let lineOfCoverage = {
          lineOfCoverage: coverageResult.type,
          count: coverageResult.count,
        };
        if (coverageResult.type.includes('Life'))
          linesOfCoverage.unshift(lineOfCoverage);
        else linesOfCoverage.push(lineOfCoverage);
      }
    }

    // setLadd({
    //    ...ladd,
    //    member: _laddMember,
    //    spouse: _laddSpouse,
    //    dependent: _laddDependent
    // })
    // setStd(_std)
    // setLtd(_ltd)
    // setLineOfCoverage([
    //    { 'lineOfCoverage': 'Short Term Disability', 'count': _std },
    //    { 'lineOfCoverage': 'Long Term Disability', 'count': _ltd },
    //    { 'lineOfCoverage': 'Life & AD&D', 'count': _laddMember },
    //    { 'lineOfCoverage': 'Life & AD&D Spouse', 'count': _laddSpouse },
    //    { 'lineOfCoverage': 'Life & AD&D Dependent', 'count': _laddDependent }

    // ])
    setCoverageType(linesOfCoverage);
  };

  const createDateReport = () => {
    let submittedDates = {
      // '1/1': {
      // total: 0,
      // agent: 0,
      // member: 0,
      // },
    };

    let max = 0;
    if (dateResults) {
      for (let dateResult of dateResults) {
        let cleanDate;
        if (dateResult?.date) {
          let monthDay = dateResult.date.slice(4, 10);
          let month = monthDay.slice(0, 2);
          month = month.replace(/^0+/, '');
          let day = monthDay.slice(2, 4);
          cleanDate = `${month}/${day}`;
        } else cleanDate = 'No Date Info';

        //NOT LOOKING AT COUNTS WHERE DATE IS UNDEFINED
        // if (cleanDate){
        // if (dateResult.count > max) max = dateResult.count
        if (submittedDates[cleanDate]) {
          submittedDates[cleanDate].total += dateResult.count;
          if (dateResult.enrollmentMethod == 'Agent')
            submittedDates[cleanDate].agent += dateResult.count;
          else submittedDates[cleanDate].member += dateResult.count;
        } else {
          submittedDates[cleanDate] = {
            total: 0,
            agent: 0,
            member: 0,
          };
          submittedDates[cleanDate].total += dateResult.count;
          if (dateResult.enrollmentMethod == 'Agent')
            submittedDates[cleanDate].agent += dateResult.count;
          else submittedDates[cleanDate].member += dateResult.count;
        }
        // }
        if (submittedDates[cleanDate].total > max)
          max = submittedDates[cleanDate].total;
      }
    }
    setMaxEnrollmentCount(max);

    let _enrollDate = [];
    for (let [key, val] of Object.entries(submittedDates)) {
      let dateObj = {
        date: key,
        count: val,
      };
      _enrollDate.push(dateObj);
    }
    setEnrollDate(_enrollDate);
  };

  useEffect(() => {
    createDateReport();
  }, [dateResults]);

  useEffect(() => {
    createCoverageReport();
  }, [coverageResults]);

  // CSV Download
  useEffect(() => {
    if (dateResults?.length > 0 && props.dateOrCoverage == 'date') {
      if (props.setAllowDownload) props.setAllowDownload(true);
    } else {
      if (props.setAllowDownload) props.setAllowDownload(false);
    }
  }, [dateResults, props.dateOrCoverage]);

  useEffect(() => {
    async function downloadFunction() {
      let result = await dashboardService.getCSVEnrolledByDate(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      return result;
    }
    if (props.setDownloadFunction)
      props.setDownloadFunction(() => downloadFunction);
  }, [props.setDownloadFunction, props.groupPlanId, props.enrollmentWindowId]);

  useEffect(() => {
    if (props.setReportName) props.setReportName('Enrollments_By_Date_Method');
  }, [props.setReportName]);

  let theme = useTheme();

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={reportingDashboardStyles.individualReportInfinityLoader}
          />
          <style>
            {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
          </style>
        </>
      )}
      <Box sx={{ position: 'relative', height: '87%' }}>
        <Box
          sx={{
            height: '100%',
            //height: "calc(100vh - 530px)",
            //border: "1px solid red",
          }}
        >
          {props.dateOrCoverage == 'date' ? (
            <EnrollmentsByDate
              enrollDate={enrollDate}
              theme={theme}
              maxEnrollmentCount={maxEnrollmentCount}
              formatNumWithCommas={props?.formatNumWithCommas}
            />
          ) : (
            <EnrollmentsByCoverage
              coverageType={coverageType}
              theme={theme}
              formatNumWithCommas={props?.formatNumWithCommas}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
