import React, { useState, useEffect, useContext } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router-dom';

//Services
import memberService from '../../../services/member-service';
import userService from '../../../services/user-service';
import permissionService from '../../../services/permission-service';
import accountService from '../../../services/account-service';

//MuiIcons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LanguageIcon from '@mui/icons-material/Language';
import CakeIcon from '@mui/icons-material/Cake';

//MuiComponents
import Button from '@mui/material/Button';
import { format, differenceInCalendarYears } from 'date-fns';
import { InputLabel, ListSubheader, TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Our components
import CompanySettings from './settings-company/SettingsCompany';
import UserProfile from './settings-profile/SettingsProfile';
import UserPermissions from './settings-permissions/SettingsPermissions';
import UserPassword from './settings-password/SettingsPassword';
import UserNotifications from './settings-notifications/SettingsNotifications';
import UserTeam from './settings-team/SettingsTeam';
import SettingsPersonalization from './settings-personalization/SettingsPersonalization';
import { thisUserContext } from '../nav-container/NavContainer';

export default function SettingsRouter(props) {
  let history = useHistory();
  let thisContext = useContext(thisUserContext);

  //If there is a state in history, use it
  // useEffect(() => {
  //    if (history.location.state) setPageState(history.location.state)
  // },[history.location.state])
  const [roles, setRoles] = useState();
  let getRoles = async () => {
    let _roles = await permissionService.getPermissionsByRole();
    setRoles(_roles);
  };

  const [thisUser, setThisUser] = useState();

  // Not sure why I have to have this?? It breaks without it (Refresh page)
  useEffect(() => {
    setThisUser(thisContext.thisUser);
  }, [thisContext]);

  const [company, setCompany] = useState();

  let getCompany = async () => {
    let _company = await accountService.getAccount();
    setCompany(_company);
  };
  useEffect(() => {
    setThisUser(thisContext.thisUser);
    getRoles();
    getCompany();
  }, []);

  // Page Navigation
  const [activePage, setActivePage] = useState(0);

  const handleActivePageChange = (event) => {
    setActivePage(Number(event.target.getAttribute('value')));
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          flexWrap: 'wrap',
        }}
        className="flex-row"
      >
        {/* SIDE NAV */}
        {/* <div className='col-3'
         style={{height:'calc(100vh - 56px)',overflowX:'hidden',overflowY:'auto',fontFamily:'Archivo'}}>
            <List sx={{paddingTop:0}}>
               <ListSubheader sx={{color:'black',fontSize:'18px',fontWeight:'bold'}}>Settings</ListSubheader>
               <ListItemButton sx={{marginBottom:'5px'}} onClick={() => setPageState({profile:true})} selected={pageState?.profile}>
                  Profile
               </ListItemButton>
               <ListItemButton sx={{marginBottom:'5px'}} onClick={() => setPageState({settings:true})} selected={pageState?.settings}>
                  Company
               </ListItemButton>
               <ListItemButton sx={{marginBottom:'5px'}} onClick={() => setPageState({permissions:true})} selected={pageState?.permissions}>
                  Permissions
               </ListItemButton>
               <ListItemButton sx={{marginBottom:'5px'}} onClick={() => setPageState({teams:true})} selected={pageState?.teams}>
                  Teams
               </ListItemButton>
               <ListItemButton sx={{marginBottom:'5px'}}>
                  Files
               </ListItemButton>
               <ListItemButton sx={{marginBottom:'5px'}}>
                  Notifications
               </ListItemButton>
            </List>
         </div> */}

        {/* MAIN CONTENT */}

        <div
          className="row"
          style={{ width: '100%', padding: '26px 0px 10px 35px' }}
        >
          <h3>Settings</h3>
        </div>
        <Box className="flex-row" style={{ width: '100%', height: '48px' }}>
          {/*sx={{ borderBottom: 'solid 1px', borderColor: 'background.border' }}*/}
          <div style={{ width: 'auto' }}>
            <Box
              sx={{
                color: activePage === 0 ? 'primary.main' : 'text.gray',
                borderBottom: activePage === 0 ? '4px solid' : '',
                borderColor: activePage === 0 ? 'primary.main' : '',
              }}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                margin: 'auto',
                cursor: 'pointer',
                padding: '0px 16px',
              }}
              value="0"
              onClick={handleActivePageChange}
            >
              <Typography
                value="0"
                variant="body1"
                fontSize="14px"
                fontWeight="500"
                style={{ fontFamily: 'Archivo' }}
              >
                PROFILE
              </Typography>
            </Box>
          </div>

          <div style={{ width: 'auto' }}>
            <Box
              sx={{
                color: activePage === 1 ? 'primary.main' : 'text.gray',
                borderBottom: activePage === 1 ? '4px solid' : '',
                borderColor: activePage === 1 ? 'primary.main' : '',
              }}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                margin: 'auto',
                cursor: 'pointer',
                padding: '0px 16px',
              }}
              value="1"
              onClick={handleActivePageChange}
            >
              <Typography
                value="1"
                variant="body1"
                fontSize="14px"
                fontWeight="500"
                style={{ fontFamily: 'Archivo' }}
              >
                PERSONALIZATION
              </Typography>
            </Box>
          </div>

          <div style={{ width: 'auto' }}>
            <Box
              sx={{
                color: activePage === 2 ? 'primary.main' : 'text.gray',
                borderBottom: activePage === 2 ? '4px solid' : '',
                borderColor: activePage === 2 ? 'primary.main' : '',
              }}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                margin: 'auto',
                cursor: 'pointer',
                padding: '0px 16px',
              }}
              value="2"
              onClick={handleActivePageChange}
            >
              <Typography
                value="2"
                variant="body1"
                fontSize="14px"
                fontWeight="500"
                style={{ fontFamily: 'Archivo' }}
              >
                USER&nbsp;MANAGEMENT
              </Typography>
            </Box>
          </div>

          {/* Hide Notifications */}
          {/* <div className="col" style={{ width: "auto" }}>
                  <div style={{ color: activePage === 3 ? '#195FFB' : '#79747e', borderBottom: activePage === 3 ? '4px solid #195ffb' : '4px solid #195ffb00', fontWeight: 500, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto', margin: 'auto', cursor: 'pointer', padding: "0px 40px" }}
                     value="3"
                     onClick={handleActivePageChange}>
                     Notifications
                  </div>
               </div> */}

          {/* Hide Company */}

          {/* <div className="col" style={{ width: "auto" }}>
                  <div style={{ color: activePage === 4 ? '#195FFB' : '#79747e', borderBottom: activePage === 4 ? '4px solid #195ffb' : '4px solid #195ffb00', fontWeight: 500, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto', margin: 'auto', cursor: 'pointer', padding: "0px 40px" }}
                     value="4"
                     onClick={handleActivePageChange}>
                     Company
                  </div>

               </div> */}
          {/* Hide Permissions */}

          {/* <div className="col" style={{ width: "auto" }}>
                  <div style={{ color: activePage === 5 ? '#195FFB' : '#79747e', borderBottom: activePage === 5 ? '4px solid #195ffb' : '4px solid #195ffb00', fontWeight: 500, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto', margin: 'auto', cursor: 'pointer', padding: "0px 40px" }}
                     value="5"
                     onClick={handleActivePageChange}>
                     Permissions
                  </div>
               </div> */}

          <div className="col-9" style={{ width: '40%' }}></div>
        </Box>
        <div style={{ overflowX: 'hidden', overflowY: 'auto', width: '100%' }}>
          {activePage === 0 && thisUser && (
            <UserProfile
              thisUser={thisUser}
              roles={roles}
              refreshUser={thisContext.refreshUser}
            />
          )}
          {activePage === 1 && <SettingsPersonalization />}
          {/* {activePage === 1 &&
               <UserPassword thisUser={thisUser} />
            } */}
          {activePage === 2 && <UserTeam thisUser={thisUser} roles={roles} />}
          {activePage === 3 && (
            <UserNotifications thisUser={thisUser} roles={roles} />
          )}

          {activePage === 4 && (
            <CompanySettings
              thisUser={thisUser}
              roles={roles}
              company={company}
            />
          )}
          {activePage === 5 && (
            <UserPermissions thisUser={thisUser} roles={roles} />
          )}
        </div>
      </Box>
    </>
  );
}
