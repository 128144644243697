import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { format, differenceInYears, addYears } from 'date-fns';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Services
import optionService from '../../../../../services/optionService';
import organizationService from '../../../../../services/organization-service';
import userService from '../../../../../services/user-service';
import ticketServices from '../../../../../services/ticket-service';
import enrollmentService from '../../../../../services/enrollment-service';

//Mui Components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {
  FormControl,
  InputLabel,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

// Our Components
import Tags from '../../../input-tags/input-tags';
import { thisUserContext } from '../../../nav-container/NavContainer';
import {
  formatUTCDateToObjWithTimeZone,
  titleCase,
} from '../../../../../components/utils';

//Mui Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LanguageIcon from '@mui/icons-material/Language';
import CakeIcon from '@mui/icons-material/Cake';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//utils
import { formatUTCToShortDate } from '../../../../../components/utils';

//component styles
const componentStyles = {
  inputField: {
    fontSize: '15px',
    height: '50px',
    borderRadius: '8px',
    margin: '16px 0px',
  },
  inputLabel: { fontFamily: 'Archivo', margin: '16px 0px' },
  smallSelectRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 16px',
    fontFamily: 'Archivo',
  },
  smallSelectFormControl: { width: '100%' },
  smallSelect: {
    fontSize: '15px',
    height: '50px',
    width: '100%',
    borderRadius: '8px',
  },
};

export default function SingleviewLeftside(props) {
  let ticket = props.ticket;
  let member = props.member;
  let myContext = useContext(thisUserContext);

  //STATES
  const [slas, setSlas] = useState('');
  const [origins, setOrigins] = useState('');
  const [priorities, setPriorities] = useState('');
  const [organizations, setOrganizations] = useState('');
  const [tags, setTags] = useState(['Tags', 'More Tags', 'Third Tag']);
  const [newTag, setNewTag] = useState('');

  const [allUsers, setAllUsers] = useState('');
  useEffect(() => {
    if (myContext) setAllUsers(myContext.allUsers);
  }, [myContext]);

  const [types, setTypes] = useState('');
  const [message, setMessage] = useState({
    messageDisplay: false,
    autoHideDuration: '',
    severity: '',
    backgroundColor: '',
    message: '',
  });
  const [subject, setSubject] = useState('');
  const [expandProfile, setExpandProfile] = useState(false);

  const [memberPhones, setMemberPhones] = useState(props.memberPhones);
  useEffect(() => {
    setMemberPhones(props.memberPhones);
  }, [props.memberPhones]);
  let formatPhoneNumber = (str) => {
    if (str) {
      let noCountryMatch = str.match(/^(\d{3})(\d{3})(\d{4})$/);
      // let countryMatch = str.match(/^(\d{1-3})(\d{3})(\d{3})(\d{4})$/);

      if (noCountryMatch) {
        return `(${noCountryMatch[1]}) ${noCountryMatch[2]}-${noCountryMatch[3]}`;
      }
      // else if (countryMatch) {
      //   return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
      // }
      return str;
    }
    return 'No Phone Info';
  };

  useEffect(() => setSubject(ticket.subject), [ticket.subject]);
  let history = useHistory();

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: '',
        severity: '',
        backgroundColor: '',
        message: null,
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message.messageDisplay]);

  let goToMemberProfile = (e) => {
    let memberId = e.target.value;
    history.push(`/members/${memberId}`, {
      profile: true,
      profileId: memberId,
    });
  };

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: '',
      severity: '',
      backgroundColor: '',
      message: null,
    });
  };

  const handleTagValueChange = (e) => {
    let newTag = e.target.value;
    setNewTag(newTag);
  };

  const handleAddNewTag = (e) => {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      setTags([...tags, val]);
      // // Check if tag already exists
      // if (tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
      //    return;
      // }
      // setTags({ tags: [...tags, val] });
      // this.tagInput.value = null;
    }
  };

  const handleDeleteTags = (e) => {
    const newtags = tags.filter((val) => val !== e);
    setTags(newtags);
  };

  const handleExpandProfileClick = (e) => {
    setExpandProfile((prev) => !prev);
  };

  // const [enrollments, setEnrollments] = useState([])
  // let getEnrollments = async () => {
  //    let _enrollments = await enrollmentService.getEnrollmentsByMemberId(member?.id)
  //    setEnrollments(_enrollments)
  // }

  // useEffect(() => {
  //    getEnrollments()
  // }, [])

  const handleTicketInfoChange = async (e, property, action) => {
    let value = e.target.value;
    if (property == 'priority') {
      ticket.ticketPriorityId = value;

      await ticketServices.updateTicket(ticket);
      //this will work specifically for updating priority but is not triggering notifications
      // await ticketServices.updatePriority(ticket?.id, value)
      props.refreshTickets();
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 1500,
        backgroundColor: 'primary.main',
        severity: 'success',
        message: 'Successfully Updated',
      });
    }
    if (property == 'origin') {
      // await ticketServices.updateOrigin(ticket?.id, value)
      ticket.ticketOriginId = value;
      await ticketServices.updateTicket(ticket);
      props.refreshTickets();
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 1500,
        backgroundColor: 'primary.main',
        severity: 'success',
        message: 'Successfully Updated',
      });
    }
    if (property == 'dueDate') {
      // await ticketServices.updateDueDate(ticket?.id, value)
      // let _ticket = {...ticket}
      // _ticket.dueDate = e.target.value
      // _ticket.dueDate = format(new Date(dueDate), 'yyyy-MM-dd')
      // ticket.dueDate = Date.UTC(year,month,day)
      // await ticketServices.updateTicket(_ticket)
      // props.refreshTickets()
      // setMessage({
      //    ...message,
      //    messageDisplay: true,
      //    autoHideDuration: 1500,
      //    backgroundColor: 'primary.main',
      //    severity: "success",
      //    message: "Successfully Updated"
      // })
    }
    if (property == 'type') {
      ticket.ticketTypeId = value;
      await ticketServices.updateType(ticket?.id, value);

      props.refreshTickets();
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 1500,
        backgroundColor: 'primary.main',
        severity: 'success',
        message: 'Successfully Updated',
      });
    }
    if (property == 'assignee') {
      ticket.userId = value;
      await ticketServices.assignTicket(ticket?.id, value);
      props.refreshTickets();
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 1500,
        backgroundColor: 'primary.main',
        severity: 'success',
        message: 'Successfully Updated',
      });
    }
    if (property == 'subject') {
      setSubject(value);
    }
  };

  const handleSaveTextField = async (e, property) => {
    if (property == 'subject' && subject !== ticket.subject) {
      await ticketServices.updateSubject(ticket?.id, subject);
      props.refreshTickets();
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 1500,
        backgroundColor: 'primary.main',
        severity: 'success',
        message: 'Successfully Updated',
      });
    }
  };

  const handleTakeIt = async (e) => {
    await ticketServices.assignTicket(ticket?.id, myContext?.thisUser.id);
    props.refreshTickets();
    setMessage({
      ...message,
      messageDisplay: true,
      autoHideDuration: 1500,
      backgroundColor: 'primary.main',
      severity: 'success',
      message: 'Successfully Updated',
    });
  };

  //SETTING STATES
  const getTicketOptions = async () => {
    //SLA
    let slaArray = await optionService.getTicketSLA();
    setSlas(slaArray);

    //ORIGIN
    let originArray = await optionService.getTicketOrigins();
    setOrigins(originArray);

    //PRIORITY
    let priorityArray = await optionService.getTicketPriority();
    setPriorities(priorityArray);

    let organizationArray = await organizationService.getOrganizations();
    setOrganizations(organizationArray);

    let usersArray = await userService.getAll();
    setAllUsers(usersArray);

    let typeArray = await optionService.getTicketTypes();
    setTypes(typeArray);

    //STATUS
    // let statusArray = await optionService.getTicketStatus()
    // setStatuses(statusArray)
  };

  useEffect(() => {
    getTicketOptions();
  }, []);

  //UPDATE DUE DATE LOGIC

  const [dueDate, setDueDate] = useState(null);
  useEffect(() => {
    if (props?.ticket?.dueDate) {
      setDueDate(formatUTCDateToObjWithTimeZone(props?.ticket?.dueDate));
    } else {
      setDueDate(null);
    }
  }, [props?.ticket?.dueDate]);

  let dateMin = new Date();
  let dateMax = addYears(new Date(), 1);

  const [dueDateError, setDueDateError] = useState();
  const checkDueDate = (dueDate) => {
    // return;
    let dateWithinRange =
      new Date(dueDate) >= new Date(dateMin) &&
      new Date(dueDate) <= new Date(dateMax);
    if (dateWithinRange) {
      setDueDateError();
    } else {
      setDueDateError('Please select a valid date');
    }
  };

  // const handleDueDateFocus = (e) => {
  //    e.target.setAttribute('type', 'date')
  //    if (new Date(dueDate) < new Date(dateMin)) setDueDate(dateMin)
  // }

  const [datePickerClose, setDatePickerClose] = useState(true);

  //set local state dueDate on change
  const handleDueDateChange = (newDate) => {
    checkDueDate(newDate);
    let _ticket = { ...props.ticket };
    setDueDate(newDate);
  };

  //update ticket with new dueDate AFTER datePicker closes
  const handleDueDateBlur = async (e) => {
    //update due date if date changed
    if (dueDate && props?.ticket && dueDate !== props?.ticket.dueDate) {
      try {
        const updatedTicket = { ...props.ticket, dueDate: dueDate };
        await ticketServices.updateDueDate(updatedTicket);

        //set success pop-up message
        setMessage({
          ...message,
          messageDisplay: true,
          autoHideDuration: 1500,
          backgroundColor: 'primary.main',
          severity: 'success',
          message: 'Successfully Updated',
        });
      } catch (error) {
        console.error('error updating ticket', error);
      }
    }
    props.refreshTickets();
  };

  //only update backend w/ new date after datepicker closes
  useEffect(() => {
    if (props.ticket) {
      handleDueDateBlur(); // Call the handler when the ticket or dueDate changes
    }
  }, [datePickerClose]);

  useEffect(() => {
    checkDueDate(dueDate);
  }, [dueDate]);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderRight: 'solid 1px',
          borderColor: 'background.border',
          height: 'calc(100vh - 57px)',
          overflowX: 'hidden',
          overflowY: 'auto',
          fontFamily: 'Archivo',
          minWidth: '340px',
        }}
        className="col-3"
      >
        {/* Member Information */}

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'background.border',
            paddingBottom: '16px',
          }}
        >
          <div className="flex-row">
            <div className="col-12">
              <Box
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'background.border',
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h5" color="text.primary">
                  Member Information
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: '10px',
                    height: '40px',
                    padding: '10px',
                    minWidth: '135px',
                  }}
                  disabled={!member?.id}
                  value={member?.id}
                  onClick={(e) => {
                    goToMemberProfile(e);
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  View&nbsp;Profile
                </Button>
              </Box>
              <Box sx={{ padding: '20px' }}>
                <Stack direction="row" spacing={1}>
                  {props?.loading ? (
                    <Skeleton
                      variant="circular"
                      width={47}
                      height={47}
                      style={{ margin: 'auto 0px' }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: 'primary.light',
                        color: 'primary.main',
                        fontFamily: 'Archivo',
                        fontWeight: '600',
                        width: 47,
                        height: 47,
                        fontSize: 22,
                        margin: 'auto 0px',
                      }}
                    >
                      {member?.firstName &&
                        `${member?.firstName[0].toUpperCase()}${member?.lastName[0].toUpperCase()}`}
                    </Avatar>
                  )}

                  <div style={{ paddingLeft: '20px', margin: 'auto 0px' }}>
                    {props?.loading ? (
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={150}
                        height={25}
                      />
                    ) : (
                      <Typography color="text.primary" variant="body1">
                        {' '}
                        {titleCase(member?.firstName)}{' '}
                        {titleCase(member?.preferredName)}{' '}
                        {titleCase(member?.lastName)}
                      </Typography>
                    )}
                    {props?.loading ? (
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={100}
                        height={15}
                      />
                    ) : (
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        style={{ fontFamily: 'Archivo', fontWeight: '500' }}
                      >
                        Enrollment ID: {member?.enrollmentId}
                      </Typography>
                    )}
                  </div>
                </Stack>
              </Box>
            </div>
          </div>
          <Box sx={{ padding: '8px 16px' }}>
            <div
              className="flex-row"
              style={{
                alignItems: 'center',
                padding: '0px 10px',
                marginBottom: '18px',
              }}
            >
              <EmailOutlinedIcon
                color="text.gray"
                sx={{ marginRight: '16px', marginLeft: '16px', fontSize: 24 }}
              />
              <div className="container" style={{ padding: 0 }}>
                {props?.loading ? (
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={150}
                    height={12}
                  />
                ) : (
                  <>
                    <Typography color="primary.main" style={{ fontSize: 15 }}>
                      {member?.email ? member?.email : '--'}
                    </Typography>
                    {expandProfile && member?.secondEmail && (
                      <>
                        <br />
                        <Typography
                          color="primary.main"
                          style={{ fontSize: 15 }}
                        >
                          {member?.secondEmail}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Phone Number -- Should Not be hard coded */}

            <div
              className="flex-row"
              style={{
                alignItems: 'center',
                padding: '0px 10px',
                marginBottom: '18px',
              }}
            >
              <PhoneAndroidIcon
                color="text.gray"
                sx={{ marginRight: '16px', marginLeft: '16px', fontSize: 24 }}
              />
              {props?.loading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={150}
                  height={12}
                />
              ) : (
                <span style={{ fontSize: 15 }}>
                  {memberPhones &&
                    formatPhoneNumber(
                      memberPhones.find((phoneObj) => phoneObj.isPreferred)
                        ?.number
                    )}
                </span>
              )}
            </div>

            {/* Local Time Zone */}

            {/* <div className="flex-row" style={{ alignItems: 'center', padding: '0px 10px', marginBottom: '18px' }}>
                  <AccessTimeIcon color='text.gray' sx={{ marginRight: '16px', marginLeft: '16px', fontSize: 24 }} />
                  {props?.loading ? (
                     <Skeleton animation="wave" variant="rounded" width={150} height={12} />
                  ) : (
                     <>
                        <span style={{ fontSize: 15 }}>11:18 AM</span>&nbsp;<span style={{ fontSize: 14, fontWeight: 300 }}>Mountain Time (US & Canada)</span>
                     </>
                  )}

               </div> */}
            {expandProfile && (
              <div style={{ animation: `ease-in 1s`, marginBottom: '18px' }}>
                <div
                  className="flex-row"
                  style={{
                    alignItems: 'top',
                    padding: '0px 10px',
                    marginBottom: '18px',
                  }}
                >
                  <HomeWorkIcon
                    color="text.gray"
                    sx={{
                      marginRight: '16px',
                      marginLeft: '16px',
                      fontSize: 24,
                    }}
                  />
                  <div>
                    {member.street1 ? (
                      <>
                        <span style={{ fontSize: 15 }}>
                          {titleCase(member?.street1)}
                          <br />
                          {titleCase(member?.street2)}
                        </span>
                        <span style={{ fontSize: 15 }}>
                          {titleCase(member?.city)}, {member?.state}{' '}
                          {member?.zipCode}
                        </span>
                      </>
                    ) : (
                      <>--</>
                    )}
                  </div>
                </div>

                {/* <div className="flex-row" style={{ alignItems: 'center', padding: '0px 10px', marginBottom: '18px' }}>
                        <LanguageIcon color='text.gray' sx={{ marginRight: '16px', marginLeft: '16px', fontSize: 24 }} />
                        <span style={{ fontSize: 15 }}>English (United States)</span>
                     </div> */}

                <div
                  className="flex-row"
                  style={{
                    alignItems: 'center',
                    padding: '0px 10px',
                    marginBottom: '0px',
                  }}
                >
                  <CakeIcon
                    color="text.gray"
                    sx={{
                      marginRight: '16px',
                      marginLeft: '16px',
                      fontSize: 24,
                    }}
                  />
                  <span style={{ fontSize: 15 }}>
                    {member?.dob && formatUTCToShortDate(props.member.dob)}{' '}
                    &nbsp; (
                    {member?.dob &&
                      differenceInYears(new Date(), new Date(member.dob))}{' '}
                    years old)
                  </span>
                </div>
              </div>
            )}

            <div className="flex-row" style={{ justifyContent: 'center' }}>
              <Button
                fullWidth
                variant="lightContained"
                disableRipple
                onClick={handleExpandProfileClick}
              >
                {expandProfile && (
                  <span>
                    Show Less <ExpandLessIcon />
                  </span>
                )}
                {!expandProfile && (
                  <span>
                    Show More <ExpandMoreIcon />
                  </span>
                )}
              </Button>
            </div>
          </Box>
        </Box>

        {/* Plan Information */}
        {/* TODO: Revisit where this data is coming from */}

        {/* <Box>

            <Box sx={{ borderBottom: '1px solid', borderColor: 'background.border', padding: '20px', display: 'flex', justifyContent: 'space-between' }}>
               <Typography variant="h5" color="text.primary">Plan</Typography>
            </Box>
            <Box sx={{ borderBottom: '1px solid', borderColor: 'background.border', padding: '20px', }}>
               <Stack direction="row" spacing={1}>
                  <Avatar  ></Avatar>
                  <div className="row">
                     <Typography variant="body1" color="text.primary">{ticket?.groupPlan}</Typography>
                     <Typography variant="body2" color="text.secondary" >Policy Number {enrollments[0]?.policyNumber}</Typography>
                  </div>

               </Stack>

            </Box>

         </Box> */}

        {/* Ticket Information */}

        <Box>
          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'background.border',
              padding: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5" color="text.primary">
              Agent Assignment
            </Typography>
          </Box>

          <Box sx={{ padding: '16px' }}>
            <div>
              {allUsers &&
                allUsers?.find((x) => x.id == ticket?.userId)?.id !==
                  myContext?.thisUser?.id && (
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#195FFB',
                      float: 'right',
                      position: 'relative',
                      left: 0,
                      top: 0,
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      handleTakeIt();
                    }}
                  >
                    take it
                  </span>
                )}
            </div>
            <>
              <FormControl fullWidth>
                <InputLabel
                  id="ticket-assignee-label"
                  sx={componentStyles.inputLabel}
                >
                  Assignee
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="ticket-assignee-label"
                  label="Assignee"
                  size="small"
                  sx={componentStyles.inputField}
                  value={
                    (allUsers &&
                      allUsers?.find((x) => x.id == ticket?.userId)?.id) ||
                    ''
                  }
                  onChange={(e) => handleTicketInfoChange(e, 'assignee')}
                >
                  <MenuItem value="">Please select</MenuItem>
                  {allUsers &&
                    allUsers.map((user) => {
                      return (
                        <MenuItem value={user.id}>
                          <Stack direction="row" spacing={1}>
                            <Avatar
                              sx={{
                                bgcolor: 'primary.light',
                                color: 'primary',
                                fontFamily: 'Archivo',
                                fontWeight: '600',
                                width: 28,
                                height: 28,
                                fontSize: 14,
                              }}
                              src={
                                user?.id > 0 ? `api/image/user/${user?.id}` : ''
                              }
                            >
                              {user?.firstName &&
                                `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`}
                            </Avatar>
                            <span
                              style={{
                                fontFamily: 'Archivo',
                                paddingLeft: '10px',
                                margin: 'auto 0px',
                              }}
                            >
                              {' '}
                              {user.firstName} {user.lastName}
                            </span>
                          </Stack>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </>
          </Box>
          <Divider width="80%" sx={{ margin: 'auto' }} />

          <Box sx={{ padding: '16px', paddingBottom: '0px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // disableFuture
                openTo="year"
                label="Due date"
                maxDate={dateMax}
                minDate={dateMin}
                views={['year', 'month', 'day']}
                value={dueDate || null}
                onChange={handleDueDateChange}
                onClose={() => setDatePickerClose((prevValue) => !prevValue)}
                format="MM/dd/yyyy"
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
            {/* <TextField fullWidth type={createdTicket.dueDate ? 'date' : ''}
               label="Due date" variant="outlined"
               sx={{marginBottom: '20px'}}
               value={createdTicket.dueDate || ''} onChange={handleDueDateChange}
               // onFocus={handleDobFocus} onBlur={handleDobBlur}
               inputProps={{
                  min: dateMin,
                  max: dateMax,
               }}
               /> */}
          </Box>

          <Box sx={{ padding: '16px' }}>
            <>
              <FormControl fullWidth>
                <InputLabel
                  id="ticket-origin-label"
                  sx={componentStyles.inputLabel}
                >
                  Origin
                </InputLabel>
                <Select
                  disabled
                  variant="outlined"
                  labelId="ticket-origin-label"
                  label="Origin"
                  size="small"
                  sx={componentStyles.inputField}
                  value={
                    origins &&
                    origins?.find((x) => x.id == ticket?.ticketOriginId)?.id
                  }
                  onChange={(e) => handleTicketInfoChange(e, 'origin')}
                >
                  {origins &&
                    origins.map((origin) => {
                      return (
                        <MenuItem value={origin.id}>
                          <span
                            style={{
                              fontFamily: 'Archivo',
                              paddingLeft: '10px',
                              margin: 'auto 0px',
                            }}
                          >
                            {origin.name}
                          </span>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </>
          </Box>
          <Box sx={componentStyles.smallSelectRowContainer}>
            {/* ticket type */}
            <FormControl
              sx={{
                ...componentStyles.smallSelectFormControl,
                marginRight: '10px',
              }}
            >
              <InputLabel id="ticket-type-label">Type</InputLabel>
              <Select
                variant="outlined"
                labelId="ticket-type-label"
                label="Type"
                sx={componentStyles.smallSelect}
                value={
                  (types &&
                    types?.find((x) => x.id == props.ticket?.ticketTypeId)
                      ?.id) ||
                  ''
                }
                onChange={(e) => handleTicketInfoChange(e, 'type')}
                placeholder="None Selected"
              >
                {ticket.ticketTypeId == 0 && (
                  <MenuItem value="">
                    <span
                      style={{
                        fontFamily: 'Archivo',
                        paddingLeft: '10px',
                        margin: 'auto 0px',
                      }}
                    >
                      None Selected
                    </span>
                  </MenuItem>
                )}

                {types &&
                  types.map((type) => {
                    return (
                      <MenuItem value={type.id}>
                        <span
                          style={{
                            fontFamily: 'Archivo',
                            paddingLeft: '10px',
                            margin: 'auto 0px',
                          }}
                        >
                          {type.name}
                        </span>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            {/* ticket priority */}
            <FormControl sx={componentStyles.smallSelectFormControl}>
              <InputLabel id="ticket-priority-label">Priority</InputLabel>
              <Select
                variant="outlined"
                labelId="ticket-priority-label"
                label="Origin"
                sx={componentStyles.smallSelect}
                value={
                  priorities &&
                  priorities?.find(
                    (x) => x.id == props.ticket?.ticketPriorityId
                  )?.id
                }
                onChange={(e) => handleTicketInfoChange(e, 'priority')}
              >
                {priorities &&
                  priorities.map((priority) => {
                    return (
                      <MenuItem value={priority.id}>
                        <span
                          style={{
                            fontFamily: 'Archivo',
                            paddingLeft: '10px',
                            margin: 'auto 0px',
                          }}
                        >
                          {priority.name != 'Normal' ? priority.name : 'Medium'}
                        </span>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* Followers */}

        {/* <div style={{ padding: '11px  19px 0px' }}>
            <div>
               <span style={{ fontSize: '12px', color: '#195FFB', float: 'right', position: 'relative', right: 0, bottom: -5 }}>follow</span>
            </div>
            <FormControl fullWidth>
                  <InputLabel id='ticket-followers-label' sx={{fontFamily: "Archivo", color: "#00000090"}}>Followers</InputLabel>
                  <Select variant='outlined' labelId='ticket-followers-label' label='Assignee' size='small'
                  sx={{fontSize: '15px',  height: '50px', borderRadius: '8px'}}
                     value=''
                     placeholder='search for users...'
                     onChange={e => handleTicketInfoChange(e, 'followers')}
                  >
                     {allUsers && allUsers.map(user => {
                        return <MenuItem value={user.id}>
                               <Stack direction="row" spacing={1}>
                                 <Avatar sx={{ bgcolor: "#195FFB17", color:"#195FFB", fontFamily: "Archivo", fontWeight: "600", width: 28, height: 28, fontSize: 14 }}>
                                 {user?.firstName && `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`}
                                    </Avatar><span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}> {user.firstName} {user.lastName}</span>
                               </Stack>
                                 
                        </MenuItem>
                     })}
                  </Select>
               </FormControl>
         </div> */}

        {/* Tags */}

        {/* <div style={{ padding: '11px  19px 0px', marginBottom: "20px" }}>
            <TextField variant='outlined' label='Tags' size='medium' fullWidth multiLine
               sx={{ fontSize: '15px', height: '50px', borderRadius: '8px', fontFamily: "Archivo", marginBottom: '20px' }}
               value={newTag}
               onChange={e => handleTagValueChange(e)}
               onKeyDown={e => handleAddNewTag(e)}
               InputLabelProps={{
                  style: { fontFamily: 'Archivo' },
               }}
               InputProps={{
                  sx: {
                     fontFamily: "Archivo",
                     '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid #195FFB' }
                  },
                  startAdornment: (
                     <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                        {tags.map((data, index) => {
                           return (
                              <Tags data={data} handleDelete={handleDeleteTags} key={index} />
                           );
                        })}
                     </Box>
                  ),
               }}
            />
            <Tags tags={tags} />
         </div> */}

        <Snackbar
          open={message.messageDisplay}
          autoHideDuration={message.autoHideDuration}
          onClose={handleMessageClose}
          sx={{ opacity: 0.8 }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            // onClose={handleClose}
            severity={message.severity}
            sx={{ width: '100%', backgroundColor: message.backgroundColor }}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  );
}
