import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//MuiIcons

//MuiComponents
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Fade from '@mui/material/Fade';

//Assets

//Our components
import { thisUserContext } from '../nav-container/NavContainer';
import OurDataGrid from '../DataGrid/OurDataGrid';
import ScheduleNewMessageDrawer from './ScheduleNewMessageDrawer';

export default function Messaging(props) {
  let myContext = useContext(thisUserContext);
  const [thisUser, setThisUser] = useState();
  let getThisUser = async () => {
    //  let _thisUser = await userService.getMe()

    setThisUser(myContext.thisUser);
  };

  useEffect(() => {
    if (myContext) getThisUser();
  }, [myContext]);

  let scheduledMessages = [
    {
      id: 1,
      status: 'scheduled',
      date: '6/9/2023',
      scheduledBy: 'Tessa Aubry',
      message: 'Enroll today!',
      audience: 'IBT Not Enrolled',
    },
    {
      id: 2,
      status: 'sent',
      date: '6/1/2023',
      scheduledBy: 'Tessa Aubry',
      message: 'Enroll today!',
      audience: 'IBT Not Enrolled',
    },
  ];

  // Scheduled Messages Data

  const [scheduledMessageRows, setScheduledMessageRows] = useState([]);
  const buildScheduledMessageRows = () => {
    let _rows = [];
    scheduledMessages.forEach((resultObj) => {
      let { id, status, date, scheduledBy, message, audience } = resultObj;
      // let result = resultObj.result

      let builtRow = {
        id: id,
        status: status,
        date: date,
        scheduledBy: scheduledBy,
        message: message,
        audience: audience,
      };
      _rows.push(builtRow);
    });
    setScheduledMessageRows(_rows);
  };

  useEffect(() => {
    buildScheduledMessageRows();
  }, []);

  const scheduledMessageColumns = [
    { field: 'id', headerName: 'ID', flex: 90, hide: true },
    {
      field: 'status',
      headerName: 'Status',
      flex: 100,
      headerClassName: 'hide-right-sep',
      renderCell: (params) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: 'date',
      headerName: 'Scheduled Date',
      flex: 100,
      // valueGetter: (params) => {
      //    return params.row.enrollmentId
      // },
      renderCell: (params) => {
        return <div style={{ display: 'block' }}>{params.value}</div>;
      },
    },
    {
      field: 'scheduledBy',
      headerName: 'Scheduled By',
      flex: 100,
      renderCell: (params) => {
        return <>{params.value}</>;
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 200,
      renderCell: (params) => {
        return <>{params.value}</>;
      },
    },
    {
      field: 'audience',
      headerName: 'Audience',
      renderCell: (params) => {
        return <>{params.value}</>;
      },
      flex: 150,
    },
  ];

  //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  function NoMemberRows(props) {
    return (
      <div style={{ backgroundColor: '', height: '100%', textAlign: 'center' }}>
        <Box
          sx={{ backgroundColor: 'primary.light' }}
          style={{
            margin: '54px auto 13px',
            width: '58px',
            height: '58px',
            borderRadius: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></Box>
        <div style={{ marginBottom: '19px' }}>No Rows!</div>
      </div>
    );
  }
  //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  function NoMemberResults(props) {
    return (
      <div
        style={{
          backgroundColor: '',
          height: '100%',
          textAlign: 'center',
          zIndex: 5,
        }}
      >
        <Box
          sx={{ backgroundColor: 'primary.light' }}
          style={{
            margin: '54px auto 13px',
            width: '58px',
            height: '58px',
            borderRadius: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          No Results!
        </Box>
      </div>
    );
  }

  // Incoming Messages Data Grid
  let incomingMessages = [
    {
      id: 1,
      date: '6/9/2023',
      from: '8179142311',
      message: 'Hello',
    },
    {
      id: 2,
      date: '6/5/2023',
      from: '2949281324',
      message: 'STOP',
    },
  ];

  const [incomingMessagesRows, setIncomingMessagesRows] = useState([]);
  const buildIncomingMessagesRows = () => {
    let _rows = [];
    incomingMessages.forEach((resultObj) => {
      let { id, date, from, message } = resultObj;
      // let result = resultObj.result

      let builtRow = {
        id: id,
        date: date,
        from: from,
        message: message,
      };
      _rows.push(builtRow);
    });
    setIncomingMessagesRows(_rows);
  };

  useEffect(() => {
    buildIncomingMessagesRows();
  }, []);

  const incomingMessagesColumns = [
    { field: 'id', headerName: 'ID', flex: 90, hide: true },
    {
      field: 'date',
      headerName: 'Date',
      flex: 100,
      // valueGetter: (params) => {
      //    return params.row.enrollmentId
      // },
      renderCell: (params) => {
        return <div style={{ display: 'block' }}>{params.value}</div>;
      },
    },
    {
      field: 'from',
      headerName: 'From',
      renderCell: (params) => {
        return <>{params.value}</>;
      },
      flex: 150,
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 200,
      renderCell: (params) => {
        return <>{params.value}</>;
      },
    },
  ];

  //   Drawer Logic
  //NEW MEMBER DRAWER
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderColor: 'background.border',
        }}
        style={{ height: 'calc(100vh - 56px)', padding: '60px' }}
      >
        <Typography variant="h4">Messaging</Typography>
        <Typography variant="body1">
          Bulk Message Manager for your members
        </Typography>
        <Box className="flex-row">
          <Box className="col-6" sx={{ marginRight: '10px' }}>
            <Box
              className="flex-row"
              sx={{ justifyContent: 'space-between', marginBottom: '10px' }}
            >
              <Typography variant="h6">Scheduled Messages</Typography>
              <Button
                variant="contained"
                sx={{ float: 'right' }}
                onClick={() => setDrawerOpen(true)}
              >
                Schedule New Message
              </Button>
            </Box>
            <OurDataGrid
              manualHeight
              rows={scheduledMessageRows}
              columns={scheduledMessageColumns}
              manualStyle={{
                borderRadius: '15px',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                minHeight: '600px',
              }}
              noRowsComp={NoMemberRows}
              noResultsComp={NoMemberResults}
            />
          </Box>
          <Box className="col-6" sx={{ marginLeft: '10px' }}>
            <Box
              className="flex-row"
              sx={{ justifyContent: 'space-between', marginBottom: '18px' }}
            >
              <Typography variant="h6">Incoming Messages</Typography>
            </Box>
            <OurDataGrid
              manualHeight
              rows={incomingMessagesRows}
              columns={incomingMessagesColumns}
              manualStyle={{
                borderRadius: '15px',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                minHeight: '600px',
              }}
              noRowsComp={NoMemberRows}
              noResultsComp={NoMemberResults}
            />
          </Box>
        </Box>
        <ScheduleNewMessageDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
    </>
  );
}
