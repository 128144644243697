import React, { useEffect, useState } from 'react';

//Services

//Mui icons

//Mui Components
import { Box } from '@mui/material';

//Our Components
import HistoryHeader from './card-components/HistoryHeader';
import HistoryGrid from './card-components/HistoryGrid';

//utils
import {
  parseUTCDateToZonedTime,
  formatDateObjYYYYMMDD,
} from '../../../../../utils';

export default function MemberHistoryTab(props) {
  //PROPS
  let {
    member,
    dateRange,
    setDateRange,
    preSelectedDateRange,
    unsortedHistoryData,
    getMemberHistory,
    permissionsEnabler,
    today,
    beginningOfTimeForMember,
    historyError,
    setHistoryError,
  } = props;

  const [loading, setLoading] = useState(false);
  const [sortedHistoryData, setSortedHistoryData] = useState([]);

  //get timeline data as array of objects
  //group by modifiedDate
  //many events can occurr on same date  - group events that occur on same day but show on individual rows by time
  const sortHistoryData = (historyData) => {
    //group by same date 'yyyy-mm-dd'
    const groupedByDate = historyData.reduce((acc, historyObj) => {
      // convert dates to local timezone and separate date section from time since date string is in this format: '2023-10-25 15:40:08.1094347 +00:00'
      const dateObjWithTimezone = parseUTCDateToZonedTime(historyObj?.creation);
      const shortDateString = formatDateObjYYYYMMDD(dateObjWithTimezone);

      if (!acc[shortDateString]) {
        acc[shortDateString] = [];
      }
      acc[shortDateString].push(historyObj);
      return acc;
    }, {});

    // Transform groupedByDate into the desired object structure
    const _organizedHistoryData = Object.keys(groupedByDate).map((date) => {
      return {
        date: date,
        events: groupedByDate[date],
      };
    });

    //Sort by date newest to oldest
    const _sortedHistoryData = _organizedHistoryData.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    setSortedHistoryData(_sortedHistoryData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    //if history data is available, sort it, else display no data message
    if (unsortedHistoryData?.length > 0) {
      sortHistoryData(unsortedHistoryData);
    } else {
      setSortedHistoryData([]);
      setLoading(false);
    }
  }, [unsortedHistoryData?.length, unsortedHistoryData]);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 104px)',
        width: '100%',
      }}
    >
      <HistoryHeader
        member={member}
        dateRange={dateRange}
        setDateRange={setDateRange}
        preSelectedDateRange={preSelectedDateRange}
        today={today}
        beginningOfTimeForMember={beginningOfTimeForMember}
        getMemberHistory={getMemberHistory}
        loading={loading}
        setLoading={setLoading}
        profileDisabled={props?.profileDisabled}
        disabledProfileStyle={props?.disabledProfileStyle}
      />
      <HistoryGrid
        sortedHistoryData={sortedHistoryData}
        unsortedHistoryData={unsortedHistoryData}
        loading={loading}
        historyError={historyError}
        profileDisabled={props?.profileDisabled}
        disabledProfileStyle={props?.disabledProfileStyle}
      />
    </Box>
  );
}
