import React, { useEffect, useState, useLayoutEffect } from 'react';
import { format, differenceInCalendarYears, differenceInYears } from 'date-fns';
import { IMaskInput, IMaskMixin, IMask } from 'react-imask';
import { TransitionGroup } from 'react-transition-group';

//MuiIcons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LanguageIcon from '@mui/icons-material/Language';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import ShieldIcon from '@mui/icons-material/Shield';
import LockIcon from '@mui/icons-material/Lock';

//Mui Components
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  Typography,
  Stack,
  AlertTitle,
  Paper,
  Icon,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

//MUI X
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';

//Our Components
import AutoCompleteAddress from './AutoCompleteAddress';
// Services
import memberService from '../../../../../../services/member-service';

//utils
import {
  compareObjects,
  formatDateObjYYYYMMDD, //yyyy-MM-dd format
  formateUTCYYYYMMDD,
  formatUTCDateToObjWithTimeZone,
  formatUTCToShortDate,
  titleCase,
} from '../../../../../utils';

//Our Styles
import memberInfoStyles from '../memberInfoStyles';
import { borderColor, color, fontStyle, fontWeight, margin } from '@mui/system';

const componentStyles = {
  avatarAndNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 10px 16px',
    // border: "1px solid orange",
  },
  memberAvatar: {
    bgcolor: 'primary.light',
    color: 'primary.main',
    fontFamily: 'Archivo',
    fontWeight: '600',
    width: 47,
    height: 47,
    margin: '0px 24px 0px 0px',
  },
  nameAndEnrollmentIdContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifycontent: 'flex-start',
  },
  archivedAlert: {
    margin: '2px',
    border: '1px solid #ef5350',
    // padding: '0px 16px 16px 16px',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Archivo',
    lineHeight: '24px',
    color: 'rgb(95, 33, 32)',
    backgroundColor: 'unset',
    titleBox: {
      alignItems: 'baseline',
    },
    title: {
      fontWeight: 500,
    },
    icon: {
      color: '#ef5350',
      margin: '4px',
    },
    button: {
      color: 'rgb(95, 33, 32)',
      paddingRight: '10px',
    },
    content: {
      paddingLeft: '6px',
      paddingBottom: '16px',
      paddingRight: '16px',
    },
  },

  memberInfoItemContainer: {
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '7px 37px 7px 23px',
    position: 'relative',
  },
  memberInfoItem: {
    width: '100%',
  },
  memberInfoIcon: (edit) => ({
    margin: edit ? '12px 32px 0px 10px' : '0px 32px 0px 10px',
    height: '24px',
    color: 'text.disabled',
  }),
  memberInfoText: {
    fontSize: 15,
    overflowWrap: 'break-word',
    paddingRight: '12px ',
    maxWidth: '200px',
  },
  horizontalLine: {
    width: '90%',
    margin: '5px auto',
  },
  //////EDIT
  addInputButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '13px',
    color: 'primary.main',
    textAlign: 'right',
    fontWeight: 500,
  },
  addIcon: {
    height: '18px',
    marginRight: '5px',
  },
  input: {
    margin: '5px 0px',
    width: '100%',
  },
  showMoreButtonContainer: { display: 'flex', justifyContent: 'center' },
  showMoreButton: {
    width: '90%',
    marginTop: '10px',
  },
  viewSSNIconContainer: {
    color: 'text.disabled',
    padding: '2px 0px 0px 0px',
  },
  viewSSNIcon: {
    fontSize: '20px',
    cursor: 'pointer',
  },
  customDatePicker: {
    // minWidth: "150px",
    margin: ' 4px 16px 4px 0px',
    padding: '0px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 12px',
      fontSize: '14px', // Adjust the font size
    },
    '& fieldset': {
      borderRadius: '8px',
      height: '45px',
    },
  },
};

export default function MemberInfoTop(props) {
  const [loading, setLoading] = useState(false);
  let formatPhoneNumber = (phoneNumberString) => {
    let noCountryMatch = phoneNumberString?.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (noCountryMatch) {
      return `(${noCountryMatch[1]}) ${noCountryMatch[2]}-${noCountryMatch[3]}`;
    }
    // else if (countryMatch) {
    //   return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    // }
    return phoneNumberString;
  };
  let profileDisabled = props.profileDisabled;

  const [edit, setEdit] = useState(false);
  const [memberInfo, setMemberInfo] = useState();

  const handleEditProfile = () => {
    setEdit(true);
    //expand all fields when editing
    setExpandProfile(true);
  };

  //Toggle/Add an edit section
  const [showCollapsedField, setShowCollapsedField] = useState({
    preferredName: false,
    suffix: false,
    secondEmail: false,
    secondaryPhone: false,
    street2: false,
  });

  const toggleField = (fieldName) => {
    setShowCollapsedField({
      ...showCollapsedField,
      [fieldName]: !showCollapsedField[fieldName],
    });
  };

  const setMemberInfoFunction = () => {
    if (props.member) {
      //format dob for DateField to date obj
      setMemberInfo({
        ...props?.member,
        dob: props.member.dob
          ? formatUTCDateToObjWithTimeZone(props.member.dob)
          : null,
      });
    }
  };

  useEffect(() => {
    setMemberInfoFunction();
  }, [props?.member]);

  let handleMemberInfoChange = (e, property) => {
    let value = e.target.value;

    if (property !== 'middleInitial' && property !== 'social') {
      setMemberInfo({
        ...memberInfo,
        [property]: value,
      });
    } else if (property === 'social') {
      //limit to 9 digits max
      let social = e.target.value;
      if (social?.length > 9) {
        social = social?.slice(0, 9);
      }
      setMemberInfo({
        ...memberInfo,
        [property]: social,
      });
    } else if (property === 'middleInitial') {
      //limit middle initial input to only 1 character
      let middleInitial = e.target.value;
      let middleInitialSingleChar = middleInitial.slice(0, 1);
      setMemberInfo({
        ...memberInfo,
        [property]: middleInitialSingleChar,
      });
    }
  };

  //update dob
  const handleDobChange = (e) => {
    let inputVal = e; //date in date obj format
    setMemberInfo({
      ...memberInfo,
      dob: inputVal,
    });
  };

  ///PHONES
  //local phone state for handling phone number format
  const [primaryPhone, setPrimaryPhone] = useState({});
  const [secondaryPhone, setSecondaryPhone] = useState({});
  const [phoneNumberInDb, setPhoneNumberInDb] = useState({});

  //updated phones in state if phone changes are made
  useEffect(() => {
    setPrimaryPhone(props.primaryPhone?.number ? props.primaryPhone : {});
    setSecondaryPhone(props.secondaryPhone?.number ? props.secondaryPhone : {});
  }, [props.member, props.phones, props.primaryPhone, props.secondaryPhone]);

  //monitor phone changes - this will only update on save member info or page refresh
  useEffect(() => {
    setPhoneNumberInDb({
      primaryPhone: props.primaryPhone?.number ? props.primaryPhone.number : {},
      secondaryPhone: props.secondaryPhone?.number
        ? props.secondaryPhone.number
        : {},
    });
  }, []);

  const handlePrimaryPhoneChange = (val, mask) => {
    mask.updateValue(val);
  };

  const handleSecondaryPhoneChange = (val, mask) => {
    mask.updateValue(val);
  };

  // UPDATE or ADD either primary or secondary phones onBlur event

  const handlePhoneComplete = (e) => {
    let enteredNumber = e.target.value;
    //remove mask symbols: () and -
    let numericPhoneNumber = enteredNumber.replace(/\D/g, '');
    //primary vs. secondary updates
    if (e.target.id === 'primary-phone-mask') {
      //check length of number
      //check if a primary mobile phone exists and add current ID of that phone
      if (props?.primaryPhone?.id) {
        let updatePrimaryPhone = {
          ...props?.primaryPhone,
          number: numericPhoneNumber,
        };
        props.setPrimaryPhone(updatePrimaryPhone);
        //update local state
        setPrimaryPhone({ ...primaryPhone, number: numericPhoneNumber });
      } else {
        //create new phone if one not in DB
        let insertPrimaryPhone = {
          memberId: props.member.id,
          phoneNumberTypeId: 1, // Mobile,home,work
          isPreferred: true,
          number: numericPhoneNumber,
        };
        props.setPrimaryPhone(insertPrimaryPhone);
        //update local state
        setPrimaryPhone({ ...primaryPhone, number: numericPhoneNumber });
      }
    } else {
      //check length of number for error messaging
      // setHomePhoneLength(numericPhoneNumber);
      // check if a secondary mobile phone exists and add current ID of that phone
      if (props.secondaryPhone?.id) {
        let updateSecondaryPhone = {
          ...props?.secondaryPhone,
          number: numericPhoneNumber,
        };
        props.setSecondaryPhone(updateSecondaryPhone);
        //update local state
        setSecondaryPhone({ ...secondaryPhone, number: numericPhoneNumber });
      } else {
        //create new phone
        let insertSecondaryPhone = {
          memberId: props?.member?.id,
          phoneNumberTypeId: 2, // home
          isPreferred: false,
          number: numericPhoneNumber,
        };

        props.setSecondaryPhone(insertSecondaryPhone);
        //update local state
        setSecondaryPhone({ ...secondaryPhone, number: numericPhoneNumber });
      }
    }
  };

  //    let noCountryMatch = str.match(/^(\d{3})(\d{3})(\d{4})$/);
  //    // let countryMatch = str.match(/^(\d{1-3})(\d{3})(\d{3})(\d{4})$/);

  //    if (noCountryMatch) {
  //       return `(${noCountryMatch[1]}) ${noCountryMatch[2]}-${noCountryMatch[3]}`
  //    }
  //    // else if (countryMatch) {
  //    //   return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
  //    // }
  //    return str
  // };

  const PrimaryPhoneMask = IMaskMixin(({ ref, ...props }) => (
    <TextField
      {...props}
      ref={ref}
      size="small"
      key={'primaryPhone mask'}
      autoComplete="tel-national"
      type={'tel'}
      label="Primary Phone"
      variant="outlined"
      sx={componentStyles.input}
      defaultValue={primaryPhone?.number || ''}
      // value={props.primaryPhone?.number}
    />
  ));

  const SecondaryPhoneMask = IMaskMixin(({ ref, ...props }) => (
    <TextField
      {...props}
      ref={ref}
      size="small"
      key={'secondaryPhone mask'}
      autoComplete="tel-national"
      type={'tel'}
      label="Secondary Phone"
      variant="outlined"
      defaultValue={secondaryPhone?.number || ''}
      // value={secondaryPhone}
    />
  ));

  //handle show more and show less button click
  const [expandProfile, setExpandProfile] = useState(false);

  const handleExpandProfileClick = (e) => {
    setExpandProfile((prev) => !prev);
    //hide full social
    setShowFullSocial(false);
  };

  //SAVE member profile changes
  const handleSaveClick = () => {
    setLoading(true);

    //CHECK PHONE NUMBER changes before saving to db
    //if there are changes AND the new number does not equal the old number=, update in db
    setTimeout(() => {
      if (
        primaryPhone?.number !== phoneNumberInDb.primaryPhone ||
        secondaryPhone?.number !== phoneNumberInDb.secondaryPhone
      ) {
        props.handlePhoneInsert();
      }
    }, 500);

    //CHECK all other member info changes before saving to db
    //copy original member object to compare
    let _memberInfoOriginalObj = { ...props?.member };
    if (props?.member?.dob && memberInfo?.dob) {
      //format both dob to same format short date yyyy-MM-dd to compare and update in db if changes made
      _memberInfoOriginalObj.dob = formateUTCYYYYMMDD(props?.member?.dob);
      memberInfo.dob = formatDateObjYYYYMMDD(memberInfo?.dob);
    }

    //check for changes in member info after formatting dob to be the same
    const memberInfoChanged = compareObjects(
      _memberInfoOriginalObj,
      memberInfo
    );
    //update in db IF changes were made
    if (memberInfoChanged) {
      props?.updateMember(memberInfo);
    }
    //reset local state
    setShowCollapsedField({
      preferredName: false,
      suffix: false,
      secondEmail: false,
      secondaryPhone: false,
      street2: false,
    });
    setEdit(false);
    setLoading(false);
    setShowFullSocial(false);
  };

  //Cancel Edit member info
  const handleCancelClick = () => {
    setEdit(false);
    //close all collapsed fields if any were opened
    setShowCollapsedField({
      preferredName: false,
      suffix: false,
      secondEmail: false,
      secondaryPhone: false,
      street2: false,
    });
    setMemberInfoFunction();
    //hide full social
    setShowFullSocial(false);
  };

  //VIEW FULL SOCIAL logic
  const [showFullSocial, setShowFullSocial] = useState(null);

  const maskFullSocial = (social) => {
    let lastFour = social?.slice(-4);
    if (showFullSocial && social?.length === 9) {
      return `${social.slice(0, 3)}-${social.slice(3, 5)}-${social.slice(5)}`;
    } else {
      return `XXX-XX-${lastFour}`;
    }
  };

  const handleShowFullSocial = () => {
    setShowFullSocial((showFullSocial) => !showFullSocial);
  };

  //update SSN - using react-imask
  const handleSSNChange = (val, mask) => {
    mask.updateValue(val);
  };

  const handleSSNComplete = (val, mask) => {
    mask.updateValue(val);
    let unmasked = mask._unmaskedValue;
    setMemberInfo({
      ...memberInfo,
      social: unmasked,
    });
  };
  //remove dashes from social for db
  const handleSocialBlur = (e) => {
    let value = e.target.value.replaceAll('-' || 'X', '');
    setMemberInfo({
      ...memberInfo,
      social: value,
    });
  };

  const FullSSNMask = IMaskMixin(({ ref, ...props }) => (
    <TextField
      {...props}
      variant="outlined"
      label="SSN"
      size="small"
      sx={componentStyles.input}
      defaultValue={memberInfo?.social ? memberInfo.social : ''}
      onBlur={handleSocialBlur}
      placeholder="XXX-XX-XXXX"
      InputProps={{
        sx: {
          '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
            border: '2px solid primary.main',
          },
        },
      }}
    />
  ));

  //format member language for display
  const formatLanguage = (languageTypeId) => {
    let language = props?.languageTypes?.find(
      (lang) => lang.id === languageTypeId
    );
    return language ? language.name : '';
  };

  const handleArchivedChange = () => {
    let archived = !props?.member?.isArchived;
    props?.updateMember({
      ...props?.member,
      isArchived: archived,
    });
    props.setMessage({
      ...props.message,
      messageDisplay: true,
      autoHideDuration: 1500,
      backgroundColor: '',
      severity: 'success',
      message: 'Successfully unarchived member',
    });
  };

  return (
    <Box sx={memberInfoStyles.sectionContainer} className="archiveAlert">
      {/* Title and Edit Button */}
      {props?.member?.isArchived && (
        <Paper
          variant="outlined"
          className="archiveAlert"
          sx={componentStyles.archivedAlert}
        >
          <Box
            className="flex-row archiveAlert"
            sx={componentStyles.archivedAlert.titleBox}
          >
            <Icon
              className="col-1 archiveAlert"
              sx={componentStyles.archivedAlert.icon}
            >
              <LockIcon className="archiveAlert" />
            </Icon>
            <Typography
              sx={componentStyles.archivedAlert.title}
              className="col-8 archiveAlert"
            >
              This profile is locked
            </Typography>
            <Button
              size="small"
              className="col-3 archiveAlert"
              color="error"
              classes={{ root: 'archiveAlert' }}
              disabled={!props?.permissionsEnabler?.archiveMember}
              onClick={handleArchivedChange}
            >
              UNLOCK
            </Button>
          </Box>
          <Typography
            component={'div'}
            className="flex-row archiveAlert"
            sx={componentStyles.archivedAlert.content}
          >
            <Box className="col-1 archiveAlert" />
            This members profile has been archived, you will not be able to make
            any changes.
          </Typography>
        </Paper>
      )}
      <Box sx={memberInfoStyles.sectionHeaderContainer}>
        <Typography variant="h5" sx={memberInfoStyles.sectionTitle}>
          Member Info
        </Typography>
        <Box sx={memberInfoStyles.editButtonsContainer}>
          {!edit ? (
            <Button
              disabled={!memberInfo || profileDisabled}
              variant="contained"
              // type='button'
              onClick={handleEditProfile}
              sx={memberInfoStyles.editButton}
            >
              EDIT
            </Button>
          ) : (
            <>
              <Button
                size="small"
                variant="text"
                onClick={handleCancelClick}
                sx={memberInfoStyles.cancelEditButton}
              >
                CANCEL
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={handleSaveClick}
                sx={memberInfoStyles.saveEditButton}
              >
                SAVE
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* Member Name and Enrollment Id */}
      <Box sx={componentStyles.avatarAndNameContainer}>
        {props?.loading ? (
          <Skeleton
            variant="circular"
            width={47}
            height={47}
            sx={{ margin: 'auto 0px' }}
          />
        ) : (
          <Avatar sx={componentStyles.memberAvatar}>
            {props.member?.firstName &&
              `${props.member?.firstName[0].toUpperCase()}${props.member?.lastName[0].toUpperCase()}`}
          </Avatar>
        )}

        <Box sx={componentStyles.nameAndEnrollmentIdContainer}>
          {props?.loading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={150}
              height={25}
            />
          ) : (
            <Typography color="text.primary" variant="body1">
              {' '}
              {titleCase(props.member?.firstName)}{' '}
              {props.member?.preferredName
                ? `'${titleCase(props.member?.preferredName)}'`
                : ''}{' '}
              {props.member?.middleInitial
                ? `${titleCase(props.member?.middleInitial)}.`
                : ''}{' '}
              {titleCase(props.member?.lastName)} {props.member?.suffix}
            </Typography>
          )}
          {props?.loading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={100}
              height={15}
            />
          ) : (
            <Typography color="text.secondary" variant="body2">
              Enrollment ID: {props.member?.enrollmentId}
            </Typography>
          )}
        </Box>
      </Box>
      {/* View AND Edit member Info */}
      <Box>
        {/*update name input hidden if user is not editing*/}
        {edit && (
          <>
            <Box sx={componentStyles.memberInfoItemContainer}>
              <Box>
                <PersonIcon sx={componentStyles.memberInfoIcon(edit)} />
              </Box>
              <Box sx={componentStyles.memberInfoItem}>
                <TextField
                  variant="outlined"
                  label="First Name"
                  size="small"
                  sx={componentStyles.input}
                  value={
                    memberInfo?.firstName ? titleCase(memberInfo.firstName) : ''
                  }
                  onChange={(e) => handleMemberInfoChange(e, 'firstName')}
                  InputProps={{
                    sx: {
                      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                        border: '2px solid primary.main',
                      },
                    },
                  }}
                />

                {/* Add Preferred Name Input - if there is no preferred name, show button to add one and give dropdown */}

                {!showCollapsedField.preferredName &&
                  !memberInfo.preferredName && (
                    <Box
                      type="button"
                      sx={componentStyles.addInputButton}
                      onClick={() => toggleField('preferredName')}
                    >
                      <AddIcon sx={componentStyles.addIcon} />
                      Add preferred name
                    </Box>
                  )}
                {(showCollapsedField.preferredName ||
                  memberInfo.preferredName) && (
                  <TextField
                    variant="outlined"
                    label="Preferred Name"
                    size="small"
                    sx={componentStyles.input}
                    value={
                      memberInfo?.preferredName ? memberInfo.preferredName : ''
                    }
                    onChange={(e) => handleMemberInfoChange(e, 'preferredName')}
                    InputProps={{
                      sx: {
                        '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                          border: '2px solid primary.main',
                        },
                      },
                    }}
                  />
                )}

                <TextField
                  variant="outlined"
                  label="Middle Initial"
                  id="middleInitial"
                  size="small"
                  sx={componentStyles.input}
                  value={
                    memberInfo?.middleInitial ? memberInfo.middleInitial : ''
                  }
                  onChange={(e) => handleMemberInfoChange(e, 'middleInitial')}
                  InputProps={{
                    sx: {
                      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                        border: '2px solid primary.main',
                      },
                    },
                  }}
                />

                <TextField
                  variant="outlined"
                  label="Last Name"
                  size="small"
                  sx={componentStyles.input}
                  value={memberInfo?.lastName ? memberInfo.lastName : ''}
                  onChange={(e) => handleMemberInfoChange(e, 'lastName')}
                  InputProps={{
                    sx: {
                      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                        border: '2px solid primary.main',
                      },
                    },
                  }}
                />

                {/* Add Suffix Input - if no suffix exists, show button to add one and give dropdown */}
                {!showCollapsedField.suffix && !memberInfo.suffix && (
                  <>
                    <Box
                      type="button"
                      sx={componentStyles.addInputButton}
                      onClick={() => toggleField('suffix')}
                    >
                      <AddIcon sx={componentStyles.addIcon} />
                      Add suffix
                    </Box>
                  </>
                )}
                {(showCollapsedField.suffix || memberInfo.suffix) && (
                  <TextField
                    variant="outlined"
                    label="Suffix"
                    size="small"
                    helperText="E.g. I, II, III, IV"
                    sx={componentStyles.input}
                    value={memberInfo?.suffix ? memberInfo.suffix : ''}
                    onChange={(e) => handleMemberInfoChange(e, 'suffix')}
                    InputProps={{
                      sx: {
                        '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                          border: '2px solid primary.main',
                        },
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <hr style={componentStyles.horizontalLine} />
          </>
        )}

        {/* start of below edit name field */}

        <Box>
          {props.member && (
            // <Box  sx={componentStyles.memberInfoItemContainer}>

            //    <Box >
            //    <AccessTimeIcon
            //
            //       sx={componentStyles.memberInfoIcon(edit)}
            //     />

            //   </Box>
            //   <Box >
            //     <>

            //       <Typograpphy sx={{ fontSize: 16, fontWeight: 300 }}>
            //         {(props.member?.street1 &&
            //           "11:18 AM Mountain Time (US & Canada)") ||
            //           "--"}
            //       </Typograpphy>
            //     </>

            //     {edit && (

            //   <TextField
            //         variant="outlined"
            //         label="Time Zone"
            //         size="small"
            //
            //         sx={componentStyles.input}
            //         InputProps={{
            //           sx: {
            //             "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
            //               border: "2px solid primary.main",
            //             },
            //           },
            //         }}
            //       />
            //     )}
            //   </Box>
            // </Box>

            <>
              {/*///// Start of Temp Email and Phone Number pre-profile expansion ("show-more button") data (place holder until timezone data available) //////*/}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <EmailOutlinedIcon
                    sx={componentStyles.memberInfoIcon(edit)}
                  />
                </Box>

                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <>
                      <Box>
                        {props?.loading ? (
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={150}
                            height={12}
                          />
                        ) : (
                          <>
                            <Typography
                              // color="primary.main"
                              sx={componentStyles.memberInfoText}
                            >
                              {props.member?.email ? props.member?.email : '--'}
                            </Typography>

                            {props?.member?.secondEmail && (
                              <Typography
                                // color="primary.main"
                                sx={componentStyles.memberInfoText}
                              >
                                {props.member?.secondEmail}
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {edit && (
                    <>
                      <TextField
                        variant="outlined"
                        label="Primary Email"
                        size="small"
                        sx={componentStyles.input}
                        value={memberInfo?.email ? memberInfo.email : ''}
                        onChange={(e) => handleMemberInfoChange(e, 'email')}
                        InputProps={{
                          sx: {
                            '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                              border: '2px solid primary.main',
                            },
                          },
                        }}
                      />

                      {/* Add Second Email Iput - if no email exists, show button to add one and give dropdown */}
                      {!showCollapsedField.secondEmail &&
                        !memberInfo.secondEmail && (
                          <Box
                            type="button"
                            sx={componentStyles.addInputButton}
                            onClick={() => toggleField('secondEmail')}
                          >
                            <AddIcon sx={componentStyles.addIcon} />
                            Add secondary email
                          </Box>
                        )}
                      {(showCollapsedField.secondEmail ||
                        memberInfo.secondEmail) && (
                        <TextField
                          variant="outlined"
                          label="Secondary Email"
                          size="small"
                          value={
                            memberInfo?.secondEmail
                              ? memberInfo.secondEmail
                              : ''
                          }
                          onChange={(e) =>
                            handleMemberInfoChange(e, 'secondEmail')
                          }
                          sx={componentStyles.input}
                          InputProps={{
                            sx: {
                              '&.MuiOutlinedInput-root.Mui-focused > fieldset':
                                {
                                  border: '2px solid primary.main',
                                },
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Box>
              {edit && <hr style={componentStyles.horizontalLine} />}

              {/* Phone(s) */}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <PhoneAndroidIcon sx={componentStyles.memberInfoIcon(edit)} />
                </Box>
                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <>
                      {props?.loading ? (
                        <>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={150}
                            height={12}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={150}
                            height={12}
                          />
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography sx={componentStyles.memberInfoText}>
                            {(props.primaryPhone?.number &&
                              formatPhoneNumber(props.primaryPhone?.number)) ||
                              '--'}
                          </Typography>
                          <Typography sx={componentStyles.memberInfoText}>
                            {(props.secondaryPhone?.number &&
                              formatPhoneNumber(
                                props.secondaryPhone?.number
                              )) ||
                              ''}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}

                  {/* Edit Primary Phone */}
                  {edit && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <PrimaryPhoneMask
                        id="primary-phone-mask"
                        key={'primary phone mask wrap'}
                        mask="(#00)000-0000"
                        definitions={{
                          '#': /[1-9]/,
                        }}
                        onAccept={(value, mask) =>
                          handlePrimaryPhoneChange(value, mask)
                        }
                        onBlur={(e) => handlePhoneComplete(e)}
                        overwrite
                        autoComplete="tel-national"
                        type="tel"
                      />

                      {/* //  Add Secondary Phone */}

                      {!showCollapsedField.secondaryPhone &&
                        !memberInfo.secondaryPhone && (
                          <>
                            <Box
                              type="button"
                              sx={componentStyles.addInputButton}
                              onClick={() => toggleField('secondaryPhone')}
                            >
                              <AddIcon sx={componentStyles.addIcon} />
                              Add secondary phone
                            </Box>
                          </>
                        )}
                      {(showCollapsedField.secondaryPhone ||
                        memberInfo.secondaryPhone) && (
                        // Secondary Phone Edit
                        <SecondaryPhoneMask
                          label="Secondary Phone Number"
                          id="secondary-phone-mask"
                          key={'secondary phone mask wrap'}
                          mask="(#00)000-0000"
                          definitions={{
                            '#': /[1-9]/,
                          }}
                          onAccept={(value, mask) =>
                            handleSecondaryPhoneChange(value, mask)
                          }
                          onBlur={(e) => handlePhoneComplete(e)}
                          overwrite
                          autoComplete="tel-national"
                          type="tel"
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              {edit && <hr style={componentStyles.horizontalLine} />}
              {/*///// End of Temp Email and Phone Number pre-profile expansion data //////*/}
            </>
          )}
          <Collapse in={expandProfile}>
            <>
              {/* Email - hiding for now until time-zone available and will remove email display above/comment this back in */}
              {/* <Box  sx={componentStyles.memberInfoItemContainer}>
                  <Box >
                    <EmailOutlinedIcon
                      
                      sx={componentStyles.memberInfoIcon(edit)}
                    />
                  </Box>

                  <Box 
                  
                  sx={componentStyles.memberInfoItem}
                  >
                    {!edit && (
                      <>
                        <Box className="container" sx={{ padding: 0 }}>
                          {props?.loading ? (
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              width={150}
                              height={12}
                            />
                          ) : (
                            <>
                              <Typography
                                color="primary.main"
                                sx={{ fontSize: 15 }}
                              >
                                {props.member?.email
                                  ? props.member?.email
                                  : "--"}
                              </Typography>

                              <Typography
                                color="primary.main"
                                sx={{ fontSize: 15 }}
                              >
                                {props.member?.secondEmail}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </>
                    )}
                    {edit && (
                      <>
                        <TextField
                          variant="outlined"
                          label="Primary Email"
                          size="small"
                          
                          sx={componentStyles.input}
                          value={memberInfo?.email ? memberInfo.email : ""}
                          onChange={(e) => handleMemberInfoChange(e, "email")}
                          InputProps={{
                            sx: {
                              "&.MuiOutlinedInput-root.Mui-focused > fieldset":
                                {
                                  border: "2px solid primary.main",
                                },
                            },
                          }}
                        /> */}

              {/* Add Second Email Iput - if no email exists, show button to add one and give dropdown */}
              {/* {!showCollapsedField.secondEmail &&
                          !memberInfo.secondEmail && (
                            <Box
                              type="button"
                             sx={componentStyles.addInputButton}
                              onClick={() => toggleField("secondEmail")}
                            >
                              <AddIcon sx={componentStyles.addIcon} />
                              Add secondary email
                            </Box>
                          )}
                        {(showCollapsedField.secondEmail ||
                          memberInfo.secondEmail) && (
                          <TextField
                            variant="outlined"
                            label="Secondary Email"
                            size="small"
                            
                            value={
                              memberInfo?.secondEmail
                                ? memberInfo.secondEmail
                                : ""
                            }
                            onChange={(e) =>
                              handleMemberInfoChange(e, "secondEmail")
                            }
                            sx={componentStyles.input}
                            InputProps={{
                              sx: {
                                "&.MuiOutlinedInput-root.Mui-focused > fieldset":
                                  {
                                    border: "2px solid primary.main",
                                  },
                              },
                            }}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Box> */}
              {/* {edit && <hr style={componentStyles.horizontalLine} />} */}

              {/* Phone - same as email above - currently commented out in the collapsible section and displayed above until time zone available */}

              {/* <Box  sx={componentStyles.memberInfoItemContainer}>
                  <Box >
                    <PhoneAndroidIcon
                      
                      sx={componentStyles.memberInfoIcon(edit)}
                    />
                  </Box>
                  <Box 
                  
                  sx={componentStyles.memberInfoItem}
                  >
                    {!edit && (
                      <>
                        {props?.loading ? (
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={150}
                            height={12}
                          />
                        ) : (
                          <Typography>
                            {(primaryPhone &&
                              formatPhoneNumber(primaryPhone)) ||
                              "--"}
                          </Typography>
                        )}
                      </>
                    )} */}

              {/* {edit && (
                      // <TextField variant='outlined' label='Phone Number' size='small' 
                      //    sx={{ marginBottom: '14px' }}
                      //    value={props.primaryPhone}
                      //     onChange={e => handleMemberInfoChange(e, 'primaryPhone')}
                      //    InputProps={{
                      //       sx: {
                      //          '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid primary.main' },
                      //       },
                      //    }}
                      // />
                      /////////////////////////////////
                      <>
                        <PhoneMask
                          id="phone-mask"
                          required
                          key={"phone mask wrap"}
                          mask="(#00)000-0000"
                          definitions={{
                            "#": /[1-9]/,
                          }}
                          onAccept={(value, mask) =>
                            handlePhoneChange(value, mask)
                          }
                          onComplete={(value, mask) =>
                            handlePhoneComplete(value, mask)
                          }
                          overwrite
                          autoComplete="false"
                        /> */}

              {/* //  Add Secondary Phone */}

              {/* {!showCollapsedField.secondaryPhone &&
                          !memberInfo.secondaryPhone && (
                            <>
                              <Box
                                type="button"
                                sx={componentStyles.addInputButton}
                                onClick={() => toggleField("secondaryPhone")}
                              >
                                <AddIcon sx={componentStyles.addIcon} />
                                Add secondary email
                              </Box>
                            </>
                          )}
                        {(showCollapsedField.secondaryPhone ||
                          memberInfo.secondaryPhone) && (
                          // Secondary Phone change
                          <PhoneMask
                            disabled={true}
                            label="Secondary Phone Number"
                            id="phone-mask"
                            required
                            key={"phone mask wrap"}
                            mask="(#00)000-0000"
                            definitions={{
                              "#": /[1-9]/,
                            }}
                            onAccept={(value, mask) =>
                              handlePhoneChange(value, mask)
                            }
                            onComplete={(value, mask) =>
                              handlePhoneComplete(value, mask)
                            }
                            overwrite
                            autoComplete="false"
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Box> */}
              {/* {edit && <hr style={componentStyles.horizontalLine} />} */}

              {/* Time - temporarily dsiable until we have timeZone data */}
              {/* <Box  sx={componentStyles.memberInfoItemContainer}>
                  <Box >
                    <AccessTimeIcon
                      
                      sx={componentStyles.memberInfoIcon(edit)}
                    />
                  </Box>
                  <Box 
                  
                  sx={componentStyles.memberInfoItem}
                   sx={{ lineHeight: "18px" }}>
                    <>
                      <Typography sx={{ fontSize: 16, fontWeight: 300 }}>
                        {(props.member?.street1 &&
                          "11:18 AM Mountain Time (US & Canada)") ||
                          "--"}
                      </Typography>
                    </>

                    {edit && (
                      <TextField
                        variant="outlined"
                        label="Time Zone"
                        size="small"
                        
                        sx={componentStyles.input}
                        InputProps={{
                          sx: {
                            "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                              border: "2px solid primary.main",
                            },
                          },
                        }}
                      />
                    )}
                  </Box>
                </Box> */}

              {/* address */}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <HomeWorkIcon sx={componentStyles.memberInfoIcon(edit)} />
                </Box>
                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <>
                      <Typography sx={componentStyles.memberInfoText}>
                        {(props.member?.street1 &&
                          titleCase(props.member?.street1)) ||
                          '--'}{' '}
                        {props.member?.street2}
                      </Typography>
                      <Typography sx={componentStyles.memberInfoText}>
                        {titleCase(props.member?.city)}
                        {props.member?.state && ','} {props.member?.state}{' '}
                        {props.member?.zipCode}
                      </Typography>
                      {/* TODO: Edit City, State, Zipcode */}
                    </>
                  )}
                  {edit && (
                    <AutoCompleteAddress
                      setMemberInfo={setMemberInfo}
                      memberInfo={memberInfo}
                      handleMemberInfoChange={handleMemberInfoChange}
                      setShowCollapsedField={setShowCollapsedField}
                      showCollapsedField={showCollapsedField}
                      toggleField={toggleField}
                    />
                  )}
                </Box>
              </Box>
              {edit && <hr style={componentStyles.horizontalLine} />}
              {/* language */}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <LanguageIcon sx={componentStyles.memberInfoIcon(edit)} />
                </Box>
                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <Typography sx={componentStyles.memberInfoText}>
                      {formatLanguage(props.member?.languageTypeId) || '--'}
                    </Typography>
                  )}
                  {edit && (
                    <TextField
                      select
                      variant="outlined"
                      label="Language"
                      size="small"
                      sx={componentStyles.input}
                      InputProps={{
                        sx: {
                          '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                            border: '2px solid primary.main',
                          },
                        },
                      }}
                      onChange={(e) =>
                        handleMemberInfoChange(e, 'languageTypeId')
                      }
                      value={memberInfo?.languageTypeId || ''}
                    >
                      {props?.languageTypes?.length > 0 &&
                        props.languageTypes.map((option) => {
                          return (
                            <MenuItem value={option.id}>{option.name}</MenuItem>
                          );
                        })}
                    </TextField>
                  )}
                </Box>
              </Box>
              {edit && <hr style={componentStyles.horizontalLine} />}

              {/* birthday */}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <CakeIcon sx={componentStyles.memberInfoIcon(edit)} />
                </Box>
                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <Typography sx={componentStyles.memberInfoText}>
                      {props.member?.dob &&
                        formatUTCToShortDate(props.member.dob)}
                      {(props.member?.dob &&
                        ` (${differenceInYears(
                          new Date(),
                          new Date(props?.member?.dob)
                        )} years old)`) ||
                        '--'}
                    </Typography>
                  )}
                  {edit && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateField
                        fullWidth
                        disableFuture
                        label="Date of Birth"
                        format="MM/dd/yyyy"
                        sx={componentStyles.customDatePicker}
                        value={memberInfo?.dob || null}
                        onChange={handleDobChange}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                </Box>
              </Box>
              {edit && <hr style={componentStyles.horizontalLine} />}

              {/* Gender*/}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <AccessibilityIcon
                    sx={componentStyles.memberInfoIcon(edit)}
                  />
                </Box>
                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <Typography sx={componentStyles.memberInfoText}>
                      {(props.member?.gender &&
                        props.genderOptions?.length > 0 &&
                        props.genderOptions.find(
                          (gender) => gender.id == props.member.gender
                        )?.name) ||
                        '--'}
                    </Typography>
                  )}
                  {edit && (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          select
                          variant="outlined"
                          labelId="member-gender-label"
                          label="Gender"
                          size="small"
                          value={memberInfo?.gender ? memberInfo.gender : ''}
                          onChange={(e) => handleMemberInfoChange(e, 'gender')}
                        >
                          {(props.genderOptions?.length > 0 &&
                            props.genderOptions.map((option) => {
                              return (
                                <MenuItem value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })) ||
                            '--'}
                        </TextField>
                      </FormControl>
                    </>
                  )}
                </Box>
              </Box>

              {/* SSN */}
              <Box sx={componentStyles.memberInfoItemContainer}>
                <Box>
                  <ShieldIcon sx={componentStyles.memberInfoIcon(edit)} />
                </Box>
                <Box sx={componentStyles.memberInfoItem}>
                  {!edit && (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography sx={componentStyles.memberInfoText}>
                        {props.member?.social
                          ? maskFullSocial(memberInfo?.social) //display local state social
                          : '--'}
                      </Typography>
                      {/* view/hide full social button visible IF agent w/ permissions AND social length > 4  */}
                      {props?.permissionsEnabler?.viewFullSocial &&
                        props?.member?.social?.length === 9 && (
                          <IconButton
                            sx={componentStyles.viewSSNIconContainer}
                            onClick={handleShowFullSocial}
                          >
                            {showFullSocial ? (
                              <VisibilityIcon
                                sx={componentStyles.viewSSNIcon}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={componentStyles.viewSSNIcon}
                              />
                            )}
                          </IconButton>
                        )}
                    </Box>
                  )}
                  {edit && (
                    <FullSSNMask
                      mask={
                        props?.member?.social?.length > 4
                          ? '***-**-0000'
                          : 'XXX-XX-0000'
                      }
                      definitions={{
                        '*': /[0-9X]/,
                      }}
                      // overwrite
                      onAccept={(value, mask) => handleSSNChange(value, mask)}
                      onComplete={(value, mask) =>
                        handleSSNComplete(value, mask)
                      }
                      // onBlur={(e) => handleSSNComplete(e)}
                    />
                  )}
                </Box>
              </Box>
            </>
          </Collapse>
        </Box>

        {/* Show More Button */}
        <Box sx={componentStyles.showMoreButtonContainer}>
          <Button
            sx={componentStyles.showMoreButton}
            variant="lightContained"
            disableRipple
            onClick={handleExpandProfileClick}
          >
            {expandProfile && (
              <Typography>
                Show Less <ExpandLessIcon />
              </Typography>
            )}
            {!expandProfile && (
              <Typography>
                Show More <ExpandMoreIcon />
              </Typography>
            )}
          </Button>
        </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );
}
