import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// Assets

//Mui Components

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  FormControl,
  InputLabel,
  Typography,
  Divider,
  Box,
  IconButton,
} from '@mui/material';
import MUIRichTextEditor from 'mui-rte';
import TMUIRichTextEditorRef from 'mui-rte';
import {
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  createWithContent,
  ContentState,
  createFromBlockArray,
  EditorState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';

//Our Components
import planBuildService from '../../../services/planBuild-service';

export default function PlanManagementDrawer(props) {
  //Drawer Logic
  const handleDrawerClose = (e) => {
    props.setDrawerOpen(false);
  };

  const convertToRTE = (value) => {
    if (!value) value = '<p></p>';
    let contentHTML = convertFromHTML(value);
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    const content = JSON.stringify(convertToRaw(state));
    return content;
  };

  const getMethodName = (value) => {
    if (value == 'achTerms') {
      return 'achterms';
    } else if (value == 'verbalSignature') {
      return 'verbalagreement';
    } else if (value == 'termsAndConditions') {
      return 'terms';
    } else if (value == 'description') {
      return 'description';
    } else if (value == 'helpText') {
      return 'helptext';
    }
  };

  const save = async (data) => {
    let newData;
    if (props?.updateObject?.valueType !== 'description') {
      props?.setEditValue(stateToHTML(convertFromRaw(JSON.parse(data))));
      newData = stateToHTML(convertFromRaw(JSON.parse(data)));
    } else {
      props?.setEditValue(
        JSON.stringify(convertFromRaw(JSON.parse(data)).getPlainText())
      );
      newData = convertFromRaw(JSON.parse(data)).getPlainText();
    }

    props.setDrawerOpen(false);
    try {
      let newUpdate = await planBuildService.update(
        props.updateObject.type,
        props.updateObject.id,
        getMethodName(props?.updateObject?.valueType),
        newData
      );
      props?.getGroupPlan();
    } catch (error) {
      console.error('error\n\n\n', error);
    }
  };

  const change = (state, e) => {
    if (!state.getCurrentContent().hasText()) {
      console.log('empty');
    }
    console.log(getMethodName(props?.updateObject?.valueType));
  };

  const [verbalAgreementObj, setVerbalAgreementObj] = useState({
    firstName: '',
    lastName: '',
    enrolledIn: '',
    totalCost: '',
    salary: '',
    adminEmail: '',
    email: '',
  });

  const ref = useRef(TMUIRichTextEditorRef);

  const handleSave = () => {
    ref.current?.save();
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { marginTop: '56px!important' },
        }}
        hideBackdrop={true}
        anchor="right"
        open={props.drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '560px',
            padding: '19px 38px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" fontFamily="Poppins" fontWeight="600">
              {`Modify Group Plan: ${props?.getDisplayName(
                props?.updateObject?.valueType
              )}`}
            </Typography>
            <IconButton
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              role="button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            sx={{ color: 'primary.main' }}
            style={{ margin: '17px -38px 30px', opacity: 1, height: '2px' }}
          />
          <Box
            sx={{
              flexDirection: 'column',
              display: 'flex',
              maxHeight: 'calc(100vh - 300px)',
            }}
          >
            {props?.updateObject?.valueType == 'verbalSignature' && (
              <Box>
                <Typography
                  fontSize={'.8rem'}
                  fontStyle={'italic'}
                  sx={{ marginBottom: '10px' }}
                >
                  The following text will dynamically appear before all group
                  plan's verbal signature. Edit the text below for what will
                  follow this information.
                </Typography>
                <Typography fontSize={'.9rem'}>
                  Please state your first and last name:&nbsp;
                  {verbalAgreementObj.firstName
                    ? verbalAgreementObj.firstName
                    : '$First Name'}
                  &nbsp;
                  {verbalAgreementObj.lastName
                    ? verbalAgreementObj.lastName
                    : '$Last Name'}
                  . Today you called and enrolled in:&nbsp;
                  {verbalAgreementObj.enrolledIn
                    ? verbalAgreementObj.enrolledIn
                    : '$Benefit Summary'}
                  &nbsp;
                  <strong>
                    (Enroller reviewed the above selected coverages, applicable
                    offsets, payments and transaction fees)&nbsp;
                  </strong>
                  with a total monthly cost of&nbsp;
                  {verbalAgreementObj.totalCost
                    ? props?.UsdFormatterDec.format(
                        verbalAgreementObj.totalCost
                      )
                    : '$Total Cost'}
                  . It is your responsibility to thoroughly read the full policy
                  booklet. To request a booklet, please email&nbsp;
                  {verbalAgreementObj.adminEmail
                    ? verbalAgreementObj.adminEmail
                    : '$Admin Email'}
                  . You have reported an annual income of&nbsp;
                  {verbalAgreementObj.salary != ''
                    ? props?.UsdFormatter.format(verbalAgreementObj.salary)
                    : '$Salary'}
                  ; &nbsp;
                  <strong>
                    If your elected benefit is tied to your income, please be
                    aware if you report an income greater than what you make,
                    you will not receive benefits on the overstated income
                    amount.
                  </strong>
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'scroll',
                border: '1px solid',
                borderRadius: '6px',
                padding: '6px',
                borderColor: 'background.border',
              }}
            >
              <MUIRichTextEditor
                ref={ref}
                defaultValue={convertToRTE(props?.editValue)}
                onChange={change}
                onSave={save}
                controls={[
                  'bold',
                  'italic',
                  'underline',
                  'link',
                  'bulletList',
                  'numberList',
                  'undo',
                  'redo',
                ]}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '20px',
            }}
          >
            <Button onClick={handleDrawerClose} variant="text">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
