import * as signalR from '@microsoft/signalr';
// import { sendMessage } from '@microsoft/signalr/dist/esm/Utils';
import authService, {
  authHeader,
  getToken,
  checkStatus,
} from './services/auth-service';

class internalSignalRConnection {
  connection;

  // Define the subscriptions
  _onNotification = new Array();
  _onChat = new Array();
  _onReportNotification = new Array();

  connect(callback) {
    //if (accessToken !== null) {
    //    this.connection = new signalR.HubConnectionBuilder()
    //        .configureLogging(signalR.LogLevel.Information)
    //        .withUrl("/notificationhub", { accessTokenFactory: () => accessToken })
    //        .withAutomaticReconnect()
    //        .build();
    //}
    //else {
    //    this.connection = new signalR.HubConnectionBuilder()
    //        .configureLogging(signalR.LogLevel.Information)
    //        .withUrl("/notificationhub")
    //        .withAutomaticReconnect()
    //        .build();
    //}

    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl('/notificationhub', { accessTokenFactory: () => getToken() })
      .withAutomaticReconnect()
      .build();

    // Define the hub subscriptions
    this.connection.on('Notification', (message) => {
      // I may not need that messagecallback
      //if (this._onNotification) {
      //    this._onNotification(message);
      //}

      for (let callback of this._onNotification) {
        console.log('new notification from signalR.js', message);
        //callback(message);
      }
    });

    this.connection.on('Chat', (message) => {
      for (let callback of this._onChat) {
        callback(message);
      }

      // I may not need that messagecallback
      //if (this._onChat) {
      //    this._onChat(message);
      //}
    });

    this.connection.on('ReportNotification', (message) => {
      for (let callback of this._onReportNotification) {
        callback(message);
      }
    });

    this.connection
      .start()
      .then(() => {
        console.log('Connected to signalR');
        // callback the function that wanted to be called
        callback();
      })
      .catch(function (err) {
        return console.error('error connecting to signalR', err.toString());
      });
  }

  disconnect(callback) {
    if (this.connection) {
      this.connection.stop();
    }
    if (callback) {
      callback();
    }
  }

  // Define the events that we want components to have access to
  onNotification(callback) {
    this._onNotification.push(callback);
  }

  onChat(callback) {
    this._onChat.push(callback);
  }

  onReportNotification(callback) {
    this._onReportNotification.push(callback);
  }

  sendMessage() {
    this.connection.invoke('SendMessageAsync', 'test', 'test');
  }
}

// Start the connection.
const signalRConnection = new internalSignalRConnection();
export default signalRConnection;
