import React, { useEffect, useState } from 'react';
import { format, differenceInCalendarYears } from 'date-fns';

//MuiIcons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

//Mui Components
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

//Our Styles
import memberInfoStyles from '../memberInfoStyles';

//utils
import { titleCase } from '../../../../../utils';

export default function MemberInfoBottom(props) {
  //Component Styling
  const componentStyles = {
    spouseAndDependentInfoItem: {
      padding: '5px 18px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    spouseAndDependentInfoTitle: {
      // alignItems: "center",
      alignContent: 'top',
      fontSize: '16px',
      fontWeight: 400,
      marginBottom: 0,
      flex: 1,
    },
    spouseAndDependentInfo: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '16px',
      fontWeight: 400,
      // flex: 7,
    },
    spouseAndDependentEditInfo: {
      flex: 1.5,
    },
    inputField: {
      sx: { marginBottom: '0px', fontSize: '10px' },
    },
    addDependentInputField: {
      sx: { fontSize: '10px', marginBottom: '10px' },
    },
  };

  let profileDisabled = props.profileDisabled;

  //Utility Functions
  const formatDate = (rawDate) => {
    if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);

    return date;
  };

  const [edit, setEdit] = useState(false);
  const [memberInfo, setMemberInfo] = useState();
  // useEffect(() => {
  //   if (props.member) {
  //     let _memberInfo = {
  //        spouseFirstName: props.member.spouseFirstName,
  //        spouseLastName: props.member.spouseLastName,
  //     }
  //     if (props.member.spouseDOB) {
  //        _memberInfo = {
  //           ..._memberInfo,
  //           spouseDOB: format(new Date(`${props.member.spouseDOB.slice(0, 10)}T00:00:00`), 'yyyy-MM-dd'),
  //        }
  //     }
  //     setMemberInfo(_memberInfo)
  //   }
  // }, [props.member]);

  let handleMemberInfoChange = (e, property) => {
    let value = e.target.value;
    setMemberInfo({
      ...memberInfo,
      [property]: value,
    });
  };

  //Spouse
  let updateMember = () => {
    // let spouseInfoChanged = (
    //    memberInfo.spouseFirstName != props.member.spouseFirstName ||
    //    memberInfo.spouseLastName != props.member.spouseLastName ||
    //    memberInfo.spouseDOB != props.member.spouseDOB
    // )
    // if (spouseInfoChanged){
    //    props.updateMember(memberInfo)
    // }
    props.saveSpouseInfo();
    props.saveDependents();
    props.getThisMember();
    setShowAddDependent(true);
    setEdit(false);
    //refresh timeline after member info updates
    setTimeout(() => {
      props?.refreshHistory();
    }, 500);
  };

  const [dependents, setDependents] = useState([]);

  useEffect(() => {
    if (props.dependents) {
      setDependents(props.dependents);
    }
  }, [props.dependents, edit]);

  let handleDependentInfoChange = (e, property, id) => {
    let value = e.target.value;

    setDependents([
      ...dependents.map((dependent) =>
        dependent.id === id
          ? Object.assign(dependent, { [property]: value })
          : dependent
      ),
    ]);
  };

  const [showAddDependent, setShowAddDependent] = useState(true);

  let handleAddDependentButton = (e) => {
    setShowAddDependent((prev) => !prev);
  };

  const [newDependent, setNewDependent] = useState({
    firstName: '',
    lastName: '',
    dob: '',
  });

  let handleNewDependentChange = (e, property) => {
    let value = e.target.value;
    setNewDependent({
      ...newDependent,
      [property]: value,
    });
  };

  let handleAddNewDependent = (e) => {
    props.addDependent(newDependent);
    setNewDependent({ firstName: '', lastName: '', dob: '' });
    setShowAddDependent(true);
  };

  let handleRemoveDependent = (e, id) => {
    props.deleteDependent(id);
  };

  const handleDobBlur = (e) => {
    if (!e.target.value) e.target.setAttribute('type', '');
  };

  const handleDobFocus = (e) => {
    e.target.setAttribute('type', 'date');
  };

  const handleSaveClick = () => {
    updateMember();
  };

  const handleCancelClick = () => {
    setEdit(false);
    //reset spouse info
    props.getSpouse();
    if (props.spouse) {
      props.setSpouse(props.spouse);
    }
    //reset dependent fields to current dependent info from db
    props.getThisMember();
    if (props.dependents) {
      setDependents(props.dependents);
    }
    //reset new dependent fields if not saved
    setNewDependent({
      firstName: '',
      lastName: '',
      dob: '',
    });
    setShowAddDependent(true);
  };

  return (
    <>
      {/* SPOUSE/DEPENDENT TITLE SECTION */}
      <Box sx={memberInfoStyles.sectionContainer}>
        <Box sx={memberInfoStyles.sectionHeaderContainer}>
          <Typography variant="h5" sx={memberInfoStyles.sectionTitle}>
            Spouse & Dependent
          </Typography>

          <Box sx={memberInfoStyles.editButtonsContainer}>
            {!edit && (
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={() => setEdit(true)}
                sx={memberInfoStyles.editButton}
                disabled={profileDisabled}
              >
                EDIT
              </Button>
            )}
            {edit && (
              <>
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  onClick={handleCancelClick}
                  sx={memberInfoStyles.cancelEditButton}
                >
                  CANCEL
                </Button>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  onClick={handleSaveClick}
                  sx={memberInfoStyles.saveEditButton}
                >
                  SAVE
                </Button>
              </>
            )}
          </Box>
        </Box>

        {/* Spouse Name*/}
        <div
          className="flex-row"
          style={componentStyles.spouseAndDependentInfoItem}
        >
          <div
            className="col-6"
            style={componentStyles.spouseAndDependentInfoTitle}
          >
            Spouse
          </div>
          {!edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentInfo}
            >
              {props.spouse?.firstName && titleCase(props.spouse?.firstName)}{' '}
              {titleCase(props.spouse?.lastName) || '--'}
            </div>
          )}

          {edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentEditInfo}
            >
              <TextField
                variant="outlined"
                label=" First Name"
                size="small"
                fullWidth
                sx={{ fontSize: '10px', marginBottom: '10px' }}
                value={props.spouse?.firstName || ''}
                onChange={(e) =>
                  props.setSpouse({
                    ...props.spouse,
                    firstName: e.target.value,
                  })
                }
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />

              <TextField
                variant="outlined"
                label="Last Name"
                size="small"
                fullWidth
                sx={componentStyles.inputField.sx}
                value={props.spouse?.lastName || ''}
                onChange={(e) =>
                  props.setSpouse({ ...props.spouse, lastName: e.target.value })
                }
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </div>
          )}
        </div>

        {/* Spouse PHONE NUMBER - do not currently have this info or collect from member */}

        {/* <div
          className="flex-row"
          style={componentStyles.spouseAndDependentInfoItem}
        >
          <div
            className="col-6"
            style={componentStyles.spouseAndDependentInfoTitle}
          >
            Phone number
          </div>
          {!edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentInfo}
            > */}
        {/* add phone info here */}
        {/* --
            </div>
          )}
          {edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentEditInfo}
            >
              <TextField
                disabled
                variant="outlined"
                label="Spouse Phone"
                sx={componentStyles.inputField.sx}
                size="small"
                fullWidth
                type=""
                value=""
                InputProps={{
                  sx: {
                    "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                      border: "2px solid primary.main",
                    },
                  },
                }}
              />
            </div>
          )}
        </div> */}

        {/* Spouse Email */}
        <div
          className="flex-row"
          style={componentStyles.spouseAndDependentInfoItem}
        >
          <div
            className="col-6"
            style={componentStyles.spouseAndDependentInfoTitle}
          >
            Email
          </div>

          {!edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentInfo}
            >
              {(props.spouse?.email && props.spouse.email) || '--'}
            </div>
          )}
          {edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentEditInfo}
            >
              <TextField
                variant="outlined"
                label="Email"
                size="small"
                fullWidth
                type={props.spouse?.email ? 'email' : ''}
                sx={componentStyles.inputField.sx}
                value={props.spouse?.email || ''}
                onChange={(e) =>
                  props.setSpouse({
                    ...props.spouse,
                    email: e.target.value,
                  })
                }
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </div>
          )}
        </div>

        {/* Spouse Gender*/}
        <div
          className="flex-row"
          style={componentStyles.spouseAndDependentInfoItem}
        >
          <div
            className="col-6"
            style={componentStyles.spouseAndDependentInfoTitle}
          >
            Gender
          </div>
          {!edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentInfo}
            >
              {(props.spouse?.gender &&
                props.genderOptions?.length > 0 &&
                props.genderOptions.find(
                  (gender) => gender.id == props.spouse.gender
                )?.name) ||
                '--'}
            </div>
          )}

          {edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentEditInfo}
            >
              <FormControl fullWidth sx={componentStyles.inputField.sx}>
                <InputLabel id="member-gender-label">Gender</InputLabel>
                <Select
                  variant="outlined"
                  labelId="member-gender-label"
                  label="Gender"
                  size="small"
                  InputProps={{
                    sx: {
                      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                        border: '2px solid primary.main',
                      },
                    },
                  }}
                  value={props.spouse?.gender ? props.spouse.gender : ''}
                  onChange={(e) =>
                    props.setSpouse({
                      ...props.spouse,
                      gender: e.target.value,
                    })
                  }
                >
                  {(props.genderOptions?.length > 0 &&
                    props.genderOptions.map((option) => {
                      return (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      );
                    })) ||
                    '--'}
                </Select>
              </FormControl>
            </div>
          )}
        </div>

        {/* Spouse Birthday */}

        <div
          className="flex-row"
          style={componentStyles.spouseAndDependentInfoItem}
        >
          <div
            className="col-6"
            style={componentStyles.spouseAndDependentInfoTitle}
          >
            Birthday
          </div>
          {!edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentInfo}
            >
              {(props.spouse?.dateOfBirth &&
                format(
                  new Date(
                    `${props?.spouse?.dateOfBirth.slice(0, 10)}T00:00:00`
                  ),
                  'MM/dd/yyyy'
                )) ||
                '--'}

              {/* {(props.spouse?.dateOfBirth &&
                  format(
                    new Date(
                      `${props.spouse?.dateOfBirth?.slice(0, 10)}T00:00:00`
                    ),
                    "MM/dd/yyyy"
                  )) ||
                  "--"} */}
            </div>
          )}
          {edit && (
            <div
              className="col-6"
              style={componentStyles.spouseAndDependentEditInfo}
            >
              <TextField
                variant="outlined"
                label="Birthday"
                size="small"
                fullWidth
                type={props.spouse?.dateOfBirth ? 'date' : ''}
                onFocus={handleDobFocus}
                onBlur={handleDobBlur}
                sx={componentStyles.inputField.sx}
                value={
                  (props.spouse?.dateOfBirth &&
                    props.spouse?.dateOfBirth
                      .toString()
                      .includes('T00:00:00') &&
                    props.spouse.dateOfBirth.replace('T00:00:00', '')) ||
                  props.spouse.dateOfBirth ||
                  ''
                }
                onChange={(e) =>
                  props.setSpouse({
                    ...props.spouse,
                    dateOfBirth: e.target.value,
                  })
                }
                InputProps={{
                  sx: {
                    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                      border: '2px solid primary.main',
                    },
                  },
                }}
              />
            </div>
          )}
        </div>
        <hr style={{ width: '90%', margin: '10px auto 5px' }} />

        {/* Dependents */}
        {dependents?.length < 1 && (
          <>
            <div
              className="flex-row"
              style={componentStyles.spouseAndDependentInfoItem}
            >
              {!edit && (
                <>
                  <div
                    className="col-6"
                    style={componentStyles.spouseAndDependentInfoTitle}
                  >
                    Dependent(s)
                  </div>
                  <div
                    className="col-6"
                    style={componentStyles.spouseAndDependentInfo}
                  >
                    {'--'}
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {/* Dependent list for 1 or more */}
        {dependents?.length > 0 &&
          dependents?.map((dependent, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className="flex-row"
                  style={componentStyles.spouseAndDependentInfoItem}
                >
                  <div
                    className="col-6"
                    style={componentStyles.spouseAndDependentInfoTitle}
                  >
                    Dependent [{i + 1}]
                  </div>
                  {!edit && (
                    <div
                      className="col-6"
                      style={componentStyles.spouseAndDependentInfo}
                    >
                      {titleCase(dependent?.firstName)}&nbsp;
                      {titleCase(dependent?.lastName)}
                    </div>
                  )}
                  {edit && (
                    <div
                      className="col-6"
                      style={componentStyles.spouseAndDependentEditInfo}
                    >
                      <TextField
                        variant="outlined"
                        label="First Name"
                        size="small"
                        fullWidth
                        sx={componentStyles.addDependentInputField.sx}
                        value={dependents && dependents[i]?.firstName}
                        onChange={(e) =>
                          handleDependentInfoChange(
                            e,
                            `firstName`,
                            props.dependents[i].id
                          )
                        }
                        InputProps={{
                          sx: {
                            '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                              border: '2px solid primary.main',
                            },
                          },
                        }}
                      />

                      <TextField
                        variant="outlined"
                        label="Last Name"
                        size="small"
                        fullWidth
                        sx={componentStyles.inputField.sx}
                        value={dependents && dependents[i]?.lastName}
                        onChange={(e) =>
                          handleDependentInfoChange(
                            e,
                            `lastName`,
                            props.dependents[i].id
                          )
                        }
                        InputProps={{
                          sx: {
                            '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                              border: '2px solid primary.main',
                            },
                          },
                        }}
                      />
                    </div>
                  )}{' '}
                </div>
                {/* Dependent Birthday */}
                <div
                  className="flex-row"
                  style={componentStyles.spouseAndDependentInfoItem}
                >
                  <div
                    className="col-6"
                    style={componentStyles.spouseAndDependentInfoTitle}
                  >
                    Birthday
                  </div>
                  {!edit && (
                    <div
                      className="col-6"
                      style={componentStyles.spouseAndDependentInfo}
                    >
                      {(dependent?.dob &&
                        format(formatDate(dependent?.dob), 'MM/dd/yyyy')) ||
                        '--'}
                    </div>
                  )}

                  {edit && (
                    <div
                      className="col-6"
                      style={componentStyles.spouseAndDependentEditInfo}
                    >
                      <TextField
                        variant="outlined"
                        label="Birthday"
                        size="small"
                        fullWidth
                        type={dependents[i].dob ? 'date' : ''}
                        sx={componentStyles.inputField.sx}
                        value={(dependents && dependents[i]?.dob) || ''}
                        onChange={(e) =>
                          handleDependentInfoChange(
                            e,
                            `dob`,
                            props.dependents[i].id
                          )
                        }
                        InputProps={{
                          sx: {
                            '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                              border: '2px solid primary.main',
                            },
                          },
                        }}
                        onBlur={handleDobBlur}
                        onFocus={handleDobFocus}
                      />
                    </div>
                  )}
                </div>
                {edit && (
                  <Box
                    type="button"
                    onClick={(e) => handleRemoveDependent(e, dependent?.id)}
                    sx={{
                      color: 'primary.main',
                      textAlign: 'right',
                      padding: '0px 18px',
                      fontSize: '14px',
                    }}
                  >
                    REMOVE
                  </Box>
                )}

                <hr style={{ width: '90%', margin: '10px auto 5px' }} />
              </React.Fragment>
            );
          })}

        {/* Add New Dependent */}

        {edit && (
          <>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              {showAddDependent && (
                <Button
                  variant="outlined"
                  sx={{
                    color: 'primary.main',
                    fontSize: '14px',
                    fontWeight: 500,
                    width: '90%',
                    fontFamily: 'Archivo',
                    '&.MuiButton-text:focus, &.MuiButton-text:hover': {
                      background: 'none',
                    },
                  }}
                  onClick={handleAddDependentButton}
                >
                  <AddIcon sx={{ fontSize: '16px', marginRight: '8px' }} />
                  Add Dependent
                </Button>
              )}
            </div>
            {!showAddDependent && (
              <>
                <div
                  className="flex-row"
                  style={componentStyles.spouseAndDependentInfoItem}
                >
                  <div
                    className="col-6"
                    style={componentStyles.spouseAndDependentInfoTitle}
                  >
                    Dependent [{dependents.length + 1}]
                  </div>
                  <div
                    className="col-6"
                    style={componentStyles.spouseAndDependentEditInfo}
                  >
                    {/* Add Dependent First Name */}
                    <TextField
                      variant="outlined"
                      label="First Name"
                      size="small"
                      fullWidth
                      sx={componentStyles.addDependentInputField.sx}
                      value={newDependent.firstName}
                      onChange={(e) => handleNewDependentChange(e, 'firstName')}
                      InputProps={{
                        sx: {
                          '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                            border: '2px solid primary.main',
                          },
                        },
                      }}
                    />

                    {/* Add Dependent Last Name */}
                    <TextField
                      variant="outlined"
                      label="Last Name"
                      size="small"
                      fullWidth
                      sx={componentStyles.addDependentInputField.sx}
                      value={newDependent.lastName}
                      onChange={(e) => handleNewDependentChange(e, 'lastName')}
                      InputProps={{
                        sx: {
                          '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                            border: '2px solid primary.main',
                          },
                        },
                      }}
                    />

                    {/* Add Dependent Birthday */}
                    <TextField
                      variant="outlined"
                      label="Birthday"
                      size="small"
                      fullWidth
                      type={newDependent.dob ? 'date' : ''}
                      onFocus={handleDobFocus}
                      onBlur={handleDobBlur}
                      sx={componentStyles.addDependentInputField.sx}
                      value={newDependent.dob}
                      onChange={(e) => handleNewDependentChange(e, 'dob')}
                      InputProps={{
                        sx: {
                          '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                            border: '2px solid primary.main',
                          },
                        },
                      }}
                    />
                  </div>
                </div>

                <div style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      marginRight: '18px',
                      padding: '12px',
                      height: '20px',
                      width: '60px',
                      borderColor: 'primary.main',
                      borderRadius: '8px',
                    }}
                    sx={{
                      fontFamily: 'Archivo',
                      '&.MuiButton-text:focus, &.MuiButton-text:hover': {
                        background: 'none',
                      },
                    }}
                    onClick={handleAddNewDependent}
                  >
                    Add
                  </Button>
                  {/* 
                  <Button
                    variant="text"
                    sx={{
                      color: "#195ffb",
                      fontSize: 14,
                      fontWeight: 500,
                      padding: 0,
                      fontFamily: "Archivo",
                      "&.MuiButton-text:focus, &.MuiButton-text:hover": {
                        background: "none",
                      },
                    }}
                    onClick={handleAddDependentButton}
                  >
                    Cancel
                  </Button> */}
                </div>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
