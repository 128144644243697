import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//services
import organizationService from '../../../services/organization-service';

// Assets

//Mui Components

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel, Typography, Divider } from '@mui/material';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';

//Our Components

export default function ScheduleNewMessageDrawer(props) {
  // Organization Logic

  const [organizations, setOrganizations] = useState([]);

  const getOrganizations = async () => {
    let organizationArray = await organizationService.getOrganizations();
    setOrganizations(organizationArray);
  };

  let history = useHistory();

  const [newMessage, setNewMessage] = useState({
    message: '',
    date: '',
    organizationId: '',
  });

  //Drawer Logic
  const handleDrawerClose = (e) => {
    props.setDrawerOpen(false);
  };

  const handleMessageInformation = (e, property) => {
    let value = e.target.value;
    setNewMessage({
      ...newMessage,
      [property]: value,
    });
  };

  const scheduleNewMessage = async (e) => {
    console.log('Schedule that bad boy');
  };

  useEffect(() => {
    getOrganizations();
  }, [props.drawerOpen]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { marginTop: '56px!important' },
        }}
        hideBackdrop={true}
        anchor="right"
        open={props.drawerOpen}
        onClose={handleDrawerClose}
      >
        <div style={{ width: '560px', padding: '19px 38px' }}>
          <div className="flex-row">
            <div className="col-6">
              <Typography variant="body1" fontFamily="Poppins" fontWeight="600">
                Schedule New Message
              </Typography>
            </div>

            <div
              className="col-6"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              role="button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </div>
          </div>
          <Divider
            sx={{ color: 'primary.main' }}
            style={{ margin: '17px -38px 30px', opacity: 1, height: '2px' }}
          />

          {/* {props.showMemberInput &&
            <Autocomplete 
            disablePortal
            options={['figgy','wiggy']}
            
            renderInput={(params) => {
               <TextField {...params} fullWidth
               variant='outlined' label='Member'
               sx={{marginBottom:'20px'}}
               value={createdTicket.memberId} onChange={e=>handleTicketChange(e,'memberId')}
               />

            }}
            />
            } */}

          <TextField
            fullWidth
            variant="outlined"
            label="Date"
            sx={{ marginBottom: '20px' }}
            value={newMessage.date}
            onChange={(e) => handleMessageInformation(e, 'date')}
          />

          <TextField
            fullWidth
            variant="outlined"
            label="Message"
            multiline
            sx={{ marginBottom: '20px' }}
            value={newMessage.message}
            onChange={(e) => handleMessageInformation(e, 'message')}
          />

          <FormControl fullWidth>
            <InputLabel
              id="topParentOrganizationId"
              sx={{ fontFamily: 'Archivo' }}
            >
              Organization
            </InputLabel>
            <Select
              variant="outlined"
              labelId="topParentOrganizationId"
              label="Organization"
              size="small"
              sx={{
                fontSize: '15px',
                height: '50px',
                borderRadius: '8px',
                marginBottom: '20px',
              }}
              value={
                organizations &&
                organizations?.find((x) => x.id == newMessage?.organizationId)
                  ?.id
              }
              onChange={(e) => handleMessageInformation(e, 'organizationId')}
            >
              {organizations &&
                organizations.map((organization) => {
                  if (!organization.parentId) {
                    return (
                      <MenuItem value={organization.id}>
                        <span
                          style={{
                            fontFamily: 'Archivo',
                            paddingLeft: '10px',
                            margin: 'auto 0px',
                          }}
                        >
                          {organization.DisplayName}
                        </span>
                      </MenuItem>
                    );
                  }
                })}
            </Select>
          </FormControl>

          <div
            className="flex-row"
            style={{ justifyContent: 'center', marginBottom: '50px' }}
          >
            <Button
              variant="contained"
              disableRipple
              sx={{
                height: 40,
                minWidth: 200,
                width: '152px',
                fontWeight: '500!important',
              }}
              onClick={scheduleNewMessage}
              // startIcon={<AddIcon/>}
            >
              Schedule Message
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
