// import './member.css'
// import Header from '../header'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    NavLink
  } from "react-router-dom";

import React from 'react';
import PrivateRoute from '../app-internal/private-route/private-route';

import SaveButton from '../save-button/save-button';


import AddMember from './add-member/add-member';
import profilePicture from '../../assets/images/profile.png';
import AllMemberGrid from './all-member-grid/all-member-grid';
import MemberSidebar from './member-sidebar/memberSidebar';
import MemberTopbar from './member-topbar/memberTopbar';
import MemberInfo from './memberInfo/memberInfo';

function Member(props){
    const location = useLocation();
    const pathname = location.pathname;
    const id = props.match.params.id;
    const addMemberLink = "/member/add";
    const isAuthenticated = true; // fix

    let informationLink = "";
    let activityLink = "";
    let enrollmentLink = "";
    let transactionLink = "";
    let claimLink = "";

    let containsMember = false;

    const memberId = parseInt(id);
    if(memberId){
        containsMember = true;
        informationLink = "/member/" + memberId + "/information";
        activityLink = "/member/" + memberId + "/activity";;
        enrollmentLink = "/member/" + memberId + "/enrollment";
        transactionLink = "/member/" + memberId + "/transaction";
        claimLink = "/member/" + memberId + "/claim";
    }

    return (
        <React.Fragment>     
        {/* <Router History>
              <Route path="/member/test" exact render={() => <MemberTopbar />} />
        </Router>   
        <MemberTopbar className="member-topbar"/>
        <div className="member-container">

            <div className="member-container-main">
            {!containsMember && pathname != addMemberLink &&
                <AllMemberGrid>

                </AllMemberGrid>
            }

            {!containsMember && pathname === addMemberLink &&
                <div className="work-container">
                    <Header text="Add Member"></Header>
                    <AddMember></AddMember>                        
                </div>
            }

            {containsMember &&
                <MemberInfo currentLocation={pathname} memberId={memberId}>

                </MemberInfo>       
            }
            </div>
        </div> */}

        </React.Fragment>

    )
  }

  export default Member;