import React, { useState } from 'react';
import './RecommendationSurvey.css';

//Mui Components
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import { Typography, Box } from '@mui/material';

//MuiIcons
import CreateIcon from '@mui/icons-material/Create';

//utils

import { formatDateObjMMDDYYYYDisplay } from '../../utils';

export default function RecommendationSurvey(props) {
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const btnStyle = {
    borderRadius: '100px',
    height: '50px',
    fontWeight: '700',
  };

  const questionStyle = {
    display: 'inline-block',
    width: '80%',
  };

  //console.log('recommendation props', props);

  return (
    <>
      <style>
        {`
         .Mui-disabled.MuiButton-root.survey-btn {
            color:#195FFB;
            background-color: white;
            border: #195FFB 1px solid;
         }
         `}
      </style>
      {!props.calculated && (
        <Grow in={!props.calculated} timeout={1000}>
          <Box className="row" sx={{ paddingTop: '5px' }}>
            {props.results?.name &&
              (props?.results?.name?.includes('IBT') ||
                props?.results?.name?.includes('Teamsters')) && (
                <>
                  <Typography
                    variant="body1"
                    className="col-12"
                    style={{ marginBottom: '13px' }}
                  >
                    Are you a full time or part time employee?
                  </Typography>
                  <div
                    className="col-6"
                    onClick={() => {
                      // handleFullTime(true, 12)
                      props.surveyHandler('isFullTime', true);
                    }}
                  >
                    <Button
                      variant="contained"
                      className="survey-btn"
                      fullWidth
                      disabled={
                        props.survey.isFullTime == null ||
                        props.survey.isFullTime == false
                      }
                      sx={btnStyle}
                      style={{ marginBottom: '13px' }}
                    >
                      Full Time
                    </Button>
                  </div>
                  <div
                    className="col-6"
                    onClick={() => {
                      // handleFullTime(false, 13)
                      props.surveyHandler('isFullTime', false);
                    }}
                  >
                    <Button
                      variant="contained"
                      className="survey-btn"
                      fullWidth
                      disabled={
                        props.survey.isFullTime == null ||
                        props.survey.isFullTime == true
                      }
                      sx={btnStyle}
                      style={{ marginBottom: '13px' }}
                    >
                      Part Time
                    </Button>
                  </div>
                </>
              )}

            <span className="col-12" style={{ marginBottom: '6px' }}>
              Are you married?
            </span>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('IsMarried', true)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.IsMarried == null ||
                  props.survey.IsMarried == false
                }
                sx={btnStyle}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('IsMarried', false)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.IsMarried == null ||
                  props.survey.IsMarried == true
                }
                sx={btnStyle}
              >
                NO
              </Button>
            </div>

            <span
              className="col-12"
              style={{ marginTop: '20px', marginBottom: '6px' }}
            >
              Do you have any dependents?
            </span>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('HaveDependents', true)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.HaveDependents == null ||
                  props.survey.HaveDependents == false
                }
                sx={btnStyle}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('HaveDependents', false)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.HaveDependents == null ||
                  props.survey.HaveDependents == true
                }
                sx={btnStyle}
              >
                NO
              </Button>
            </div>

            <span
              className="col-12"
              style={{ marginTop: '20px', marginBottom: '6px' }}
            >
              Are you the primary household income?
            </span>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('IsPrimary', true)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.IsPrimary == null ||
                  props.survey.IsPrimary == false
                }
                sx={btnStyle}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('IsPrimary', false)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.IsPrimary == null ||
                  props.survey.IsPrimary == true
                }
                sx={btnStyle}
              >
                NO
              </Button>
            </div>

            <span
              className="col-12"
              style={{ marginTop: '20px', marginBottom: '6px' }}
            >
              Do you have a mortgage or rent?
            </span>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('Mortgage', true)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.Mortgage == null ||
                  props.survey.Mortgage == false
                }
                sx={btnStyle}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              onClick={() => props.surveyHandler('Mortgage', false)}
            >
              <Button
                variant="contained"
                className="survey-btn"
                fullWidth
                disabled={
                  props.survey.Mortgage == null || props.survey.Mortgage == true
                }
                sx={btnStyle}
              >
                NO
              </Button>
            </div>

            {props.results?.name && props?.results?.name?.includes('IUPAT') && (
              <>
                <Typography
                  variant="body1"
                  className="col-12"
                  style={{ marginTop: '20px', marginBottom: '6px' }}
                >
                  Are you a female wanting coverage for pregnancy?
                </Typography>
                <div
                  className="col-6"
                  onClick={() => {
                    // handleFullTime(true, 12)
                    props.surveyHandler('FamilyPlanning', true);
                  }}
                >
                  <Button
                    variant="contained"
                    className="survey-btn"
                    fullWidth
                    disabled={
                      props.survey.FamilyPlanning == null ||
                      props.survey.FamilyPlanning == false
                    }
                    sx={btnStyle}
                    style={{ marginBottom: '13px' }}
                  >
                    Yes
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    // handleFullTime(false, 13)
                    props.surveyHandler('FamilyPlanning', false);
                  }}
                >
                  <Button
                    variant="contained"
                    className="survey-btn"
                    fullWidth
                    disabled={
                      props.survey.FamilyPlanning == null ||
                      props.survey.FamilyPlanning == true
                    }
                    sx={btnStyle}
                    style={{ marginBottom: '13px' }}
                  >
                    No
                  </Button>
                </div>
              </>
            )}

            {props.results?.name && props?.results?.name?.includes('ASTTU') && (
              <>
                <Typography
                  variant="body1"
                  className="col-12"
                  style={{ marginTop: '20px', marginBottom: '6px' }}
                >
                  Are you a railroad employee?
                </Typography>
                <div
                  className="col-6"
                  onClick={() => {
                    // handleFullTime(true, 12)
                    props.surveyHandler('WorksInRail', true);
                  }}
                >
                  <Button
                    variant="contained"
                    className="survey-btn"
                    fullWidth
                    disabled={
                      props.survey.WorksInRail == null ||
                      props.survey.WorksInRail == false
                    }
                    sx={btnStyle}
                    style={{ marginBottom: '13px' }}
                  >
                    Yes
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    // handleFullTime(false, 13)
                    props.surveyHandler('WorksInRail', false);
                  }}
                >
                  <Button
                    variant="contained"
                    className="survey-btn"
                    fullWidth
                    disabled={
                      props.survey.WorksInRail == null ||
                      props.survey.WorksInRail == true
                    }
                    sx={btnStyle}
                    style={{ marginBottom: '13px' }}
                  >
                    No
                  </Button>
                </div>
              </>
            )}

            {props.results?.name &&
              (props?.results?.name?.includes('Teamsters') ||
                props?.results?.name?.includes('IBT')) && (
                <>
                  <Typography
                    variant="body1"
                    className="col-12"
                    style={{ marginTop: '20px', marginBottom: '6px' }}
                  >
                    Are you an Airline Pilot?
                  </Typography>
                  <div
                    className="col-6"
                    onClick={() => {
                      // handleFullTime(true, 12)
                      props.surveyHandler('IsPilot', true);
                    }}
                  >
                    <Button
                      variant="contained"
                      className="survey-btn"
                      fullWidth
                      disabled={
                        props.survey.IsPilot == null ||
                        props.survey.IsPilot == false
                      }
                      sx={btnStyle}
                      style={{ marginBottom: '13px' }}
                    >
                      YES
                    </Button>
                  </div>
                  <div
                    className="col-6"
                    onClick={() => {
                      // handleFullTime(false, 13)
                      props.surveyHandler('IsPilot', false);
                    }}
                  >
                    <Button
                      variant="contained"
                      className="survey-btn"
                      fullWidth
                      disabled={
                        props.survey.IsPilot == null ||
                        props.survey.IsPilot == true
                      }
                      sx={btnStyle}
                      style={{ marginBottom: '13px' }}
                    >
                      NO
                    </Button>
                  </div>
                </>
              )}
          </Box>
        </Grow>
      )}
      {props.calculated && (
        <Grow in={props.calculated} timeout={1000}>
          <div
            className="row"
            style={{ margin: '10px', justifySelf: 'center' }}
          >
            <div
              className="flex-row"
              style={{
                marginBottom: '15px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h5"
                textAlign="center"
                fontWeight="600"
                fontSize="20px"
              >
                My Information
              </Typography>
              <Button
                sx={{ marginLeft: '10px' }}
                onClick={() => props.setCalculated(false)}
                startIcon={<CreateIcon color="primary.main" />}
              >
                Edit
              </Button>
            </div>

            <div style={{ marginBottom: '12px' }}>
              <span style={{ display: 'inline-block', width: '60%' }}>
                Estimated annual earnings
              </span>
              <span
                style={{ fontWeight: 600, color: '#195FFB', float: 'right' }}
              >
                {UsdFormatterDec.format(props.userInfo.Salary)}
              </span>
            </div>

            <div style={{ marginBottom: '12px' }}>
              <span style={{ display: 'inline-block' }}>Date of birth</span>
              <span
                style={{ fontWeight: 600, color: '#195FFB', float: 'right' }}
              >
                {props?.userInfo.DOB
                  ? formatDateObjMMDDYYYYDisplay(props?.userInfo.DOB)
                  : ''}
              </span>
            </div>

            {props.results?.name &&
              (props?.results?.name?.includes('IBT') ||
                props?.results?.name?.includes('Teamsters')) && (
                <div style={{ marginBottom: '12px' }}>
                  <span style={questionStyle}>
                    Are you a Full Time or Part Time employee?
                  </span>
                  {/* Default sets to FT if not answered */}

                  <span
                    style={{
                      fontWeight: 600,
                      color: '#195FFB',
                      float: 'right',
                    }}
                  >
                    {props?.survey?.isFullTime
                      ? 'Full Time'
                      : props?.survey?.isFullTime == false
                      ? 'Part Time'
                      : 'Full Time'}
                  </span>
                </div>
              )}

            <div style={{ marginBottom: '12px' }}>
              <span style={questionStyle}>Are you married?</span>

              {props.survey.IsMarried && (
                <span
                  style={{ fontWeight: 600, color: '#2e8540', float: 'right' }}
                >
                  YES
                </span>
              )}
              {!props.survey.IsMarried && (
                <span
                  style={{ fontWeight: 600, color: '#CD2026', float: 'right' }}
                >
                  NO
                </span>
              )}
            </div>

            <div style={{ marginBottom: '12px' }}>
              <span style={questionStyle}>Do you have dependents?</span>

              {props.survey.HaveDependents && (
                <span
                  style={{ fontWeight: 600, color: '#2e8540', float: 'right' }}
                >
                  YES
                </span>
              )}
              {!props.survey.HaveDependents && (
                <span
                  style={{ fontWeight: 600, color: '#CD2026', float: 'right' }}
                >
                  NO
                </span>
              )}
            </div>

            <div style={{ marginBottom: '12px' }}>
              <span style={questionStyle}>
                Are you the primary household income?
              </span>

              {props.survey.IsPrimary && (
                <span
                  style={{ fontWeight: 600, color: '#2e8540', float: 'right' }}
                >
                  YES
                </span>
              )}
              {!props.survey.IsPrimary && (
                <span
                  style={{ fontWeight: 600, color: '#CD2026', float: 'right' }}
                >
                  NO
                </span>
              )}
            </div>

            <div style={{ marginBottom: '12px' }}>
              <span style={questionStyle}>Do you have a mortgage?</span>

              {props.survey.Mortgage && (
                <span
                  style={{ fontWeight: 600, color: '#2e8540', float: 'right' }}
                >
                  YES
                </span>
              )}
              {!props.survey.Mortgage && (
                <span
                  style={{ fontWeight: 600, color: '#CD2026', float: 'right' }}
                >
                  NO
                </span>
              )}
            </div>

            {props.results?.name &&
              (props?.results?.name?.includes('IBT') ||
                props?.results?.name?.includes('Teamsters')) && (
                <div style={{ marginBottom: '12px' }}>
                  <span style={questionStyle}>Are you an Airline Pilot?</span>

                  {props.survey.IsPilot && (
                    <span
                      style={{
                        fontWeight: 600,
                        color: '#2e8540',
                        float: 'right',
                      }}
                    >
                      YES
                    </span>
                  )}
                  {!props.survey.IsPilot && (
                    <span
                      style={{
                        fontWeight: 600,
                        color: '#CD2026',
                        float: 'right',
                      }}
                    >
                      NO
                    </span>
                  )}
                </div>
              )}

            {props.results?.name && props?.results?.name?.includes('ASTTU') && (
              <div style={{ marginBottom: '12px' }}>
                <span style={questionStyle}>Do you work in rail?</span>

                {props.survey.WorksInRail && (
                  <span
                    style={{
                      fontWeight: 600,
                      color: '#2e8540',
                      float: 'right',
                    }}
                  >
                    YES
                  </span>
                )}
                {!props.survey.WorksInRail && (
                  <span
                    style={{
                      fontWeight: 600,
                      color: '#CD2026',
                      float: 'right',
                    }}
                  >
                    NO
                  </span>
                )}
              </div>
            )}
          </div>
        </Grow>
      )}
    </>
  );
}
